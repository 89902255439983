import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  description: string;
  externalId: string;
  title: string;
  channel: string;
  uploadDate: Date;
  importDate: Date;
}

interface IVideo {
  id: number,
  description: string,
  externalId: string,
  title: string,
  channel: string,
  uploadDate: Date,
  importDate: Date
}

function createData(
  id: string,
  code: number,
  description: string,
  externalId: string,
  title: string,
  channel: string,
  uploadDate: Date,
  importDate: Date
): Data {
  return { id, code, description, externalId, title, channel, uploadDate, importDate };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Titulo' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'externalId', numeric: false, disablePadding: false, label: 'YT ID' },
  { id: 'channel', numeric: false, disablePadding: false, label: 'Canal' },
  { id: 'uploadDate', numeric: false, disablePadding: false, label: 'Data upload' },
  { id: 'importDate', numeric: false, disablePadding: false, label: 'Data importe' },
];

const VideoList = () => {
  const [rows, setRows] = useState<IVideo[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const mappedChannels = new Map()

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    mappedChannels.set("UCfG3nAoJrfqDZ2Q9gg5I46Q", "Aparecida Ao Vivo")
    mappedChannels.set("UCRWa9z4GzZZb0szyME8tysg", "A Rádio POP")
    mappedChannels.set("UC_t-KziRBosCVyFW1iT8X_Q", "Devotos Mirins")
    mappedChannels.set("UCaYnce-Vi5JL1Y7WoczllNQ", "A12")
    mappedChannels.set("UCdB5obv0acug-GcANICp_gA", "Rádio Aparecida")
    mappedChannels.set("UCfYrK5JU5EznsnK3wQE7iIg", "TV Aparecida")
    mappedChannels.set("UChdIDPYu280yFB1iXTP8gMQ", "Jovens de Maria")
    mappedChannels.set("UCsmc-icGWeZe_7lZKzGc76w", "Redentoristas")
    mappedChannels.set("UCuSeVCzI21lPeKnIzZehFPQ", `Santurario Nacional`)
    api.get(`/videos`, { params: { limit: 1000000 } }).then(response => {
      setRows(response.data);
      setLoading(false)
    });
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/videos/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Video deletado com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])
  
  const newsRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.description, row.externalId, row.title, row.channel, row.uploadDate, row.importDate));
  }, [rows]);


  const onEdit = useCallback((row) => {
    history.push('/video/edit', { row: row });
  }, [history]);

  const onImport = useCallback((row) => {
    api.post(`/videos/import`)
      .then(result => {
        alert("Processo de importação foi iniciado com sucesso! \n Dentro de alguns minutos será finalizado")
      })
      .catch(error => {
        alert("Um problema aconteceu :(")
      })


  }, []);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.title}</TableCell>
      <TableCell align="left">{row.description}</TableCell>
      <TableCell align="left">{row.externalId}</TableCell>
      <TableCell align="left">{mappedChannels.get(row.channel)}</TableCell>
      <TableCell align="left">{format(parseISO(row.uploadDate), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
      <TableCell align="left">{format(parseISO(row.importDate), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title='Vídeos do Youtube'
          headCells={headCells}
          rows={newsRows}
          onDelete={onDelete}
          onEdit={onEdit}
          onImport={onImport}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default VideoList;
