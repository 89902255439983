import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;

  form {
    margin: 40px 0;
    width: 340px;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      margin-bottom: 24px;
    }
  }
`;
