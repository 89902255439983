
interface OptionDTO {
    value: string;
    label: string;
}

export const typeOptions: OptionDTO[] = [
    { value: "A12_MESSAGE", label: "Mensagem para o usuário" },
    { value: "A12_INPUT_ADDRESS_ADDITIONAL", label: "Complemento do endereço" },
    { value: "A12_INPUT_ADDRESS_NEIGHBORHOOD", label: "Bairro" },
    { value: "A12_INPUT_ADDRESS_NUMBER", label: "Número da casa" },
    { value: "A12_INPUT_BIRTH_DATE", label: "Data de Nascimento" },
    { value: "A12_INPUT_BIRTH_DATE_CHILD", label: "Data de Nascimento da criança" },
    { value: "A12_INPUT_POSTAL_CODE", label: "CEP" },
    { value: "A12_INPUT_CPF", label: "CPF" },
    { value: "A12_INPUT_CPF_CHILD", label: "CPF da criança" },
    { value: "A12_INPUT_PHONE", label: "Telefone" },
    { value: "A12_INPUT_PHONE_CHILD", label: "Telefone da criança" },
    { value: "A12_INPUT_NAME", label: "Nome Completo" },
    { value: "A12_INPUT_NAME_CHILD", label: "Nome Completo da Criança" },
    { value: "A12_INPUT_EMAIL", label: "E-mail" },
    { value: "A12_INPUT_EMAIL_CHILD", label: "E-mail da criança" },
    { value: "A12_SELECT_YES_NO", label: "Aceite LGPD" },
    { value: "A12_SELECT_GENDER", label: "Gênero" },
    { value: "A12_SELECT_YES_NO_CHILD_EMAIL", label: "Confirmação de e-mail da criança" },
    { value: "A12_SELECT_YES_NO_CHILD_PHONE", label: "Confirmação de telefone da criança" },
    { value: "A12_SELECT_YES_NO_CHILD_CPF", label: "Confirmação de CPF da criança" },
    { value: "A12_SELECT_RADIO_BUTTON", label: "Origem do cadastro" },
    { value: "A12_SELECT_CONFIRM_ADDRESS", label: "Confirmação do endereço" },
    { value: "A12_BUTTON_SCREEN", label: "Botão para direcionar para uma tela" }
]

export const chatsOptions: OptionDTO[] = [
    { value: "DEVOUT_MISSIONARY", label: "Devoto Missionário" },
    { value: "YOUNG_OF_MARY", label: "Jovem de Maria" },
    { value: "CHILD_DEVOTEE", label: "Devoto Mirim" }
]

export const screenOptions: OptionDTO[] = [
    { value: "DashboardHome", label: "Home do App" },
    { value: "AparecidaTv", label: "TV Aparecida" },
    { value: "DailyMeditation", label: "Meditação diária" },
    { value: "SaintOfDay", label: "Santo do Dia" },
    { value: "OnlineBible", label: "Bíblia" },
    { value: "RadioAparecidaHome", label: "Rádio Aparecida" },
    { value: "RadioPopHome", label: "Rádio POP" },
    { value: "NewsHome", label: "Notícias A12" },
    { value: "ChosenCamera", label: "Aparecida ao Vivo" },    
    { value: "AparecidaPlayHome", label: "Aparecida Play" },
    { value: "DevoteesFamilyHome", label: "Família dos Devotos" },
    { value: "OurGodWithUsStackScreen", label: "Deus Conosco" },
    { value: "DevoteeArea", label: "Área do Devoto" },
    { value: "MakeYourDonation", label: "Faça sua Doação" },
    { value: "Magazine", label: "Revista" },
]