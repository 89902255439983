import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  description: string;
  isDefault: boolean;
  date: Date;
}

interface INews {
  id: number;
  description: string;
  isDefault: boolean;
  date: Date;
}

function createData(
  id: string,
  code: number,
  description: string,
  isDefault: boolean,
  date: Date

): Data {
  return { id, code, description, isDefault, date };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'isDefault', numeric: false, disablePadding: false, label: 'Default' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Data' }
];

const DailyMessageList = () => {
  const [rows, setRows] = useState<INews[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get(`/daily_message?page=1&limit=10`).then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/daily_message/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Notícia deletada com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])

  const newsRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(),row.id, row.description, row.isDefault, row.date));
  }, [rows]);



  const onCreate = useCallback(() => {
    history.push('/daily/messages/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/daily/messages/edit', { row: row });
  }, [history]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.description}</TableCell>
      <TableCell align="left">{row.isDefault ? 'SIM' : 'NÃO'}</TableCell>
      <TableCell align="left">{format(parseISO(row.date), "dd/MM/YYY", { locale: ptBR })}</TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title='Mensagens do dia'
          headCells={headCells}
          rows={newsRows}
          onCreate={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default DailyMessageList;
