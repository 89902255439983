import { shade } from 'polished';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;
  form {
    margin: 40px 0;
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;

    h1 {
      text-align: center;
      margin-bottom: 24px;
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
`;

export const ReorderButton = styled.button`
  display: flex;
  background: #008FC3;
  width: 30px;
  height: 100%;
  padding: 10px 0;
  margin: 0 5px;
  border-radius: 7px;
  border: 0;
  color: #312e38;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#008FC3')};
  }
`;

export const ImportButton = styled.div`
  display: flex;
  background: #008FC3;
  width: auto;
  height: 100%;
  padding: 10px 10px;
  margin: 0 5px;
  border-radius: 7px;
  border: 0;
  color: #FFF;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#008FC3')};
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  background: RED;
  width: 40px;
  height: 100%;
  padding: 10px 0;
  margin: 0 5px;
  border-radius: 7px;
  border: 0;
  color: #FFF;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#008FC3')};
  }
`;

export const ModalButton = styled.div`
  display: flex;
  background: #008FC3;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  margin: 10% 0 0 0;
  border-radius: 7px;
  border: 0;
  color: #FFF;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#008FC3')};
  }
`;
