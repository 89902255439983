import React, { useEffect, useState } from 'react';

import {
  Col,
  Content, Row, TitleGraph,
} from './styles';

import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import api from '../../../services/api';
import { CircularProgress } from '@material-ui/core';

interface IHealthTest {
  correct: number;
  error: number;
  name: string;
}

const ReportHealthCheck: React.FC = () => {

  const [data, setData] = useState<IHealthTest[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = () => {
    setLoading(true);
    api.get('/health_tests').then(response => {
      setLoading(false);
      setData(response.data);
    }).catch((error) => {
      setLoading(false);
      console.log(error);
    });
  }

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Row>
          <Col style={{width: "100%"}}>
            <TitleGraph>Health check</TitleGraph>
            {loading && <CircularProgress size={32} />}
            {!loading &&
              <>
                {(data && data?.length > 0) && (
                  <ResponsiveContainer width="100%" height="100%" minHeight={500}>
                    <BarChart
                      width={500}
                      height={300}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar name="Erro" dataKey="error" fill="#eb4034" />
                      <Bar name="Sucesso" dataKey="correct" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
                {(!data || data?.length === 0) &&
                  <>Não há dados no período</>
                }
              </>
            }
          </Col>
        </Row>
      </Content>
    </StandardContainer>
  );
};

export default ReportHealthCheck;
