import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: orange[500],
    },
  }),
);

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => {
  const classes = useStyles();
  return (
    <Container type="button" disabled={loading} {...rest}>
      {loading ? <CircularProgress size={32} className={classes.buttonProgress} /> : children}
    </Container>
  );
};

export default Button;
