import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { StyledTextarea, Label } from '../../Create/styles';


interface TextareaProps {
    name: string;
    label: string;
    placeholder?: string;
    defaultValue?: string;
  }
  

const Textarea: React.FC<TextareaProps> = ({ name, label, defaultValue, ...rest }) => {
  const textareaRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {label && <Label htmlFor={fieldName}>{label}</Label>}
      <StyledTextarea
        id={fieldName}
        ref={textareaRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <span style={{ color: '#f00' }}>{error}</span>}
    </div>
  );
};

export default Textarea;
