import React, { useCallback, useRef, useEffect, useState } from 'react';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink } from 'react-icons/fi';
import { GrSoundcloud } from 'react-icons/gr';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content, EditorTitle } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import { Editor } from '@tinymce/tinymce-react';

import { NewsTypeEnum } from '../../../utils/NewsTypeEnum';

interface AuthorData {
  id: number;
  name: string;
}

interface EditorialData {
  id: number;
  name: string;
}

interface NewsData {
  title: string;
  subtitle: string;
  header: string;
  content: string;
  news_type: string;
  audio_url: string;
  video_url: string;
  link_portal: string;
  highlight_home: boolean;
  highlight_banner: boolean;
  author_id: number;
  editorial_id: number;
  credit: string;
  highlight_fixed: string;
}

interface NewsFormData {
  title: string;
  subtitle?: string;
  header: string;
  content: string;
  news_type: string;
  audio_url: string;
  video_url: string;
  link_portal: string;
  author_id: string;
  editorial_id: string;
  credit: string;
  highlight_fixed: string;
}

interface OptionDTO {
  value: string;
  label: string;
}

interface LocationState {
  id: string;
};

const newsTypesOptions = [
  { value: NewsTypeEnum.PADRAO, label: 'Padrão' },
  { value: NewsTypeEnum.AUDIO, label: 'Áudio' },
  { value: NewsTypeEnum.VIDEO, label: 'Vídeo' },
];

const EditNews = () => {
  const [news, setNews] = useState<NewsData>();
  const [loading, setLoading] = useState(false);
  const [newsText, setNewsText] = useState('');
  const [newsType, setNewsType] = useState('');
  const [checkedHome, setCheckedHome] = useState(false);
  const [checkedBanner, setCheckedBanner] = useState(false);
  const [checkedactive, setCheckedActive] = useState(false);
  const [authorsOptions, setAuthorsOptions] = useState<OptionDTO[]>([]);
  const [editorialsOptions, setEditorialsOptions] = useState<OptionDTO[]>([]);

  const formRef = useRef<FormHandles>(null);
  const editorRef = useRef<any>(null);
  const { addToast } = useToast();
  const [countChars, setCountChars] = useState(0);
  const MAX_CHARS = 4000

  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/news/${state.id}`).then(response => {
      setNews(response.data);
      setNewsType(response.data.news_type);
      setNewsText(response.data.content);
      setCheckedHome(response.data.highlight_home);
      setCheckedBanner(response.data.highlight_banner);
      console.log("asddas",response.data.highlight_fixed)
      if (response.data.highlight_fixed === 'Y') {
        setCheckedActive(true);
        console.log('entra aqui se for Y')
      }
    });

  }, [state.id]);

  useEffect(() => {
    api.get(`/authors`).then(response => {
      const authors = response.data.map((author: AuthorData) => ({
        value: author.id.toString(),
        label: author.name,
      }));

      setAuthorsOptions(authors);
    });
  }, []);

  useEffect(() => {
    api.get(`/editorials`).then(response => {
      const editorials = response.data.map((editorial: EditorialData) => ({
        value: editorial.id.toString(),
        label: editorial.name,
      }));

      setEditorialsOptions(editorials);
    });
  }, []);

  const getCountChars = (): number => {
    const rawContent = editorRef.current.getContent({ format: 'raw' }).replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, "").trim()
    return rawContent.length
  }

  const handleNewsTypeValue = useCallback((data: NamedNodeMap) => {
    const labelUnformatted = data[3]?.nodeValue || '';

    let label = '';

    switch (labelUnformatted) {
      case 'Padrão':
        label = NewsTypeEnum.PADRAO;
        break;
      case 'Áudio':
        label = NewsTypeEnum.AUDIO;
        break;
      case 'Vídeo':
        label = NewsTypeEnum.VIDEO;
        break;
      default:
        label = '';
        break;
    }

    setNewsType(label);
  }, []);

  const saveText = () => {
    if (editorRef.current) {
      setNewsText(editorRef.current.getContent());
    }
  };

  const handleSubmit = useCallback(
    async (data: NewsFormData) => {
      try {
        formRef.current?.setErrors({});

        data.content = newsText;
        data.news_type = newsType;

        if (getCountChars() > MAX_CHARS) {
          alert(`A quantidade de caracteres na notícia está excedendo o máximo permitido: ${MAX_CHARS} caracteres`)
          return false
        }

        if (checkedactive) {
          data.highlight_fixed = 'Y';
        } else {
          data.highlight_fixed = 'N';
        }

        const schema = Yup.object().shape({
          title: Yup.string().required('Título é obrigatório'),
          subtitle: Yup.string(),
          header: Yup.string().required('Texto do chapéu é obrigatório'),
          content: Yup.string().required('Conteúdo é obrigatório'),
          news_type: Yup.string().required('Tipo é obrigatório'),
          audio_url: Yup.string(),
          video_url: Yup.string(),
          link_portal: Yup.string().required('Link do portal é obrigatório'),
          author_id: Yup.string().required('Autor é obrigatório'),
          editorial_id: Yup.string().required('Editoria é obrigatória'),
          credit: Yup.string(),
          highlight_fixed: Yup.string()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true)

        const author_id = Number(data.author_id);
        const editorial_id = Number(data.editorial_id);

        api.put(`/news/${state.id}`, {
          ...data,
          highlight_home: checkedHome,
          highlight_banner: checkedBanner,
          highlight_fixed: data.highlight_fixed,
          author_id,
          editorial_id,
        })
        .then((res) => {
          history.push('/news');
        })
        .catch((err) => console.log(err));       

        addToast({
          type: 'success',
          title: 'Notícia atualizada!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar notícia',
          description: 'Ocorreu um erro ao atualizar notícia, tente novamente.',
        });
      }
      setLoading(false);
    },
    [
      addToast,
      history,
      state.id,
      newsText,
      newsType,
      checkedHome,
      checkedBanner,
      checkedactive
    ],
  );


  const handleSendImage = async (file: File): Promise<{ url: string, name: string }> => {
    const dataImg = new FormData();
    dataImg.append('image', file);

    const { data } = await api.post(`/news/images`, dataImg);

    return data
  }

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar notícia</h1>
        <Form
          ref={formRef}
          initialData={{
            title: news?.title,
            subtitle: news?.subtitle,
            header: news?.header,
            content: news?.content,
            news_type: news?.news_type,
            audio_url: news?.audio_url,
            video_url: news?.video_url,
            link_portal: news?.link_portal,
            author_id: news?.author_id,
            editorial_id: news?.editorial_id,
            credit: news?.credit,
            highlight_fixed: news?.highlight_fixed

          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Input name="title" icon={BiFont} label="Título da notícia" placeholder="Título da notícia" />
            <Input name="subtitle" icon={BiFont} label="Subtítulo da notícia" placeholder="Subtítulo da notícia" />
            <Input name="header" icon={BiFont} label="Texto do chapéu" placeholder="Texto do chapéu" />
            <Input name="credit" icon={BiFont} label="Crédito da imagem" placeholder="Crédito da imagem da notícia" />
          </div>
          <div>
            <Select
              name="news_type"
              defaultOption={newsType}
              options={newsTypesOptions}
              label="Selecione o tipo da notícia"
              style={{ marginBottom: '7px' }}
              onChange={e => handleNewsTypeValue(e?.target?.attributes)}
            />
            {newsType === NewsTypeEnum.AUDIO && (
              <Input name="audio_url" icon={GrSoundcloud} label="Embed SoundCloud" placeholder="Embed SoundCloud" />
            )}
            {newsType === NewsTypeEnum.VIDEO && (
              <Input name="video_url" icon={FiYoutube} label="URL do Youtube" placeholder="URL do Youtube" />
            )}
            <Input name="link_portal" icon={FiLink} label="Link do portal" placeholder="Link do portal" />
            <Checkbox
              name="checkbox_home"
              checkboxName="Destaque home"
              checked={checkedHome}
              onChecked={() => setCheckedHome(!checkedHome)}
            />
            <Checkbox
              name="checkbox_banner"
              checkboxName="Destaque banner"
              checked={checkedBanner}
              onChecked={() => setCheckedBanner(!checkedBanner)}
            />
              <Checkbox
              name="checkedActive"
              checkboxName="Destaque fixo"
              checked={checkedactive}
              onChecked={() => setCheckedActive(!checkedactive)}
            />
            {authorsOptions && authorsOptions.length && (
              <Select
                name="author_id"
                defaultOption={news?.author_id?.toString()}
                options={authorsOptions}
                label="Selecione o autor"
                style={{ marginBottom: '7px' }}
              />
            )}
            {editorialsOptions && editorialsOptions.length && (
              <Select
                name="editorial_id"
                defaultOption={news?.editorial_id?.toString()}
                options={editorialsOptions}
                label="Selecione a editoria"
                style={{ marginBottom: '7px' }}
              />
            )}
          </div>
          <Content>
            <EditorTitle>Conteúdo da notícia:</EditorTitle>
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={news?.content}
              apiKey={process.env.REACT_APP_TINYMCE_KEY}
              onBlur={saveText}
              init={{
                width: "100%",
                height: 500,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar_mode: 'sliding',
                automatic_uploads: true,
                link_assume_external_targets: true,
                image_title: true,
                file_picker_types: 'image',
                font_family_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n',
                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                menubar: 'file edit view insert format tools table tc help',
                toolbar: 'fontfamily fontsize | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                /* and here's our custom image picker*/
                file_picker_callback: (cb, value, meta) => {
                  const input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');

                  let width = 0;
                  let height = 0;

                  const _URL = window.URL || window.webkitURL;

                  input.addEventListener('change', async (e: any) => {
                    const imageFile = e.target.files[0];
                    const img = new Image()
                    const objectUrl = _URL.createObjectURL(imageFile);
                    img.src = objectUrl;

                    img.onload = () => {
                      width = img.width;
                      height = img.height;
                    }

                    const { url, name } = await handleSendImage(imageFile)

                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                      cb(url, { title: name, width: `${width}px`, height: `${height}px` });
                    });
                    reader.readAsDataURL(imageFile);
                  });

                  input.click();
                },
                setup: function (ed) {
                  var allowedKeys = [8, 37, 38, 39, 40, 46, 16, 17, 18]; // backspace, delete and cursor keys, shift, ctrl, alt
                  ed.on('keydown', function (e) {
                    if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
                    if (getCountChars() + 1 > MAX_CHARS) {
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }
                    return true;
                  });
                  ed.on('keyup', function (e) {
                    setCountChars(getCountChars())
                  });
                },
                init_instance_callback: function () { // initialize counter div
                  setCountChars(getCountChars())
                },

              }}
            />
            <span>{countChars} de {MAX_CHARS}</span>
            <Button type="submit" loading={loading}>Salvar</Button>
          </Content>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditNews;
