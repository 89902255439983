import React, { useState } from 'react';
import {LoadingOverlayStyle} from './styles';


const Loading = () => {
  const [isActive, setActive] = useState(true);

  console.log("LoadingOverlay renderizado");

  return (
    <div>
      <LoadingOverlayStyle active={isActive} fadeSpeed={500} spinner text="Loading..."></LoadingOverlayStyle>
    </div>
  );
};

export default Loading;