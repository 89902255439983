import { PermissionsEnum } from './../utils/PermissionsEnum';
import { useAuth } from './auth';

const usePermission = (permissionCode: number): boolean => {
  const { user_permissions } = useAuth();

  if (!user_permissions) {
    return false;
  }

  const foundUserPermission = user_permissions.find((permission) => permission.permission_id === permissionCode) != null;
  const foundUserAdminPermission = user_permissions.find((permission) => permission.permission_id === PermissionsEnum.ADMIN) != null;

  return foundUserAdminPermission || foundUserPermission;
};

export default usePermission;
