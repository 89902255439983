import styled, { StyledComponent } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';

export const LoadingOverlayStyle = styled(LoadingOverlay)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
