import { Box, Modal, Typography } from "@material-ui/core";
import React, { createContext, useContext, useState } from "react";
import { ModalButton } from "../pages/Banner/styles";

export interface ModalContextData {
    openModal: (data: ModalData) => void,
    closeModal: () => void,
}

export type ModalData = {
    type: "success" | "error" | "info" | "warning",
    title: string,
    description: string,
    okAction?: {
        handleSubmit: () => void,
        label: string | JSX.Element,
        enabled: boolean
    }
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

export const ModalProvider: React.FC = ({ children }) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        justifyContent: 'center',
        alignItems: 'center',
        width: 400,
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
        color: 'black'
    };

    const [isOpen, setIsOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [type, setType] = useState("")
    const [okAction, setOkAction] = useState({} as ModalData["okAction"] | undefined)


    const openModal = (data: ModalData) => {

        console.log("Opening modal with data", data)

        setTitle(data.title)
        setDescription(data.description)
        setIsOpen(true)
        setType(data.type)
        setOkAction(data.okAction)

    }
    const closeModal = () => {
        setIsOpen(false)
        setTitle("")
        setDescription("")
        setType("")
        setOkAction(undefined)
    }


    const OkButton = () => {

        if (!okAction || !okAction.enabled) {
            return null
        }


        return <ModalButton
            onClick={() => { okAction.handleSubmit(); closeModal() }}
        >
            {okAction.label}
        </ModalButton>
    }


    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            <Modal
                open={isOpen}
                onClose={() => closeModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }}>
                        {title}
                    </Typography>

                    {description.split("\n").map((item, key) =>
                        <Typography key={key} id="modal-modal-description">
                            {item}
                        </Typography>
                    )}

                    <OkButton />

                </Box>
            </Modal>
            {children}
        </ModalContext.Provider>
    )
}

export function useModal(): ModalContextData {
    return useContext(ModalContext);
}