import React, { ChangeEvent, useCallback, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { Content, UploadBannerInput } from './styles';

interface LocationState {
  channelId: string;
  type: string;
};

const ImageUploadChannel = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [imgSize, setImgSize] = useState({ height: 0, width: 0 });

  const handleImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];

        const img = new Image()
        var _URL = window.URL || window.webkitURL;

        var objectUrl = _URL.createObjectURL(file);
        img.src = objectUrl;

        img.onload = () => {
          const { height, width } = img

          console.log({ height, width })
          setImgSize({ height, width })

        }


        setFile(file)
      }
    }, [setFile, setImgSize]);



  const handleUploadImage = useCallback(async () => {
    setLoading(true);
    try {
      if (file) {

        console.log(imgSize)

        const data = new FormData();
        data.append('banner', file);
        await api.patch(`/videos/channel/${state.channelId}/image`, data);
        history.push('/channel');

        addToast({
          type: 'success',
          title: 'Imagem do canal atualizada!',
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar imagem!',
        description: 'Tente novamente mais tarde :)',
      });
    }

    setLoading(false);
  }, [addToast, file, history, state.channelId, imgSize]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Adicionar imagem ao canal</h1>

        <UploadBannerInput>
          <input disabled value={file?.name} />
          <label htmlFor="uploadBanner">
            <FiUpload />
            <input type="file" id="uploadBanner" onChange={handleImageChange} />
          </label>          
        </UploadBannerInput>
        {imgSize.width > 0 && imgSize.height > 0 && (
            <span>
              {imgSize.width} x {imgSize.height}
            </span>
          )}
        <Button onClick={handleUploadImage} loading={loading}>Salvar</Button>

      </Content>
    </StandardContainer>
  )
}

export default ImageUploadChannel;
