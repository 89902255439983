import { shade } from 'polished';
import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  @media(max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
`;

export const VersionCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #28262E;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;

  div {
    flex: 1;
    font-size: 18px;
  }

  button {
    background: none;
    border: 0;
    border-radius: 5px;
    color: #312e38;

    svg {
      color: #fff;
      width: 25px;
      height: 25px;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }
`;
