import React, { useCallback, useRef, useEffect, useState } from 'react';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink } from 'react-icons/fi';
import { GrSoundcloud } from 'react-icons/gr';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content, EditorTitle } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Checkbox from '../../../components/Checkbox';


interface ChannelFormData {
  id: string;
  name: string;
  active: string;
}

interface LocationState {
  id: string;
}


const EditChannel = () => {
  const [channel, setChannel] = useState<ChannelFormData>();
  const [loading, setLoading] = useState(false);
  const [checkedactive, setCheckedActive] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    console.log(state.id, 'state');
    api.get(`/videos/showchannel/${state.id}`).then(response => {
      setChannel(response.data);
      console.log(response.data, 'response')
      if (response.data.active === 'Y') {
        setCheckedActive(true);
      }
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: ChannelFormData) => {
        console.log('handlesubmit')
      try {
        formRef.current?.setErrors({});

        if (checkedactive) {
          data.active = 'Y';
        } else {
          data.active = 'N';
        }

        setLoading(true);

        if (
        //   data.name!== '' &&
          data.active !== ''
        ) {
          api
            .put(`/videos/${state.id}/active`, {
              ...data,
            })
            .then(res => {
              history.push('/channel');
              addToast({
                type: 'success',
                title: 'Channel atualizado!',
              });
            })
            .catch(err => console.log(err));
        
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar channel',
            description: 'Preencha todos os dados.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar channel',
          description: 'Ocorreu um erro ao atualizar channel, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id, checkedactive],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar Channel</h1>
        <Form
          ref={formRef}
          initialData={{
            name: channel?.name,
            active: channel?.active,
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Input
              name="name"
              icon={BiFont}
              label="Nome"
              placeholder="Nome do canal"
            />
            <div style={{ marginTop: '15px' }}>
              <Checkbox
                name="checkedActive"
                checkboxName="Channel Ativo"
                checked={checkedactive}
                onChecked={() => setCheckedActive(!checkedactive)}
              />
            </div>
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </div>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default EditChannel;
