import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import { Editor } from '@tinymce/tinymce-react';

import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../../components/Button';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';

import { Content } from './styles';
import Select from '../../../components/Select';
import { NotificationTypeEnum } from '../../../utils/NotificationTypeEnum';
import { ScreensNotificationTypeEnum } from '../../../utils/ScreensNotificationTypeEnum';
import Input from '../../../components/Input';
import InputTasksGame from '../../../components/Input/InputsTasksGame';
import { FiLink } from 'react-icons/fi';
import Checkbox from '../../../components/Checkbox';
import DatetimeInput from '../../../components/DatetimeInput';

interface NotificationFormData {
  send_date: string;
}

interface INotificationData {}

const CreateNotification = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const editorDescriptionRef = useRef<any>(null);
  const editorTitleRef = useRef<any>(null);
  const [notificationText, setNotificationText] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [checked, setChecked] = useState(false);
  const [selectedScheduled, setSelectedScheduled] = useState('');
  const [type, setType] = useState<NotificationTypeEnum>();
  const [screenType, screenSetType] = useState<ScreensNotificationTypeEnum>();
  const [screenAparecidaAoVivoCamera, setScreenAparecidaAoVivoCamera] =
    useState('');
  const [screenAparecidaPlayPlaylists, setScreenAparecidaPlayPlaylists] =
    useState('');
  const [url, setUrl] = useState('');
  const [countCharsDescription, setCountCharsDescription] = useState(0);
  const [countCharsTitle, setCountCharsTitle] = useState(0);
  const [book, setBook] = useState('');
  const [chapter, setChapter] = useState('');
  const [verse, setVerse] = useState('');
  const [id_noticia, setIdNoticia] = useState('');
  const [id_aparecida_play_video, setAparecidaPlayVideo] = useState('');
  const MAX_CHARS_TITLE = 100;
  const MAX_CHARS_DESCRIPTION = 300;

  const notificationTypeOptions = [
    { value: NotificationTypeEnum.DEFAULT, label: 'Somente texto e emoji' },
    { value: NotificationTypeEnum.LINK, label: 'Texto com Link' },
  ];

  const screensNotificationTypeOptions = [
    { value: ScreensNotificationTypeEnum.HOME, label: 'home' },
    { value: ScreensNotificationTypeEnum.BIBLIA_BUSCA, label: 'biblia_busca' },
    {
      value: ScreensNotificationTypeEnum.BIBLIA_LEITURA,
      label: 'biblia_leitura',
    },
    {
      value: ScreensNotificationTypeEnum.RADIO_APARECIDA,
      label: 'radio_aparecida',
    },
    { value: ScreensNotificationTypeEnum.RADIO_POP, label: 'radio_pop' },
    { value: ScreensNotificationTypeEnum.TV_APARECIDA, label: 'tv_aparecida' },
    {
      value: ScreensNotificationTypeEnum.DEUS_CONOSCO_DIA_DIA,
      label: 'deus_conosco_dia_dia',
    },
    {
      value: ScreensNotificationTypeEnum.DEUS_CONOSCO_MEDITACAO_DIARIA,
      label: 'deus_conosco_meditacao_diaria',
    },
    {
      value: ScreensNotificationTypeEnum.APARECIDA_PLAY_CANAIS,
      label: 'aparecida_play_canais',
    },
    {
      value: ScreensNotificationTypeEnum.APARECIDA_PLAY_PLAYLISTS,
      label: 'aparecida_play_playlists',
    },
    {
      value: ScreensNotificationTypeEnum.APARECIDA_PLAY_VIDEO,
      label: 'aparecida_play_video',
    },
    { value: ScreensNotificationTypeEnum.FAMILIA_HOME, label: 'familia_home' },
    {
      value: ScreensNotificationTypeEnum.FAMILIA_CADASTRO,
      label: 'familia_cadastro',
    },
    {
      value: ScreensNotificationTypeEnum.FAMILIA_REVISTA,
      label: 'familia_revista',
    },
    {
      value: ScreensNotificationTypeEnum.APARECIDA_AO_VIVO_CAMERA,
      label: 'aparecida_ao_vivo_camera',
    },
    { value: ScreensNotificationTypeEnum.NOTICIA, label: 'noticia' },
    { value: ScreensNotificationTypeEnum.HOME_NOTICIA, label: 'home_noticia' },
  ];

  const channelsOptions = [
    { value: '1', label: 'Aparecida Ao Vivo' },
    { value: '2', label: 'A Rádio Pop' },
    { value: '3', label: 'Devotos Mirins' },
    { value: '4', label: 'A12' },
    { value: '5', label: 'Rádio Aparecida' },
    { value: '6', label: 'TV Aparecida' },
    { value: '7', label: 'Jovens de Maria' },
    { value: '8', label: 'Redentoristas' },
    { value: '9', label: 'Santuário Nacional de Aparecida' },
  ];

  const camerasOptions = [
    { value: '1', label: 'Aparecida Ao Vivo' },
    { value: '2', label: 'Nicho de N. Senhora' },
    { value: '3', label: 'Imagem de N. Senhora' },
    { value: '4', label: 'Capela São José' },
    { value: '5', label: 'Capela Santíssimo' },
    { value: '7', label: 'Altar Central' },
    { value: '8', label: 'Santuário Nacional' },
    { value: '10', label: 'Cidade Aparecida' },
    { value: '11', label: 'Altar Bas. Histórica' },
    { value: '12', label: 'Sala das Promessas' },
    { value: '14', label: 'Basílica Histórica' },
  ];

  const handleNotificationType = (target: any) => {
    if (target.value) {
      setType(target.value);
    }
  };

  const screenHandleNotificationType = (target: any) => {
    if (target.value) {
      screenSetType(target.value);
    }
  };

  const screenHandleAparecidaPlayPlaylist = (target: any) => {
    if (target.value) {
      setScreenAparecidaPlayPlaylists(target.value);
    }
  };

  const screenHandleAparecidaAoVivoCamera = (target: any) => {
    if (target.value) {
      setScreenAparecidaAoVivoCamera(target.value);
    }
  };

  const handleUrl = (value: string) => {
    if (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) {
      setUrl(value);
    } else {
      setUrl('https://' + value);
    }
  };

  const getCountChars = (ref: any): number => {
    const rawContent = ref?.current
      ?.getContent({ format: 'raw' })
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&nbsp;/g, '')
      .trim();
    return rawContent.length;
  };

  const saveText = () => {
    if (editorDescriptionRef.current) {
      setNotificationText(editorDescriptionRef.current.getContent());
    }
  };

  const saveTitle = () => {
    if (editorTitleRef.current) {
      setNotificationTitle(editorTitleRef.current.getContent());
    }
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: NotificationFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        let notificationData: INotificationData = {};

        const onlyScreenWithoutSelectOrInputs = [
          'home',
          'biblia_busca',
          'radio_aparecida',
          'radio_pop',
          'tv_aparecida',
          'deus_conosco_dia_dia',
          'deus_conosco_meditacao_diaria',
          'deus_conosco_santo_dia',
          'aparecida_play_canais',
          'familia_home',
          'familia_cadastro',
          'familia_revista',
          'home_noticia',
        ];

        if (type === NotificationTypeEnum.LINK) {
          if (book !== '' && chapter !== '' && verse !== '') {
            notificationData = {
              type: NotificationTypeEnum.LINK,
              url: url,
              screen: screenType,
              book: book,
              chapter: chapter,
              verse: verse,
            };
          }

          if (id_noticia !== '') {
            notificationData = {
              type: NotificationTypeEnum.LINK,
              url: url,
              screen: screenType,
              id_noticia: id_noticia,
            };
          }

          if (id_aparecida_play_video !== '') {
            notificationData = {
              type: NotificationTypeEnum.LINK,
              url: url,
              screen: screenType,
              id_aparecida_play_video: id_aparecida_play_video,
            };
          }
          if (screenAparecidaAoVivoCamera !== '') {
            notificationData = {
              type: NotificationTypeEnum.LINK,
              url: url,
              screen: screenType,
              screen_aparecida_ao_vivo: screenAparecidaAoVivoCamera,
            };
          }
          if (screenAparecidaPlayPlaylists !== '') {
            notificationData = {
              type: NotificationTypeEnum.LINK,
              url: url,
              screen: screenType,
              screen_aparecida_play_playlists: screenAparecidaPlayPlaylists,
            };
          }

          let verifyScreen = onlyScreenWithoutSelectOrInputs.filter(str => {
            return str === screenType;
          });

          if (verifyScreen && verifyScreen.length > 0) {
            notificationData = {
              type: NotificationTypeEnum.LINK,
              url: url,
              screen: screenType,
            };
          }
        } else {
          if (book !== '' && chapter !== '' && verse !== '') {
            notificationData = {
              type: NotificationTypeEnum.DEFAULT,
              screen: screenType,
              book: book,
              chapter: chapter,
              verse: verse,
            };
          }

          if (id_noticia !== '') {
            notificationData = {
              type: NotificationTypeEnum.DEFAULT,
              screen: screenType,
              id_noticia: id_noticia,
            };
          }

          if (id_aparecida_play_video !== '') {
            notificationData = {
              type: NotificationTypeEnum.DEFAULT,
              screen: screenType,
              id_aparecida_play_video: id_aparecida_play_video,
            };
          }
          if (screenAparecidaAoVivoCamera !== '') {
            notificationData = {
              type: NotificationTypeEnum.DEFAULT,
              screen: screenType,
              screen_aparecida_ao_vivo: screenAparecidaAoVivoCamera,
            };
          }
          if (screenAparecidaPlayPlaylists !== '') {
            notificationData = {
              type: NotificationTypeEnum.DEFAULT,
              screen: screenType,
              screen_aparecida_play_playlists: screenAparecidaPlayPlaylists,
            };
          }

          let verifyScreen = onlyScreenWithoutSelectOrInputs.filter(str => {
            return str === screenType;
          });

          if (verifyScreen && verifyScreen.length > 0) {
            notificationData = {
              type: NotificationTypeEnum.DEFAULT,
              screen: screenType,
            };
          }
        }

        //    let teste = {
        //   title: notificationTitle,
        //   notification_text: notificationText,
        //   data: notificationData,
        //   scheduled: checked,
        //   scheduled_date: checked ? selectedScheduled : null,
        // };

        // console.log(teste, 'dados testeeeeeeeeeee')

        await api.post('/notifications', {
          title: notificationTitle,
          notification_text: notificationText,
          data: notificationData,
          scheduled: checked,
          scheduled_date: checked ? selectedScheduled : null,
        });

        history.push('/notifications');

        addToast({
          type: 'success',
          title: 'Notificação cadastrada!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar notificação',
          description:
            'Ocorreu um erro ao cadastrar notificação, tente novamente.',
        });
      }
      setLoading(false);
    },
    [
      addToast,
      history,
      notificationText,
      notificationTitle,
      type,
      screenType,
      url,
      book,
      chapter,
      verse,
      id_aparecida_play_video,
      id_noticia,
      screenAparecidaAoVivoCamera,
      screenAparecidaPlayPlaylists,
      checked,
      selectedScheduled,
    ],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Criar notificação</h1>
          <label>Título da notificação:</label>
          <br />
          <Editor
            onInit={(evt, editor) => (editorTitleRef.current = editor)}
            apiKey={process.env.REACT_APP_TINYMCE_KEY}
            onBlur={saveTitle}
            init={{
              entity_encoding: 'raw',
              width: '100%',
              height: 150,
              menubar: false,
              plugins: ['code', 'emoticons'],
              toolbar: `
                  undo redo | bold italic | code | emoticons
                `,
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
              setup: function (ed) {
                var allowedKeys = [8, 37, 38, 39, 40, 46, 16, 17, 18]; // backspace, delete and cursor keys, shift, ctrl, alt
                ed.on('keydown', function (e) {
                  if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
                  if (getCountChars(editorTitleRef) + 1 > MAX_CHARS_TITLE) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  return true;
                });
                ed.on('keyup', function (e) {
                  setCountCharsTitle(getCountChars(editorTitleRef));
                });
              },
              init_instance_callback: function () {
                // initialize counter div
                setCountCharsTitle(getCountChars(editorTitleRef));
              },
            }}
          />
          <span>
            {countCharsTitle} de {MAX_CHARS_TITLE}
          </span>
          <br />

          <label>Texto da notificação:</label>
          <br />
          <Editor
            onInit={(evt, editor) => (editorDescriptionRef.current = editor)}
            apiKey={process.env.REACT_APP_TINYMCE_KEY}
            onBlur={saveText}
            init={{
              entity_encoding: 'raw',
              width: '100%',
              height: 300,
              menubar: false,
              plugins: ['code', 'emoticons'],
              toolbar: `
                  undo redo | bold italic | code | emoticons
                `,
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
              setup: function (ed) {
                var allowedKeys = [8, 37, 38, 39, 40, 46, 16, 17, 18]; // backspace, delete and cursor keys, shift, ctrl, alt
                ed.on('keydown', function (e) {
                  if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
                  if (
                    getCountChars(editorDescriptionRef) + 1 >
                    MAX_CHARS_DESCRIPTION
                  ) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  return true;
                });
                ed.on('keyup', function (e) {
                  setCountCharsDescription(getCountChars(editorDescriptionRef));
                });
              },
              init_instance_callback: function () {
                // initialize counter div
                setCountCharsDescription(getCountChars(editorDescriptionRef));
              },
            }}
          />
          <span>
            {countCharsDescription} de {MAX_CHARS_DESCRIPTION}
          </span>

          <Select
            name="type"
            options={notificationTypeOptions}
            label="Selecione o tipo de notificação"
            style={{ marginBottom: '10px' }}
            onChange={e => handleNotificationType(e.target)}
          />

          {type === NotificationTypeEnum.LINK && (
            <Input
              name="link"
              icon={FiLink}
              label="Link para abrir na notificação"
              placeholder="Link da notificação"
              onChange={e => handleUrl(e.target.value)}
            />
          )}

          <Select
            name="screensType"
            options={screensNotificationTypeOptions}
            label="Selecione a tela para direcionar a notificação"
            style={{ marginBottom: '10px' }}
            onChange={e => screenHandleNotificationType(e.target)}
          />

          {screenType === ScreensNotificationTypeEnum.BIBLIA_LEITURA && (
            <>
              <InputTasksGame
                name="book"
                label="Livro"
                placeholder="Livro"
                onChange={e => setBook(e.target.value)}
              />
              <InputTasksGame
                name="chapter"
                label="Capítulo"
                placeholder="Capítulo"
                onChange={e => setChapter(e.target.value)}
              />
              <InputTasksGame
                name="verse"
                label="Versículo"
                placeholder="Versículo"
                onChange={e => setVerse(e.target.value)}
              />
            </>
          )}
          {screenType === ScreensNotificationTypeEnum.NOTICIA && (
            <InputTasksGame
              name="id_noticia"
              label="Id da notícia"
              placeholder="Id da notícia"
              onChange={e => setIdNoticia(e.target.value)}
            />
          )}
          {screenType === ScreensNotificationTypeEnum.APARECIDA_PLAY_VIDEO && (
            <InputTasksGame
              name="id_aparecida_play_video"
              label="Id do vídeo"
              placeholder="Id do vídeo"
              onChange={e => setAparecidaPlayVideo(e.target.value)}
            />
          )}

          {screenType ===
            ScreensNotificationTypeEnum.APARECIDA_PLAY_PLAYLISTS && (
            <Select
              name="screens_aparecida_play_canais"
              options={channelsOptions}
              label="Canal"
              style={{ marginBottom: '10px' }}
              onChange={e => screenHandleAparecidaPlayPlaylist(e.target)}
            />
          )}
          {screenType ===
            ScreensNotificationTypeEnum.APARECIDA_AO_VIVO_CAMERA && (
            <Select
              name="screens_aparecida_ao_vivo_camera"
              options={camerasOptions}
              label="Câmera"
              style={{ marginBottom: '10px' }}
              onChange={e => screenHandleAparecidaAoVivoCamera(e.target)}
            />
          )}
          <Checkbox
            name="checkbox"
            checkboxName="Agendar notificação?"
            checked={checked}
            onChecked={() => setChecked(!checked)}
          />

          {checked && (
            <DatetimeInput
              id="datetime-scheduled"
              label="Data e hora do agendamento"
              name="dateStart"
              value={selectedScheduled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedScheduled(e.target.value)
              }
            />
          )}

          <Button type="submit" loading={loading}>
            Salvar
          </Button>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default CreateNotification;
