import React, { FunctionComponent, Dispatch } from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { TBpmnDefinition, TOptionsGateway } from "../index";
import TaskForm from "./Task";
import GatewayForm from "./Gateway";



interface FormBpmnProps {
    showModal: boolean
    setShowModal: Dispatch<React.SetStateAction<boolean>>
    bpmnDefinition: TBpmnDefinition | null | undefined
    optionsGateway: TOptionsGateway[]
}
 
const FormBpmn: FunctionComponent<FormBpmnProps> = ({setShowModal, showModal, bpmnDefinition, optionsGateway}) => {
    console.log(bpmnDefinition)
    return (
        <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Box 
        sx={{
        width: '50%',
        height: '80%',
        bgcolor: '#312E38',
        padding: '50px 100px'
      }}>
            {(() => {
                switch (bpmnDefinition) {
                case 'bpmn:Task':
                    return <TaskForm />
                case 'bpmn:SequenceFlow':
                    return <GatewayForm optionsGateway={optionsGateway} />
                case 'bpmn:EndEvent':
                    return <Typography variant="h4" component="h4" color="textSecondary">EndEvent</Typography>
                case 'bpmn:StartEvent':
                    return <Typography variant="h4" component="h4" color="textSecondary">StartEvent</Typography>
                default:
                    return null
                }
            })()}
            
        </Box>
      </Modal>
    );
}
 
export default FormBpmn;