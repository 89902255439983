import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiCamera, FiYoutube } from 'react-icons/fi';
import { MdOndemandVideo } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import { StatusEnum } from '../../../utils/StatusEnum';
import { PriorityEnum } from '../../../utils/PriorityEnum';
import Checkbox from '../../../components/Checkbox';

interface CameraFormData {
  name: string;
  android_url: string;
  android_priority: string;
  ios_url: string;
  ios_priority: string;
  youtube_url: string;
  youtube_priority: string;
  status: string;
  autoplay: string
}

interface CameraData {
  name: string;
  android_url: string;
  android_priority: string;
  ios_url: string;
  ios_priority: string;
  youtube_url: string;
  youtube_priority: string;
  active: string;
  is_default: boolean;
  autoplay: string
}

interface LocationState {
  id: string;
};

const statusOptions = [
  { value: StatusEnum.INATIVO, label: 'Inativo' },
  { value: StatusEnum.ATIVO, label: 'Ativo' },
];

const priorityOptions = [
  { value: PriorityEnum.ALTA, label: 'Alta' },
  { value: PriorityEnum.MEDIA, label: 'Média' },
  { value: PriorityEnum.BAIXA, label: 'Baixa' },
];

const EditCamera = () => {
  const [camera, setCamera] = useState<CameraData>();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAutoPlay, setCheckedAutoPlay] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/cameras/${state.id}`).then(response => {
      setCamera(response.data);
      setChecked(response.data.is_default);
      response.data.autoplay === 'Y' ? setCheckedAutoPlay(true) : setCheckedAutoPlay(false)
    });

  }, [state.id]);

  const handleSubmit = useCallback(
    async (data: CameraFormData) => {
      setLoading(true);

      if(checkedAutoPlay){
        data.autoplay = 'Y'
      }else{
        data.autoplay = 'N'
      }

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da câmera é obrigatório'),
          android_url: Yup.string(),
          android_priority: Yup.string()
            .when('android_url', {
              is: val => !!val.length,
              then: Yup.string().required('Prioridade para m3u8 é obrigatória'),
              otherwise: Yup.string(),
            }),
          ios_url: Yup.string(),
          ios_priority: Yup.string()
            .when('ios_url', {
              is: val => !!val.length,
              then: Yup.string().required('Prioridade para JMV é obrigatória'),
              otherwise: Yup.string(),
            }),
          youtube_url: Yup.string(),
          youtube_priority: Yup.string()
            .when('youtube_url', {
              is: val => !!val.length,
              then: Yup.string().required('Prioridade para Youtube é obrigatória'),
              otherwise: Yup.string(),
            }),
          status: Yup.string().required('Status da câmera é obrigatório'),
          autoplay: Yup.string()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const status = data.status === 'true' ? true : false;

        const {
          name,
          android_url,
          android_priority,
          ios_url,
          ios_priority,
          youtube_url,
          youtube_priority,
          autoplay
        } = data;

        await api.put(`/cameras/${state.id}`, {
          name,
          android_url,
          android_priority: android_url ? Number(android_priority) : null,
          ios_url,
          ios_priority: ios_url ? Number(ios_priority) : null,
          youtube_url,
          youtube_priority: youtube_url ? Number(youtube_priority) : null,
          active: status,
          is_default: checked,
          autoplay
        });

        history.push('/camera');

        addToast({
          type: 'success',
          title: 'Câmera atualizada!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar câmera',
          description: 'Ocorreu um erro ao atualizar câmera, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id, checked, checkedAutoPlay],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar câmera</h1>
        <Form
          ref={formRef}
          initialData={{
            status: camera?.active,
            name: camera?.name,
            android_url: camera?.android_url,
            android_priority: camera?.android_priority,
            ios_url: camera?.ios_url,
            ios_priority: camera?.ios_priority,
            youtube_url: camera?.youtube_url,
            youtube_priority: camera?.youtube_priority,
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Select
              name="status"
              defaultOption={camera?.active}
              options={statusOptions}
              label="Selecione o status da câmera"
              style={{ marginBottom: '7px' }}
            />
            <Input name="name" icon={FiCamera} label="Nome da câmera" placeholder="Nome da câmera" />
            <Select
              name="android_priority"
              defaultOption={camera?.android_priority}
              options={priorityOptions}
              label="Selecione a prioridade para o m3u8"
              style={{ marginBottom: '7px' }}
            />
            <Input name="android_url" icon={MdOndemandVideo} label="Link para m3u8" placeholder="Link para m3u8" />
          </div>
          <div>
            <Select
              name="ios_priority"
              defaultOption={camera?.ios_priority}
              options={priorityOptions}
              label="Selecione a prioridade para o JMV"
              style={{ marginBottom: '7px' }}
            />
            <Input name="ios_url" icon={MdOndemandVideo} label="Link para JMV" placeholder="Link para JMV" />

            <Select
              name="youtube_priority"
              defaultOption={camera?.youtube_priority}
              options={priorityOptions}
              label="Selecione a prioridade para o Youtube"
              style={{ marginBottom: '7px' }}
            />
            <Input name="youtube_url" icon={FiYoutube} label="Link para youtube" placeholder="Link para youtube" />
            <Checkbox
              name="checkbox"
              checkboxName="Câmera padrão"
              checked={checked}
              onChecked={() => setChecked(!checked)}
            />
             <Checkbox
              name="checkbox-camera"
              checkboxName="AutoPlay"
              checked={checkedAutoPlay}
              onChecked={() => setCheckedAutoPlay(!checkedAutoPlay)}
            />
          </div>
          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditCamera;
