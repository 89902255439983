import React, { useCallback, useRef, useState } from 'react';
import { FiLink } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';
import legacyApi from '../../../services/legacyApi';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import { EditorialStatusEnum } from '../../../utils/EditorialStatusEnum';
import { BannerNavigationEnum } from '../../../utils/BannerNavigationEnum';
import DatetimeInput from '../../../components/DatetimeInput';
import { BiText } from 'react-icons/bi';


interface ProfileFormData {
  editorial: EditorialStatusEnum.DEUS_CONOSCO | EditorialStatusEnum.FAMILIA_DOS_DEVOTOS | EditorialStatusEnum.HOME;
  url: string;
  navigate_details?: any;
  navigate_details_testament?: string;
  navigate_details_book?: string;
  navigate_details_chapter?: string;
  navigate_details_verse?: string;
  title?: string
}

const editorialStatusOptions = [
  { value: EditorialStatusEnum.DEUS_CONOSCO, label: 'Deus conosco' },
  { value: EditorialStatusEnum.FAMILIA_DOS_DEVOTOS, label: 'Família dos devotos' },
  { value: EditorialStatusEnum.HOME, label: 'Home' },
  { value: EditorialStatusEnum.APARECIDA_PLAY, label: 'Aparecida Play' },
];

const bannerTypeOptions = [
  { value: 'LINK', label: 'Link' },
  { value: 'NAVIGATION', label: 'Navegação' },
];

const navigationOptions = [
  { value: BannerNavigationEnum.RADIO_APARECIDA, label: 'Rádio Aparecida' },
  { value: BannerNavigationEnum.TV_APARECIDA, label: 'TV Aparecida' },
  { value: BannerNavigationEnum.RADIO_POP, label: 'Rádio Pop' },
  { value: BannerNavigationEnum.DEUS_CONOSCO, label: 'Deus conosco' },
  { value: BannerNavigationEnum.SANTO_DO_DIA, label: 'Deus conosco - Santo do dia' },
  { value: BannerNavigationEnum.DIA_A_DIA, label: 'Deus conosco - Dia a dia' },
  { value: BannerNavigationEnum.FAMILIA_DOS_DEVOTOS, label: 'Família dos devotos' },
  { value: BannerNavigationEnum.DOACAO, label: 'Família dos devotos - Doação' },
  { value: BannerNavigationEnum.CADASTRO, label: 'Família dos devotos - Cadastro' },
  { value: BannerNavigationEnum.NOTICIAS, label: 'Notícias' },
  { value: BannerNavigationEnum.CAMERA, label: 'Aparecida ao vivo' },
  { value: BannerNavigationEnum.MEDITACAO_DIARIA, label: 'Meditação diária' },
  { value: BannerNavigationEnum.EVANGELHO, label: 'Passagem da bíblia' },
  { value: BannerNavigationEnum.CONTATO, label: 'Contato' },
  { value: BannerNavigationEnum.SOBRE, label: 'Sobre' },
  { value: BannerNavigationEnum.APARECIDA_PLAY, label: 'Aparecida Play' },
];

interface OptionDTO {
  value: string;
  label: string;
}

interface NewsData {
  id: number;
  title: string;
}

interface NavDetails {
  field: string | null;
  label: string;
  isObject: boolean;
}

interface IVerse {
  id: number;
  number: number;
  title: string;
  content: string;
  selected?: boolean;
}

interface IChapter {
  id: number;
  number: number;
  name: string;
  verses: IVerse[];
}

interface IBook {
  id: number;
  name: string;
  number: number;
}

interface IBible {
  book?: IBook;
  chapter?: IChapter;
  chapters?: IChapter[];
  verse?: number;
  testament?: string;
  selected?: boolean;
}

const CreateBanner = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [newsOptions, setNewsOptions] = useState<OptionDTO[]>([]);
  const [camerasOptions, setCamerasOptions] = useState<OptionDTO[]>([]);
  const [testamentOptions, setTestamentOptions] = useState<OptionDTO[]>([]);
  const [bookOptions, setBookOptions] = useState<OptionDTO[]>([]);
  const [chapterOptions, setChapterOptions] = useState<OptionDTO[]>([]);
  const [verseOptions, setVerseOptions] = useState<OptionDTO[]>([]);

  const [bibleDetails, setBibleDetails] = useState<IBible>();

  const [selectedMeditationDate, setSelectedMeditationDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [bannerType, setBannerType] = useState('LINK');
  const [hasNavDetails, setHasNavDetails] = useState<NavDetails | undefined>();

  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  const handleBannerTypeValue = useCallback((data: NamedNodeMap) => {
    const labelUnformatted = data[3].nodeValue;

    let label = '';

    switch (labelUnformatted) {
      case 'Link':
        label = 'LINK';
        break;
      case 'Navegação':
        label = 'NAVIGATION';
        break;
      default:
        label = '';
        break;
    }

    setBannerType(label);
  }, []);

  const handleBibleTestament = useCallback((data: NamedNodeMap) => {
    const labelUnformatted = data[3].nodeValue;

    if (labelUnformatted === 'Antigo testamento') {
      setBibleDetails({ testament: 'old' });
      legacyApi.get('/bible/books', { params: { testament: 'old' } }).then(response => {
        const bookData = response.data.map((book: any) => ({
          value: JSON.stringify(book),
          label: book.name,
        }));

        setBookOptions(bookData);
      });

      return;
    }

    setBibleDetails({ testament: 'new' });
    legacyApi.get('/bible/books', { params: { testament: 'new' } }).then(response => {
      const bookData = response.data.map((book: any) => ({
        value: JSON.stringify(book),
        label: book.name,
      }));

      setBookOptions(bookData);
    });
  }, [legacyApi]);

  const handleBibleBook = useCallback((data: NamedNodeMap) => {
    const selectedBook = JSON.parse(data[4].value);
    const { new_testament, ...rest } = selectedBook;

    legacyApi
      .get(`/bible/books/${selectedBook.id}/chapters`)
      .then(response => {
        const chapterData = response.data.map((chapter: any) => {
          return {
            value: JSON.stringify(chapter),
            label: chapter.name,
          };
        });

        setBibleDetails({ ...bibleDetails, book: { ...rest, number: new_testament } });
        setChapterOptions(chapterData);
      });
  }, [legacyApi, bibleDetails]);

  const handleBibleChapter = useCallback((data: NamedNodeMap) => {
    const selectedChapter = JSON.parse(data[4].value);
    setBibleDetails({ ...bibleDetails, chapter: selectedChapter });

    setVerseOptions(selectedChapter.verses.map((verse: IVerse) => ({
      value: JSON.stringify(verse),
      label: verse.content,
    })
    ));
  }, [bibleDetails]);

  const handleBibleVerse = useCallback((data: NamedNodeMap) => {
    const selectedVerse = JSON.parse(data[4].value);

    setBibleDetails({ ...bibleDetails, verse: selectedVerse.number, selected: true });
  }, [bibleDetails]);

  const handleBannerNavigationDetails = useCallback((data: NamedNodeMap) => {
    const labelUnformatted = data[3].nodeValue;

    let label = undefined;

    switch (labelUnformatted) {
      case 'Notícias': {
        api.get(`/news`).then(response => {
          const newsData = response.data.map((news: NewsData) => ({
            value: news.id.toString(),
            label: news.title,
          }));

          setNewsOptions(newsData);
        });

        label = {
          field: 'news_id',
          label: 'NEWS',
          isObject: false,
        };

        break;
      }
      case 'Aparecida ao vivo': {
        api.get(`/cameras`).then(response => {
          const cameraData = response.data.map((camera: any) => ({
            value: JSON.stringify(camera),
            label: camera.name,
          }));

          setCamerasOptions(cameraData);
        });

        label = {
          field: 'selectedCamera',
          label: 'CAMERA',
          isObject: true,
        };

        break;
      }
      case 'Meditação diária': {

        label = {
          field: 'currentDate',
          label: 'DAILY_MEDITATION',
          isObject: false,
        };

        break;
      }
      case 'Passagem da bíblia': {
        setTestamentOptions([
          { value: 'old', label: 'Antigo testamento' },
          { value: 'new', label: 'Novo testamento' },
        ]);

        label = {
          field: null,
          label: 'BIBLE',
          isObject: true,
        };

        break;
      }
      default:
        label = undefined;
        break;
    }

    setHasNavDetails(label);
  }, []);

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        if (selectedMeditationDate && hasNavDetails && hasNavDetails?.label === 'DAILY_MEDITATION') {
          data.navigate_details = selectedMeditationDate.toString();
        }

        if (bibleDetails && hasNavDetails && hasNavDetails?.label === 'BIBLE') {
          const {
            navigate_details_testament,
            navigate_details_book,
            navigate_details_chapter,
            navigate_details_verse,
            ...rest
          } = data;

          data = rest;
          data.navigate_details = JSON.stringify(bibleDetails);
        }

        const schema = Yup.object().shape({
          editorial: Yup.string().required('Selecione a editoria do banner'),
          banner_type: Yup.string().required('Tipo é obrigatório'),
          url: Yup.string().required('Link obrigatório'),
          navigate_details: hasNavDetails ? Yup.string().required('Selecione os detalhes da navegação') : Yup.string(),
          navigate_details_testament: Yup.string(),
          navigate_details_book: Yup.string(),
          navigate_details_chapter: Yup.string(),
          navigate_details_verse: Yup.string()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let newData = data as any;

        if (hasNavDetails) {

          newData = {
            ...data,
            navigate_details: JSON.stringify({
              field: hasNavDetails?.field,
              isObject: hasNavDetails?.isObject,
              content: data.navigate_details,
            })
          };
        }

        newData = {
          ...newData,
          ...{
            schedule_publication_start: !selectedStartDate ? null : new Date(selectedStartDate),
            schedule_publication_end: !selectedEndDate ? null : new Date(selectedEndDate)
          }
        }

        const response = await api.post('/banners', newData);

        history.push('/banner/upload', { id: response.data.id, type: data.editorial.toString() });

        addToast({
          type: 'success',
          title: 'Banner cadastrado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar banner',
          description: 'Ocorreu um erro ao cadastrar banner, tente novamente.',
        });
      }
      setLoading(false);
    },
    [
      addToast,
      history,
      hasNavDetails,
      bibleDetails,
      selectedMeditationDate,
      selectedEndDate,
      selectedStartDate
    ],
  );

  const navDetailsComponent = () => {
    switch (hasNavDetails?.label) {
      case 'NEWS':
        return (
          <Select
            name="navigate_details"
            options={newsOptions}
            label="Selecione a notícia desejada"
            style={{ marginBottom: '10px' }}
          />
        );
      case 'CAMERA':
        return (
          <Select
            name="navigate_details"
            options={camerasOptions}
            label="Selecione a câmera desejada"
            style={{ marginBottom: '10px' }}
          />
        );
      case 'DAILY_MEDITATION':
        return (
          <DatetimeInput
            id="datetime"
            label="Dia da meditação"
            name="dateMeditation"
            value={selectedMeditationDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedMeditationDate(e.target.value)}
          />
        );
      case 'BIBLE':
        return (
          <>
            <Select
              name="navigate_details_testament"
              options={testamentOptions}
              label="Selecione o testamento da bíblia desejado"
              style={{ marginBottom: '10px' }}
              onChange={e => handleBibleTestament(e.target.attributes)}
            />
            {bibleDetails?.testament && bookOptions.length > 0 && (
              <Select
                name="navigate_details_book"
                options={bookOptions}
                label="Selecione o livro da bíblia desejado"
                style={{ marginBottom: '10px' }}
                onChange={e => handleBibleBook(e.target.attributes)}
              />
            )}
            {bibleDetails?.book && chapterOptions.length > 0 && (
              <Select
                name="navigate_details_chapter"
                options={chapterOptions}
                label="Selecione o capítulo da bíblia desejado"
                style={{ marginBottom: '10px' }}
                onChange={e => handleBibleChapter(e.target.attributes)}
              />
            )}
            {bibleDetails?.chapter && verseOptions.length > 0 && (
              <Select
                name="navigate_details_verse"
                options={verseOptions}
                label="Selecione o versículo da bíblia desejado"
                style={{ marginBottom: '10px' }}
                onChange={e => handleBibleVerse(e.target.attributes)}
              />
            )}
          </>
        );
    }
  };

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h1>Criar banner</h1>

          <Input name="title" label='Título' icon={BiText}/>

          <Select
            name="editorial"
            options={editorialStatusOptions}
            label="Selecione a editoria"
            style={{ marginBottom: '10px' }}
          />
          <Select
            name="banner_type"
            options={bannerTypeOptions}
            label="Selecione o tipo de link do banner"
            style={{ marginBottom: '10px' }}
            onChange={e => handleBannerTypeValue(e.target.attributes)}
          />
          {bannerType === 'LINK' ? (
            <Input label="Link" name="url" icon={FiLink} placeholder="Link" />
          ) : (
            <>
              <Select
                name="url"
                options={navigationOptions}
                label="Selecione a área"
                style={{ marginBottom: '10px' }}
                onChange={e => handleBannerNavigationDetails(e.target.attributes)}
              />
              {hasNavDetails && navDetailsComponent()}
            </>
          )}
          <Button type="submit" loading={loading}>Próximo</Button>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default CreateBanner;
