import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableBibleSearches from '../../components/Table';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  searches: number;
  text: string;
  created_at: Date;
}

interface IBibleSearches {
  searches: number;
  text: string;
  created_at: Date;
}

function createData(
  id: string,
  searches: number,
  text: string,
  created_at: Date,
): Data {
  return { id, searches, text, created_at };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'searches', numeric: true, disablePadding: true, label: 'Nº pesquisas' },
  { id: 'text', numeric: false, disablePadding: false, label: 'Termo' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Última busca' },
];

const BibleSearches = () => {
  const [rows, setRows] = useState<IBibleSearches[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api.get('/bible/searches/all/').then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, []);

  const bibleSearchesRows = useMemo(() => {
    return rows.map((row, index) => createData((index + 1).toString(), row.searches, row.text, row.created_at));
  }, [rows]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.searches}</TableCell>
      <TableCell align="left">{row.text}</TableCell>
      <TableCell align="left">{format(parseISO(row.created_at), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableBibleSearches<Data, HeadCell[]>
          title='Buscas na Bíblia'
          headCells={headCells}
          rows={bibleSearchesRows}
          orderByDefaultColumn='id'
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default BibleSearches;
