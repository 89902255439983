export default class CustomContextPad {
    static $inject: string[];
    constructor(contextPad: any) {
      contextPad.registerProvider(this);
    }
  
    getContextPadEntries(element: any) {
        return function (entries: any) {
            delete entries["append.intermediate-event"];
            delete entries["replace"];
            delete entries["append.text-annotation"];
        
            return entries;
    }
  }
}

CustomContextPad.$inject = [
  'contextPad',
];