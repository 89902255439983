import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNotifications from '../../components/Table';
import api from '../../services/api';
import { Content } from './styles';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import { AnyNaptrRecord } from 'dns';

const saoPauloTimeZone = 'America/Sao_Paulo';

interface Data {
  id: string;
  title: string;
  notification_text: string;
  send_date: Date;
  scheduled: boolean;
  created_at: Date;
}

interface INotification {
  id: number;
  title: string;
  notification_text: string;
  send_date: Date;
  scheduled: boolean;
  created_at: Date;
}

function createData(
  id: string,
  title: string,
  notification_text: string,
  send_date: Date,
  scheduled: boolean,
  created_at: Date,
): Data {
  return { id, title, notification_text, send_date, scheduled, created_at };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'title', numeric: true, disablePadding: true, label: 'Título' },
  { id: 'notification_text', numeric: false, disablePadding: false, label: 'Texto' },
  { id: 'send_date', numeric: false, disablePadding: false, label: 'Data de envio' },
  { id: 'scheduled', numeric: false, disablePadding: false, label: 'Agendado?' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Criado em' },
];

const Notifications = () => {
  const [rows, setRows] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(true)

  const history = useHistory();

  useEffect(() => {
    api.get('/notifications').then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, []);

  const notificationsRows = useMemo(() => {
    return rows.map((row) => createData(row.id.toString(), row.title, row.notification_text, row.send_date, row.scheduled, row.created_at));
  }, [rows]);

  const onCreate = useCallback(() => {
    history.push('/notifications/new');
  }, [history]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => {
    const formatDateToSaoPaulo = (dateString: string) => {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('pt-BR', {
        timeZone: saoPauloTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(date);
    };
  
    return (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.title}</TableCell>
        <TableCell align="left">{row.notification_text}</TableCell>
        <TableCell align="left">{formatDateToSaoPaulo(row.send_date)}</TableCell>
        <TableCell align="left">{row.scheduled ? 'Sim' : 'Não'}</TableCell>
        <TableCell align="left">{formatDateToSaoPaulo(row.created_at)}</TableCell>
      </>
    );
  }, []);

  // const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
  //   <>
  //     <TableCell padding="checkbox">
  //         <Checkbox
  //           checked={isItemSelected}
  //           inputProps={{ 'aria-labelledby': labelId }}
  //         />
  //     </TableCell>
  //     <TableCell component="th" id={labelId} scope="row" padding="none">
  //       {row.id}
  //     </TableCell>
  //     <TableCell align="left">{row.title}</TableCell>
  //     <TableCell align="left">{row.notification_text}</TableCell>
  //     <TableCell align="left">{format(parseISO(row.send_date), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
  //     <TableCell align="left">{row.scheduled ? 'Sim' : 'Não'}</TableCell>
  //     <TableCell align="left">{format(parseISO(row.created_at), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
  //   </>
  // ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNotifications<Data, HeadCell[]>
          title='Notificações'
          headCells={headCells}
          rows={notificationsRows}
          onCreate={onCreate}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Notifications;
