import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  description: string;
  screen: string;
  active: string;
}

interface ITasksGame {
  id: number;
  description: string;
  screen: string;
  active: string;
}

function createData(
  id: string,
  code: number,
  description: string,
  screen: string,
  active: string,
): Data {
  return { id, code, description, screen, active };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
  { id: 'screen', numeric: false, disablePadding: false, label: 'Tela' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Ativa' },
];

const TasksGame = () => {
  const [rows, setRows] = useState<ITasksGame[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/tasksgame/', { params: { limit: 10000 } }).then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const newsRows = useMemo(() => {
    return rows.map(row =>
      createData(
        row.id.toString(),
        row.id,
        row.description,
        row.screen,
        row.active,
      ),
    );
  }, [rows]);

  const onCreate = useCallback(() => {
    history.push('/tasksgame/task/create');
  }, [history]);

  const onEdit = useCallback(
    row => {
      history.push('/tasksgame/:id', { id: row.code, screen: row.screen });
    },
    [history],
  );


  const onDelete = useCallback(
    id => {
      api.delete(`/tasksgame/${id}`).then(response => {
        setDeleted(!deleted);
        addToast({
          type: 'success',
          title: 'Task deletada com sucesso!',
        });
      });
    },
    [setDeleted, deleted, addToast],
  );

  const tableBodyCells = useCallback(
    (isItemSelected, labelId, row) => (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.description}</TableCell>
        <TableCell align="left">{row.screen}</TableCell>
        <TableCell align="left">{row.active}</TableCell>
      </>
    ),
    [],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title="TasksGame"
          headCells={headCells}
          rows={newsRows}
          orderByDefaultColumn="updated_at"
          defineOrder="desc"
          onCreate={onCreate}
          onDelete={onDelete}
          // onUpload={onUpload}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  );
};

export default TasksGame;
