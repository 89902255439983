import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import Select from '../../components/Select';
import StandardContainer from '../../components/StandardContainer';
import { Content, TitleContainer, MainContainer, CheckboxContainer } from './styles';
import api from '../../services/api';
import usePermissions from './hooks/usePermissions';
import { PermissionsActionsEnum } from '../../utils/PermissionsActionsEnum';
import { useAuth } from '../../hooks/auth';
import Button from '../../components/Button';

interface UserOption {
  value: string,
  label: string,
}

interface Permission {
  id: number,
  title: string,
  code: number,
}

interface PermissionCheck {
  id: number,
  title: string,
  code: number,
  checked: boolean,
}

const Permissions = () => {
  const [users, setUsers] = useState<UserOption[]>();
  const [userId, setUserId] = useState<string>();
  const [loading, setLoading] = useState(false);

  const { state, dispatch } = usePermissions();

  const { permissions: allPermissions } = useAuth();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    api.get(`/users`).then(response => {
      setUsers(response.data.map((user: any) => ({ value: user.id.toString(), label: user.name })));
    });
  }, []);

  useEffect(() => {
    if (userId) {
      console.log("userId ", userId)
      api.get(`/permission_users/user/${userId}`).then(response => {
        dispatch({ type: PermissionsActionsEnum.LOAD_PERMISSIONS, payload: { user_permissions: response.data } });
      });
    }

  }, [userId, dispatch]);

  const handleSelectUser = useCallback((data: NamedNodeMap) => {
    const [, id] = data[2].nodeValue!.split('selected_user');

    setUserId(id);
  }, []);

  const handleSelectPermission = useCallback((id: number) => {
    dispatch({ type: PermissionsActionsEnum.CHANGE_PERMISSIONS, payload: { permission_id: id } });
  }, [dispatch]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    if (!userId) {
      setLoading(false);
      return;
    }

    //validate aparecida play
    const isAparecidaPlayChecked = state.permissions.find((permission: any) => permission.checked
      && permission.id === 14) != null
    const permissionsPerChannel = state.permissions
      .filter((permission: any) => permission.checked && Number(permission.id) > 14 && Number(permission.id) <= 22);


    if (isAparecidaPlayChecked && permissionsPerChannel.length == 0) {
      setLoading(false);
      alert("Selecione pelo menos uma permissão para a Aparecida Play");
      return;
    } else if (!isAparecidaPlayChecked) {
      state.permissions
        .filter((permission: any) => Number(permission.id) > 14 && Number(permission.id) <= 22)
        .forEach((permission: any) => {
          permission.checked = false;
        })
    }

    const promises = state.permissions.map((permission: any) => {
      if (permission.checked) {
        return api.post('/permission_users', {
          permission_id: permission.id,
          user_id: userId,
        });
      }

      return api.post('/permission_users/delete', {
        permission_id: permission.id,
        user_id: userId,
      });
    });

    await Promise.all(promises);

    setLoading(false);
  }, [userId, state.permissions]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TitleContainer>
          <h1>Gerenciar permissões</h1>
        </TitleContainer>
        <MainContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {users && (
              <Select
                name="selected_user"
                options={users}
                label="Selecione o usuário"
                style={{ width: '100%' }}
                onChange={e => handleSelectUser(e.target.attributes)}
              />
            )}

            <CheckboxContainer>
              {allPermissions
                .filter((p: Permission) => p.id <= 14)
                .map((permission: Permission) => (
                  <Checkbox
                    name={`checkbox_permission_${permission.id}`}
                    checkboxName={permission.title}
                    checked={state.permissions?.find((p: PermissionCheck) => (permission.id === p.id && p.checked)) || false}
                    onChecked={() => handleSelectPermission(permission.id)}
                  />
                ))}

              {/* Aparecida play permissions */}

              {state.permissions?.find((p: PermissionCheck) => (p.id === 14 && p.checked)) && (
                <>
                  {allPermissions
                    .filter((p: Permission) => p.id > 14)
                    .map((permission: Permission) => (
                      <Checkbox
                        name={`checkbox_permission_${permission.id}`}
                        checkboxName={permission.title}
                        checked={state.permissions?.find((p: PermissionCheck) => (permission.id === p.id && p.checked)) || false}
                        onChecked={() => handleSelectPermission(permission.id)}
                      />
                    ))}
                </>
              )}
            </CheckboxContainer>

            <Button type="submit" loading={loading}>Salvar</Button>
          </Form>
        </MainContainer>
      </Content>
    </StandardContainer>
  )
}

export default Permissions;
