import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FiDelete, FiPlus, FiUpload, FiEdit3, FiDownloadCloud } from 'react-icons/fi';
import { FaChartLine, FaFileDownload, FaRegCalendarPlus, FaRegCalendarTimes } from 'react-icons/fa';
import Pagination from './Pagination';
import Toolbar from './Toolbar';
import Header from './Header';
import { CircularProgress } from '@material-ui/core';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface ITableProps<T, H> {
  title: string;
  headCells: H;
  rows: any;
  onCreate?: any;
  onDelete?: any;
  onUpload?: any;
  onEdit?: any;
  onSchedule?: any;
  onCancelSchedule?: any;
  orderByDefaultColumn?: string;
  tableBodyCells: any;
  onImport?: any;
  loading: boolean;
  defineOrder?: Order;
  rowsPerPage?: number;
  setLimitToParent?: any;
  setPageToParent?: any;
  hasChartAttached?: boolean;
  setShowChart?: any;
  hasDownloadData?: boolean;
  downloadDataEvent?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    loading: {
      color: '#3f51b5',
    },
  }),
);

export default function BasicTable<T, H>(props: ITableProps<T, H>) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>(!props.defineOrder ? 'asc' : props.defineOrder);
  const [orderBy, setOrderBy] = useState<keyof T | any>(props.orderByDefaultColumn);
  const [selected, setSelected] = useState<string>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState((props.rowsPerPage) ? props.rowsPerPage : 10);

  const {
    title,
    headCells,
    rows,
    onCreate,
    onDelete,
    onUpload,
    onEdit,
    onSchedule,
    onCancelSchedule,
    tableBodyCells,
    onImport,
    setLimitToParent,
    setPageToParent,
    hasChartAttached,
    setShowChart,
    hasDownloadData,
    downloadDataEvent
  } = props;

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof T) => (event: MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleUploadImage = () => {
    const row = rows.find((_row: any) => _row.id === (selected === '' ? 0 : selected));

    if (row) {
      onUpload(row);
    }
  }

  const handleEdit = () => {
    const row = rows.find((_row: any) => _row.id === (selected === '' ? 0 : selected));

    if (row) {
      onEdit(row);
    }
  }

  const handleImport = () => {
    const row = rows.find((_row: any) => _row.id === (selected === '' ? 0 : selected));

    if (row) {
      onImport(row);
    }
  }

  const handleShedule = () => {
    const row = rows.find((_row: any) => _row.id === (selected === '' ? 0 : selected));

    if (row) {
      onSchedule(row);
    }
  }

  const handleCancelShedule = () => {
    const row = rows.find((_row: any) => _row.id === (selected === '' ? 0 : selected));

    if (row) {
      onCancelSchedule(row);
    }
  }

  const handleDelete = () => {
    setSelected('');
    onDelete(selected);
  }

  const handleClick = (event: MouseEvent<unknown>, id: string) => {
    let newSelected: string;

    if (selected === '' || (selected !== '' && selected !== id)) {
      newSelected = id;
    } else {
      newSelected = '';
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (setPageToParent){
      setPageToParent(newPage)
    }
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLimitToParent(parseInt(event.target.value, 10))
    setPage(0);
    if (setPageToParent){
      setPageToParent(0);
    }
  };

  const isSelected = (name: string) => selected === name;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  return (
    <>
      {props.loading ?
        <CircularProgress size={32} className={classes.loading} /> :
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar
              title={title}
              numSelected={selected === '' ? 0 : Number(selected)}
              topSelectedFeatures={
                <>
                  {onDelete && (
                    <Tooltip title="Deletar">
                      <IconButton aria-label="deletar" onClick={handleDelete}>
                        <FiDelete />
                      </IconButton>
                    </Tooltip>
                  )}
                  {onEdit && (
                    <Tooltip title="Editar item">
                      <IconButton aria-label="edit" onClick={handleEdit}>
                        <FiEdit3 />
                      </IconButton>
                    </Tooltip>
                  )}
                  {onUpload && (
                    <Tooltip title="Upload imagem">
                      <IconButton aria-label="upload" onClick={handleUploadImage}>
                        <FiUpload />
                      </IconButton>
                    </Tooltip>
                  )}
                  {onSchedule && (
                    <Tooltip title="Agendar">
                      <IconButton aria-label="schedule" onClick={handleShedule}>
                        <FaRegCalendarPlus />
                      </IconButton>
                    </Tooltip>
                  )}
                  {onCancelSchedule && (
                    <Tooltip title="Cancelar agendamento">
                      <IconButton aria-label="cancel-schedule" onClick={handleCancelShedule}>
                        <FaRegCalendarTimes />
                      </IconButton>
                    </Tooltip>
                  )}
                  {
                    onImport && (
                      <Tooltip title="Importar vídeos e playlists">
                        <IconButton aria-label="import" onClick={handleImport}>
                          <FiDownloadCloud />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </>
              }
              topFeatures={
                <>
                  {hasDownloadData &&  (
                    <Tooltip title="Download dos dados">
                      <IconButton aria-label="download dos dados" onClick={downloadDataEvent}>
                        <FaFileDownload />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasChartAttached &&  (
                    <Tooltip title="Mostrar gráfico">
                      <IconButton aria-label="mostrar gráfico" onClick={() => setShowChart(true)}>
                        <FaChartLine />
                      </IconButton>
                    </Tooltip>
                  )}
                  {
                    onCreate && (
                      <Tooltip title="Novo item">
                        <IconButton aria-label="novo item" onClick={onCreate}>
                          <FiPlus />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </>
              }
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <Header
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  visuallyHidden={classes.visuallyHidden}
                  onCreateSortHandler={createSortHandler}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id.toString());
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id.toString())}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {tableBodyCells(isItemSelected, labelId, row)}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              totalRows={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      }
    </>
  );
}
