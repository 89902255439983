export enum BannerNavigationEnum {
  RADIO_APARECIDA = 'RadioAparecidaHome',
  TV_APARECIDA = 'AparecidaTv',
  RADIO_POP = 'RadioPopHome',
  DEUS_CONOSCO = 'OurGodWithUsHome',
  SANTO_DO_DIA = 'SaintOfDay',
  DIA_A_DIA = 'DayToDay',
  FAMILIA_DOS_DEVOTOS = 'DevoteesFamilyHome',
  DOACAO = 'Donation',
  CADASTRO = 'NewRegister',
  CONTATO = 'DashboardHome',
  NOTICIAS = 'NewsDetails',
  CAMERA = 'ChosenCamera',
  MEDITACAO_DIARIA = 'DailyMeditation',
  EVANGELHO = 'ReadBible',
  SOBRE = 'About',
  APARECIDA_PLAY = "AparecidaPlayHome"
}
