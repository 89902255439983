import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer } from './styles';
import api from '../../services/api';

interface ConfirmRegisterDevoteeFormData {
  password: string;
  password_confirmation: string;
  code: string;
}

const ConfirmRegisterDevotee: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  
  const { addToast } = useToast();

  const history = useHistory();
  const location = useLocation();
  const queryString = new URLSearchParams(location.search)
  const [valid, setValid] = useState<number>(0) //0 = loading, 1 = false, 2 = true
  const [message, setMessage] = useState<string>("")
  
  useEffect(() => {
    api.get("/chats/check-token/" + queryString.get("hash") + "?type=" + queryString.get("type")).then((response) => {
      let devotees = response.data
      if (devotees.length > 0){
        let devotee = devotees[0]
        if (devotee.date_confirmation == null){
          let type = devotee.type
          if (type === "DEVOUT_MISSIONARY"){
            setMessage("na Família dos Devotos")
          }else if (type === "YOUNG_OF_MARY"){
            setMessage("no Jovens de Maria")
          }else{ //CHILD_DEVOTEE
            setMessage("no Devotos Mirins")
          }
          setValid(2)
        }else{ //já confirmou o cadastro anteriormente
          setValid(1)
        }
      }else{
        setValid(3) //não encontrou o cadastro
      }      
    }).catch((error) => {
      setValid(1)
    })
  }, [])

  return (
    <Container>
      <Content>
        <AnimationContainer style={{backgroundColor: "#fff", padding: 20, width: 500, maxWidth: "100%"}}>
          <img src={require("../../assets/logo.webp")} style={{marginBottom: 30}} />
          { valid === 0  && 
            <h2 style={{color: "#000", fontSize: "0.8em"}}>Aguarde um momento, por favor...</h2>
          }
          { valid === 1 && 
            <>
              <h2 style={{color: "#000"}}>Seu cadastro já foi validado.</h2>
              <a href="https://www.a12.com/">Acesse o Portal A12</a>
            </>
          }
          { valid === 2 && 
            <>
              <h2 style={{color: "#000"}}>CADASTRO CONFIRMADO COM SUCESSO</h2>
              <p style={{color: "#000", fontSize: "0.9em"}}>Que alegria acolher você {message}!<br />
              Juntos, assumimos a missão de propagar o amor à Nossa Senhora Aparecida, através das obras 
              de evangelização, acolhimento e manutenção da Casa da Mãe, da Rede Aparecida de Comunicação e de diversas obras sociais.<br />
              Em breve, você receberá um e-mail com um presente especial.
              </p>
              <a href="https://www.a12.com">Acesse o Portal A12</a>
            </>
          }
          { valid === 3 && 
            <>
              <h2 style={{color: "#000"}}>Complete seu cadastro no App.</h2>
              <a href="https://www.a12.com/">Acesse o Portal A12</a>
            </>
          }
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default ConfirmRegisterDevotee;
