import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { FiDatabase } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';

interface IContainerProps {
  show: boolean;
}

interface ILinkProps {
  hasPermission: boolean;
}

export const ContainerSubmenu = styled.div<IContainerProps>`
  display: ${(props) => (props.show) ? "block" : "none"}
`;

export const Content = styled.div`
  background-color: #28262e;
  transition: all 0.2s;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #B5EAD7;
  }  
`;

export const Container = styled.div`
  @media print {
    display: none;
  }
`;

export const Logo = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img.Logo {
    width: 7rem;
    height: 7rem;
    border-radius: 3.5rem;
    margin-bottom: 1rem;
  }
  h1 {
    color: #A4A4A4;
    font-weight: 200;
    font-size: 1.75rem;
    margin-bottom: 3vh;
    strong {
      word-break: break-all;
      color: #A4A4A4;
      font-weight: 700;
    }
  }

  a {
    text-decoration: none;
    color: #008FC3;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1rem;
  color: #A4A4A4;
`;

export const Text = styled.h3`
  font-size: 16px;
  transition: all 0.2s;
  &:hover {
    color: #008FC3;
    margin-left: 0.3rem;
  }
`;

export const ProfileContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Options = styled(AccordionSummary)`
  border: none;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  > div {
    background: #3e3b47;
  }
`;

export const StyledLink = styled(Link)<ILinkProps>`
  margin-left: 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  width: 100%;
  height: 50px;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
  color: #A4A4A4;

  display: ${(props) => props.hasPermission ? 'flex' : 'none'};

  svg {
    margin-right: 0.5rem;
  }
`;

export const HeadLink = styled.button<ILinkProps>`
  font-size: 1.25rem;
  text-decoration: none;
  width: 100%;
  height: 50px;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
  color: #A4A4A4;
  background: transparent;
  border: none;

  display: ${(props) => props.hasPermission ? 'flex' : 'none'};

  svg {
    margin-right: 0.5rem;
  }
`;

export const MinorLink = styled(Link)`
  font-size: 24px;
  text-decoration: none;
  background-color: #514f59;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
  color: #A4A4A4;
`;

export const MyReport = styled(FiDatabase)`
  color: #008FC3;
`;

export const Report = styled(FaInstagram)`
  color: #008FC3;
`;
