import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;

  form {
    margin: 40px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;

    h1 {
      text-align: center;
      margin-bottom: 24px;
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      width: 300px;
    }
  }
`;

export const EditorTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
  font-size: 1rem;
  color: #f4ede8;
`;