import axios from 'axios';

const legacyApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_LEGACY_URL,
  headers: {
    token: '$2y$12$KuecsEICYzzTlWwSIZntOulUc0rzhlme83XfM9ugNFaaF2AS8.Y7y',
  },
});

export default legacyApi;
