import React, { useCallback, useRef, useEffect, useState } from 'react';
import { BiFont } from 'react-icons/bi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';

interface AuthorFormData {
  name: string;
}

interface LocationState {
  id: string;
};

const EditAuthor = () => {
  const [author, setAuthor] = useState<AuthorFormData>();
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/authors/${state.id}`).then(response => {
      setAuthor(response.data);
    });

  }, [state.id]);

  const handleSubmit = useCallback(
    async (data: AuthorFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do autor é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
        } = data;

        await api.put(`/authors/${state.id}`, {
          name,
        });

        history.push('/author');

        addToast({
          type: 'success',
          title: 'Autor atualizado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar autor',
          description: 'Ocorreu um erro ao atualizar autor, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          initialData={{
            name: author?.name,
          }}
          onSubmit={handleSubmit}
        >
          <h1>Editar autor</h1>
          <Input name="name" icon={BiFont} label="Nome do autor" placeholder="Nome do autor" />

          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditAuthor;
