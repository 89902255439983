import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { FiPieChart, FiUser, FiPackage, FiCamera, FiSearch, FiAward, FiDatabase,FiPlayCircle } from 'react-icons/fi';
import { BsImage, BsPeople } from 'react-icons/bs';
import { MdLiveTv } from 'react-icons/md';
import { FaInstagram, FaNewspaper, FaCheckCircle, FaUsers, FaCloudSun, FaLink, FaInfo, FaClock, FaComment, FaGamepad } from 'react-icons/fa';
import { BiPen } from 'react-icons/bi';
import { IoIosNotifications } from 'react-icons/io';

interface IContentProps {
  toggle: boolean;
}

interface ILinkProps {
  hasPermission: boolean;
}

export const Content = styled.div<IContentProps>`
  position: fixed;
  background-color: #28262e;
  height: 100vh;
  width: 18vw;
  padding-top: 3vh;
  left: ${props => props.toggle ? '0vw' : '-18vw'};
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.2s;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #B5EAD7;
  }
  @media (max-width: 1024px) {
    z-index: 99;
    width: 70vw;
    left: ${props => props.toggle ? '0vw' : '-70vw'};
  }
`;

export const Container = styled.div`
  @media print {
    display: none;
  }
`;

export const Logo = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img.Logo {
    width: 7rem;
    height: 7rem;
    border-radius: 3.5rem;
    margin-bottom: 1rem;
  }
  h1 {
    color: #A4A4A4;
    font-weight: 200;
    font-size: 1.75rem;
    margin-bottom: 3vh;
    strong {
      word-break: break-all;
      color: #A4A4A4;
      font-weight: 700;
    }
  }

  a {
    text-decoration: none;
    color: #008FC3;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1rem;
  color: #A4A4A4;
`;

export const Text = styled.h3`
  font-size: 16px;
  transition: all 0.2s;
  &:hover {
    color: #008FC3;
    margin-left: 0.3rem;
  }
`;

export const ProfileContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Options = styled(AccordionSummary)`
  border: none;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  > div {
    background: #3e3b47;
  }
`;

export const StyledLink = styled(Link)<ILinkProps>`
  font-size: 1.25rem;
  text-decoration: none;
  width: 100%;
  height: 50px;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
  color: #A4A4A4;

  display: ${(props) => props.hasPermission ? 'flex' : 'none'};

  svg {
    margin-right: 0.5rem;
  }
`;

export const MinorLink = styled(Link)`
  font-size: 24px;
  text-decoration: none;
  background-color: #514f59;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
  color: #A4A4A4;
`;

export const MyProfile = styled(FiUser)`
  color: #008FC3;
`;

export const MyFiPieChart = styled(FiPieChart)`
  color: #008FC3;
`;

export const MyReport = styled(FiDatabase)`
  color: #008FC3;
`;

export const MyCamera = styled(FiCamera)`
  color: #008FC3;
`;

export const MyBanner = styled(BsImage)`
  color: #008FC3;
`;

export const MyPackage = styled(FiPackage)`
  color: #008FC3;
`;

export const MyMultimedia = styled(MdLiveTv)`
  color: #008FC3;
`;

export const MyNews = styled(FaNewspaper)`
  color: #008FC3;
`;

export const MyDailyMessaage = styled(FaCloudSun)`
  color: #008FC3;
`;

export const MyPen = styled(BiPen)`
  color: #008FC3;
`;

export const MyAuthor = styled(BsPeople)`
  color: #008FC3;
`;

export const MySearch = styled(FiSearch)`
  color: #008FC3;
`;

export const MyNotification = styled(IoIosNotifications)`
  color: #008FC3;
`;

export const Watermark = styled(FiAward)`
  color: #008FC3;
`;

export const UsersIcon = styled(FaUsers)`
  color: #008FC3;
`;

export const InfoIcon = styled(FaInfo)`
  color: #008FC3;
`;

export const PermissionsIcon = styled(FaCheckCircle)`
  color: #008FC3;
`;

export const Report = styled(FaInstagram)`
  color: #008FC3;
`;

export const MyLink = styled(FaLink)`
  color: #008FC3;
`;

export const MyChat = styled(FaComment)`
  color: #008FC3;
`;

export const PlayIcon = styled(FiPlayCircle)`
  color: #008FC3;
`;


export const ClockIcon = styled(FaClock)`
  color: #008FC3;
`;

export const GameIcon = styled(FaGamepad)`
color: #008FC3;
`;