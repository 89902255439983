import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Pagination from './Pagination';
import { TableProps } from './tableProps';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { format } from 'date-fns';
import moment from 'moment';
import api from '../../services/api';

export const SimpleTable = ({ headers, data }: TableProps) => {
  //ref
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [exibitionData, setExibitionData] = useState<any[]>(data.slice(0, 10));
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState('');

  const handlePageChange = () => {
    setExibitionData(
      data.slice(1 * page * rowsPerPage, 1 * page * rowsPerPage + rowsPerPage),
    );
    // console.log(page, rowsPerPage, exibitionData, 'aquii');
  };

  useEffect(() => {
    handlePageChange();
  }, [page, rowsPerPage, data]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportExcel = () => {
    api
      .get('/export/users', {responseType: 'blob'})
      .then(response => {
        console.log(response, 'response');

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users.xlsx');

        // Start download
        link.click();
      });
  };

  // // const downLoadFIleUsers = () => {
  //   const tableref = useRef<HTMLTableElement | null>(null);
  //   const { onDownload } = useDownloadExcel({
  //     // currentTableRef: tableref.current,
  //     currentTableRef: users,
  //     filename: 'users_info',
  //     sheet: 'UserData',
  //   });
  // // }

  return (
    <TableContainer component={Paper}>
      <Table
      // ref={tableref}
      >
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell>{header.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {exibitionData.map(item => (
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.fieldName}>
                  {header.fieldName === 'created_at'
                    ? moment(item[header.fieldName]).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )
                    : item[header.fieldName]
                    ? item[header.fieldName]
                    : `${header.label} não encontrado`}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        totalRows={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        type="button"
        onClick={exportExcel}
        loading={loading}
        style={{ maxWidth: 100, marginLeft: 10, marginBottom: 10 }}
      >
        Exportar
      </Button>
    </TableContainer>
  );
};
