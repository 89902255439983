const initDiagram =`<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_0vbeabr" targetNamespace="http://bpmn.io/schema/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="12.0.0">
  <bpmn:collaboration id="Collaboration_0394fau">
    <bpmn:participant id="Participant_1meaan8" processRef="Process_1psrxxk" />
  </bpmn:collaboration>
  <bpmn:process id="Process_1psrxxk" isExecutable="false" />
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_0394fau">
      <bpmndi:BPMNShape id="Participant_1meaan8_di" bpmnElement="Participant_1meaan8" isHorizontal="true">
        <dc:Bounds x="160" y="80" width="1670" height="780" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>`

export default initDiagram;
