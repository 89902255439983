import React, { useCallback, useRef, useState } from 'react';
import { FiCamera, FiYoutube } from 'react-icons/fi';
import { MdOndemandVideo } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import { StatusEnum } from '../../../utils/StatusEnum';
import { PriorityEnum } from '../../../utils/PriorityEnum';
import Checkbox from '../../../components/Checkbox';

interface CameraFormData {
  name: string;
  android_url: string;
  android_priority: string;
  ios_url: string;
  ios_priority: string;
  youtube_url: string;
  youtube_priority: string;
  status: string;
  autoplay: string;
}

const statusOptions = [
  { value: StatusEnum.INATIVO, label: 'Inativo' },
  { value: StatusEnum.ATIVO, label: 'Ativo' },
];

const priorityOptions = [
  { value: PriorityEnum.ALTA, label: 'Alta' },
  { value: PriorityEnum.MEDIA, label: 'Média' },
  { value: PriorityEnum.BAIXA, label: 'Baixa' },
];

const CreateCamera = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAutoPlay, setCheckedAutoPlay] = useState(false);


  const handleSubmit = useCallback(
    async (data: CameraFormData) => {
      setLoading(true);

      console.log(data, 'dados form')

      if(checkedAutoPlay){
        data.autoplay = 'Y'
      }else{
        data.autoplay = 'N'
      }

      console.log(data.autoplay, 'valor de autoplay pós if')

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da câmera é obrigatório'),
          android_url: Yup.string(),
          android_priority: Yup.string()
            .when('android_url', {
              is: val => !!val.length,
              then: Yup.string().required('Prioridade para m3u8 é obrigatória'),
              otherwise: Yup.string(),
            }),
          ios_url: Yup.string(),
          ios_priority: Yup.string()
            .when('ios_url', {
              is: val => !!val.length,
              then: Yup.string().required('Prioridade para JMV é obrigatória'),
              otherwise: Yup.string(),
            }),
          youtube_url: Yup.string(),
          youtube_priority: Yup.string()
            .when('youtube_url', {
              is: val => !!val.length,
              then: Yup.string().required('Prioridade para Youtube é obrigatória'),
              otherwise: Yup.string(),
            }),
          status: Yup.string().required('Status da câmera é obrigatório'),
          autoplay: Yup.string()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const status = data.status === 'true' ? true : false;

        const {
          name,
          android_url,
          android_priority,
          ios_url,
          ios_priority,
          youtube_url,
          youtube_priority,
          autoplay
        } = data;

        const response = await api.post('/cameras', {
          name,
          android_url,
          android_priority: android_url ? Number(android_priority) : null,
          ios_url,
          ios_priority: ios_url ? Number(ios_priority) : null,
          youtube_url,
          youtube_priority: youtube_url ? Number(youtube_priority) : null,
          active: status,
          is_default: checked,
          autoplay
        });

        history.push('/camera/upload', { id: response.data.id });

        addToast({
          type: 'success',
          title: 'Câmera cadastrada!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar câmera',
          description: 'Ocorreu um erro ao cadastrar câmera, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, checked, checkedAutoPlay],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Criar câmera</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <div>
            <Select
              name="status"
              options={statusOptions}
              label="Selecione o status da câmera"
              style={{ marginBottom: '7px' }}
            />
            <Input name="name" icon={FiCamera} label="Nome da câmera" placeholder="Nome da câmera" />
            <Select
              name="android_priority"
              options={priorityOptions}
              label="Selecione a prioridade para o m3u8"
              style={{ marginBottom: '7px' }}
            />
            <Input name="android_url" icon={MdOndemandVideo} label="Link para m3u8" placeholder="Link para m3u8" />
          </div>
          <div>
            <Select
              name="ios_priority"
              options={priorityOptions}
              label="Selecione a prioridade para o JMV"
              style={{ marginBottom: '7px' }}
            />
            <Input name="ios_url" icon={MdOndemandVideo} label="Link para JMV" placeholder="Link para JMV" />

            <Select
              name="youtube_priority"
              options={priorityOptions}
              label="Selecione a prioridade para o Youtube"
              style={{ marginBottom: '7px' }}
            />
            <Input name="youtube_url" icon={FiYoutube} label="Link para youtube" placeholder="Link para youtube" />
            <Checkbox
              name="checkbox"
              checkboxName="Câmera padrão"
              checked={checked}
              onChecked={() => setChecked(!checked)}
            />
              <Checkbox
              name="checkbox-camera"
              checkboxName="Autoplay"
              checked={checkedAutoPlay}
              onChecked={() => setCheckedAutoPlay(!checkedAutoPlay)}
            />
          </div>
          <Button type="submit" loading={loading}>Salvar</Button>          
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default CreateCamera;
