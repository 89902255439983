import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';


import Button from '../../../components/Button';
import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import { BiCalendarEdit, BiFont } from 'react-icons/bi';
import Input from '../../../components/Input';
import { useToast } from '../../../hooks/toast';
import {
  Content
} from './styles';

const VideoCreate = () => {
  const [loading, setLoading] = useState(false);
  const [newsText, setNewsText] = useState('');
  const [isDefault, setCheckedHome] = useState(false);

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
     

        const schema = Yup.object().shape({
          description: Yup.string(),
          url: Yup.string().required('Url é obrigatorio'),
          title: Yup.string().required('Titulo é obrigatorio'),
          channel: Yup.string().required('Canal é obrigatorio'),
          uploadDate: Yup.date().required('Necessario informa data do upload'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });


        setLoading(true);


        await api.post('/videos', data);

        addToast({
          type: 'success',
          title: 'Mensagem do dia cadastrada!',
        });

        history.push('/video');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);


        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar video',
          description: 'Erro no processo de cadastro',
        });
      }
      setLoading(false);
    },
    [
      newsText,
      isDefault
    ],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Crair detalhes de video</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={
            {
              description: null,
              title: "",
              url: "",
              channel: "",
              uploadDate: ""
            }
          }
        >
          <div>
            <Input name="title" icon={BiFont} label="Titulo do video" placeholder="" />
            <Input name="description" icon={BiFont} label="Descrição do video" placeholder="" />
            <Input name="url" icon={BiFont} label="Url do video" placeholder="" />
            <Input name="channel" icon={BiFont} label="Nome do canal" placeholder="" />
            <Input name="uploadDate" type="datetime-local" icon={BiCalendarEdit} label="Data do upload" placeholder="" />

            <Button type="submit" loading={loading}>Salvar</Button>
          </div>

        </Form>
      </Content>
    </StandardContainer>
  );
}

export default VideoCreate;
