export default class MyPaletteProvider {

    translate: any;
    eventBus: any;
    static $inject: string[];

    constructor(eventBus: any, palette: any, translate: any) {
      this.eventBus = eventBus;
      this.translate = translate;
  
      palette.registerProvider(this);
    }
  
    getPaletteEntries(element: HTMLElement) {
      return function (entries: any) {
        delete entries["hand-tool"];
        delete entries["lasso-tool"];
        delete entries["space-tool"];
        delete entries["global-connect-tool"];
        delete entries["create.intermediate-event"];
        delete entries["create.subprocess-expanded"];
        delete entries["create.data-object"];
        delete entries["create.data-store"];
        delete entries["create.participant-expanded"];
        delete entries["create.group"];
  
        return entries;
      };
    }
  }
  
  MyPaletteProvider.$inject = ["eventBus", "palette", "translate"];
  