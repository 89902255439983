import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableMultimedia from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { MediaTypeEnum } from '../../utils/MediaTypeEnum';

interface Data {
  id: string;
  code: number;
  name: string;
  android_url: string;
  ios_url: string;
  youtube_url: string;
  media_type: number;
  active: boolean;
  is_default: boolean;
  icon_url: string;
  radio_url: string;
  autoplay: string;
}

interface IMultimedia {
  id: number;
  name: string;
  android_url: string;
  ios_url: string;
  youtube_url: string;
  media_type: number;
  active: boolean;
  is_default: boolean;
  icon_url: string;
  radio_url: string;
  autoplay: string;
}

function createData(
  id: string,
  code: number,
  name: string,
  android_url: string,
  ios_url: string,
  youtube_url: string,
  media_type: number,
  active: boolean,
  is_default: boolean,
  icon_url: string,
  radio_url: string,
  autoplay: string,
): Data {
  return { id, code, name, android_url, ios_url, youtube_url, media_type, active, is_default, icon_url, radio_url, autoplay };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'android_url', numeric: false, disablePadding: false, label: 'URL m3u8' },
  { id: 'ios_url', numeric: false, disablePadding: false, label: 'URL JMV' },
  { id: 'youtube_url', numeric: false, disablePadding: false, label: 'URL Youtube' },
  { id: 'radio_url', numeric: false, disablePadding: false, label: 'URL Rádio' },
  { id: 'media_type', numeric: true, disablePadding: false, label: 'Tipo de mídia' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'is_default', numeric: false, disablePadding: false, label: 'Padrão' },
  { id: 'icon_url', numeric: false, disablePadding: false, label: 'Imagem' },
  { id: 'autoplay', numeric: false, disablePadding: false, label: 'AutoPlay' },
];

const Multimedia = () => {
  const [rows, setRows] = useState<IMultimedia[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/stream_multimedia/').then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/stream_multimedia/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Transmissão deletada com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])

  const multimediaRows = useMemo(() => {
    return rows.map(row => createData(
      row.id.toString(),
      row.id,
      row.name,
      row.android_url,
      row.ios_url,
      row.youtube_url,
      row.media_type,
      row.active,
      row.is_default,
      row.icon_url,
      row.radio_url,
      row.autoplay
    ));
  }, [rows]);

  const onUpload = useCallback((row) => {
    history.push('/multimedia/upload', { id: row.code });
  }, [history]);

  const onCreate = useCallback(() => {
    history.push('/multimedia/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/multimedia/edit', { id: row.code });
  }, [history]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => {
    let mediaType;

    switch (row.media_type) {
      case MediaTypeEnum.RADIO:
        mediaType = 'Rádio';
        break;
      case MediaTypeEnum.TV:
        mediaType = 'TV';
        break;
      case MediaTypeEnum.RADIO_TV:
        mediaType = 'Rádio e TV';
        break;
      default:
        mediaType = 'Vazio';
        break;
    }

    return (
      <>
        <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left"><a href={row.android_url !== '' && row.android_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.android_url?.substring(0, 12)}...</a></TableCell>
        <TableCell align="left"><a href={row.ios_url !== '' && row.ios_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.ios_url?.substring(0, 12)}...</a></TableCell>
        <TableCell align="left"><a href={row.youtube_url !== '' && row.youtube_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.youtube_url?.substring(0, 12)}...</a></TableCell>
        <TableCell align="left"><a href={row.radio_url !== '' && row.radio_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.radio_url?.substring(0, 12)}...</a></TableCell>
        <TableCell align="left">{mediaType}</TableCell>
        <TableCell align="left">{row.active ? 'Ativo' : 'Inativo'}</TableCell>
        <TableCell align="left">{row.is_default ? 'Sim' : 'Não'}</TableCell>
        <TableCell align="left"><a href={row.icon_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>Abrir imagem</a></TableCell>
        <TableCell align="left">{row.autoplay}</TableCell>

      </>
  )}, []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableMultimedia<Data, HeadCell[]>
          title='Transmissões ao vivo'
          headCells={headCells}
          rows={multimediaRows}
          orderByDefaultColumn='code'
          onCreate={onCreate}
          onDelete={onDelete}
          onUpload={onUpload}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Multimedia;
