import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';

import { Content } from './styles';
import { FiLock, FiMail, FiUser } from 'react-icons/fi';

interface UserFormData {
  name: string;
  new_email: string;
  new_password: string;
  new_password_confirmation: string;
}

const CreateUser = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do usuário é obrigatório'),
          new_email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          new_password: Yup.string().required('Campo obrigatório'),
          new_password_confirmation: Yup.string()
            .when('new_password', {
              is: val => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf(
              [Yup.ref('new_password'), undefined],
              'Confirmação de senha incorreta',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.new_email,
          password: data.new_password,
        };

        await api.post('/users', formData);

        history.push('/users');

        addToast({
          type: 'success',
          title: 'Usuário cadastrado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar usuário',
          description: 'Ocorreu um erro ao cadastrar usuário, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h1>Criar usuário</h1>
          <Input name="name" icon={FiUser} label="Nome do usuário" placeholder="Nome do usuário" />
          <Input name="new_email" icon={FiMail} label="E-mail" placeholder="E-mail" />

          <Input
            name="new_password"
            icon={FiLock}
            type="password"
            label="Nova senha"
            placeholder="Nova senha"
          />

          <Input
            name="new_password_confirmation"
            icon={FiLock}
            type="password"
            label="Confirmar senha"
            placeholder="Confirmar senha"
          />

          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  );
}

export default CreateUser;
