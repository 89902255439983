import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import Swal from 'sweetalert2';
import { ImportButton } from '../Banner/styles';


interface Data {
  id: string;
  code: number;
  description: string;
  externalId: string;
  title: string;
  channel: string;
  channelName: string;
  uploadDate: Date;
  importDate: Date;
  priority: number;
}

interface IPlaylist {
  id: number,
  description: string,
  externalId: string,
  title: string,
  channel: string,
  channelName: string,
  uploadDate: Date,
  importDate: Date,
  priority: number,
}


interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Titulo' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Descrição' },
  // { id: 'externalId', numeric: false, disablePadding: false, label: 'YT ID' },
  { id: 'channelName', numeric: false, disablePadding: false, label: 'Canal' },
  { id: 'uploadDate', numeric: false, disablePadding: false, label: 'Data upload' },
  { id: 'importDate', numeric: false, disablePadding: false, label: 'Data importe' },
  { id: 'priority', numeric: false, disablePadding: false, label: 'Prioridade' },
];

const PlaylistList = () => {
  const [rows, setRows] = useState<IPlaylist[]>([]);
  const [loading, setLoading] = useState(true)

  let location = useLocation<{ channel: string }>();

  const mappedChannels = new Map()
  mappedChannels.set("UCfG3nAoJrfqDZ2Q9gg5I46Q", "Aparecida Ao Vivo")
  mappedChannels.set("UCRWa9z4GzZZb0szyME8tysg", "A Rádio POP")
  mappedChannels.set("UC_t-KziRBosCVyFW1iT8X_Q", "Devotos Mirins")
  mappedChannels.set("UCaYnce-Vi5JL1Y7WoczllNQ", "A12")
  mappedChannels.set("UCdB5obv0acug-GcANICp_gA", "Rádio Aparecida")
  mappedChannels.set("UCfYrK5JU5EznsnK3wQE7iIg", "TV Aparecida")
  mappedChannels.set("UChdIDPYu280yFB1iXTP8gMQ", "Jovens de Maria")
  mappedChannels.set("UCsmc-icGWeZe_7lZKzGc76w", "Redentoristas")
  mappedChannels.set("UCuSeVCzI21lPeKnIzZehFPQ", "Santuário Nacional")

  const { addToast } = useToast();

  const history = useHistory();

  const createData = (
    id: string,
    code: number,
    description: string,
    externalId: string,
    title: string,
    channel: string,
    channelName: string,
    uploadDate: Date,
    importDate: Date,
    priority: number,
  ): Data => {
    channelName = mappedChannels.get(channel)
    return { id, code, description, externalId, title, channel, channelName, uploadDate, importDate, priority };
  }


  useEffect(() => {
    getData()

  }, [])


  const getData = () => {
    api.get(`/videos/channel/${location.state.channel}/playlists`).then(response => {
      let rows = response.data
      rows = rows.map((row: IPlaylist) => {
        row.channelName = mappedChannels.get(row.channel)
        return row
      })
      setRows(response.data);
      setLoading(false)
    });
  }


  const newsRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.description, row.externalId,
      row.title, row.channel, row.channelName, row.uploadDate, row.importDate, row.priority));
  }, [rows]);

  const handleChangePosition = useCallback(async (row) => {

    Swal.fire({
      title: `Deseja alterar a prioridade da playlist ${row.title}?`,
      text: "A prioridade é utilizada para definir a ordem de exibição das playlists",
      icon: 'question',
      input: 'number',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      showLoaderOnConfirm: true,
      inputAttributes: {
        min: '1',
      },
      preConfirm: (priority) => {
        return api.patch(`/videos/playlist/${row.id}/position`, { priority })
          .then(response => {
            addToast({
              type: 'success',
              title: 'Prioridade alterada com sucesso!',
            });
            getData()
          }).catch(error => {
            addToast({
              type: 'error',
              title: 'Erro ao alterar prioridade!',
              description: 'Ocorreu um erro ao alterar a prioridade da playlist, tente novamente mais tarde.'
            });
          })
      }
    })

  }, [rows]);


  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.title}</TableCell>
      <TableCell align="left">{row.description}</TableCell>

      <TableCell align="left">{row.channelName}</TableCell>
      <TableCell align="left">{format(parseISO(row.importDate), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
      <TableCell align="left">{format(parseISO(row.uploadDate), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
      <TableCell align="left">
        <div style={{ display: 'flex' }}>
          <ImportButton style={{width: '40px'}} onClick={() => handleChangePosition(row)}>{row.priority + 1}</ImportButton>
        </div>
      </TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title={`Playlists do Youtube - ${mappedChannels.get(location.state.channel)}`}
          headCells={headCells}
          rows={newsRows}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default PlaylistList;
