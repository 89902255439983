import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

interface IPagination {
  totalRows: number;
  rowsPerPage: number;
  page: number;
  onPageChange: any;
  onRowsPerPageChange: any;
}

const Pagination: React.FC<IPagination> = ({
  totalRows,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 15, 25, 50]}
      component="div"
      count={totalRows}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}

export default Pagination;
