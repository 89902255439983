import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;

  form {
    margin: 40px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;

    h1 {
      text-align: center;
      margin-bottom: 24px;
    }

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
`;

export const EditorTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
  font-size: 1rem;
  color: #f4ede8;
`;

export const QuestionContainer = styled.div`
  grid-column: 1 / -1;
  max-width: 800px;
`;

export const AlternativeContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  max-width: 800px;
`;

export const StyledTextarea = styled.textarea`
  height: 100px;
  width: 100%; 
  resize: vertical; /* permite redimensionar verticalmente */
  vertical-align: top; /* alinha o texto próximo à margem superior */
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #333;
  margin-top: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid  #008FC3;
  padding: 10px;
`;

export const Label = styled.label`
`;
