import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  title: string;
  link: string;
  publicatedAt: Date;
  description: string;
  durationTime: number;
  imageUrl: string;
}

interface IPodcastItem {
  id: number;
  title: string;
  link: string;
  publicatedAt: Date;
  description: string;
  durationTime: number;
  imageUrl: string;
}

function createData(
  id: string,
  code: number,
  title: string,
  link: string,
  publicatedAt: Date,
  description: string,
  durationTime: number,
  imageUrl: string
): Data {
  return { id, code, title, link, publicatedAt, description, durationTime, imageUrl };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Titulo' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'imageUrl', numeric: false, disablePadding: false, label: 'Img URL' },
  { id: 'durationTime', numeric: false, disablePadding: false, label: 'Duração' },
  { id: 'publicatedAt', numeric: false, disablePadding: false, label: 'Data publicação' },
  { id: 'link', numeric: false, disablePadding: false, label: 'Soundcloud link' },
];

const PodcastList = () => {
  const [rows, setRows] = useState<IPodcastItem[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api.get(`/podcast`, { params: { limit: 1000000 } })
      .then(response => {
        setRows(response.data);
        setLoading(false);
      });
  }, []);


  const newsRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.title, row.link, row.publicatedAt,
      row.description, row.durationTime, row.imageUrl));
  }, [rows]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.title}</TableCell>
      <TableCell align="left">{row.description}</TableCell>
      <TableCell align="left">{row.imageUrl}</TableCell>
      <TableCell align="left">{numberSecondsToString(row.durationTime)}</TableCell>
      <TableCell align="left">{format(new Date(row.publicatedAt), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
      <TableCell align="left">{row.link}</TableCell>

    </>
  ), []);

  const numberSecondsToString = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);
    const secondsLeft = seconds - (hours * 3600) - (minutes * 60);

    const hoursString = hours <= 9 ? `0${hours}` : hours;
    const minutesString = minutes <= 9 ? `0${minutes}` : minutes;
    const secondsString = secondsLeft <= 9 ? `0${secondsLeft}` : secondsLeft;

    return `${hoursString}:${minutesString}:${secondsString}`;
  }

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title='Soundcloud podcasts'
          headCells={headCells}
          rows={newsRows}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default PodcastList;
