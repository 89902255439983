import React, { FunctionComponent, useRef } from "react";
import { TOptionsGateway } from "../..";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import { BiFont } from "react-icons/bi";
import Button from "../../../../components/Button";
interface GatewayFormProps {
    optionsGateway: TOptionsGateway[]
}
 
const GatewayForm: FunctionComponent<GatewayFormProps> = ({optionsGateway}) => {

    const optionsComparison = [
        {label: 'Igual a', value: 'equalsTo'},
        {label: 'Maior que', value: 'moreThan'},
        {label: 'Menor que', value: 'lessThan'}
    ]
    const formRef = useRef<FormHandles>(null);
    const handleSubmit = () => { console.log('form enviado') }

    return (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={
            {
              phrase: ""
            }
          }
        >
             <Select name="field" label="Campo" options={optionsGateway} onChange={(e) => console.log(e.target.value)} />
             <Select name="comparison" label="Comparação" options={optionsComparison} onChange={(e) => console.log(e.target.value)} />
             <Input name="comparisonValue" icon={BiFont} label="Valor" placeholder="Valor" />
             <Button type="submit">Salvar</Button>
        </Form>
    );
}
 
export default GatewayForm;