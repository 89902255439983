import React, { ChangeEvent, useCallback, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import Button from '../../components/Button';

import { Content, UploadPlaceInput } from './styles';
import StandardContainer from '../../components/StandardContainer';
import Header from '../../components/Header';

interface LocationState {
  id: string;
};

const PlaceUpload = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [imgSize, setImgSize] = useState({ height: 0, width: 0 });

  const handleImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];

        const img = new Image()
        var _URL = window.URL || window.webkitURL;

        var objectUrl = _URL.createObjectURL(file);
        img.src = objectUrl;

        img.onload = () => {
          const { height, width } = img

          console.log({ height, width })
          setImgSize({ height, width })

        }


        setFile(file)
      }
    },
    [setFile, setImgSize],
  );

  const handleUploadImage = useCallback(async () => {
    setLoading(true);
    try {
      if (file) {
        const data = new FormData();
        data.append('image', file);

        await api.patch(`/places/${state.id}/image`, data);
        history.push('/places');

        addToast({
          type: 'success',
          title: 'Imagem do Ponto do Mapa atualizada!',
        });
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar imagem!',
        description: 'Tente novamente mais tarde :)',
      });
    }

    setLoading(false);
  }, [addToast, file, history, state.id, imgSize]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Adicionar imagem do Ponto do Mapa</h1>

        <UploadPlaceInput>
          <input disabled value={file?.name} />
          <label htmlFor="uploadPlace">
            <FiUpload />
            <input type="file" id="uploadPlace" onChange={handleImageChange} />
          </label>
        </UploadPlaceInput>
        {imgSize.width > 0 && imgSize.height > 0 && (
            <span>
              {imgSize.width} x {imgSize.height}
            </span>
          )}
        <Button onClick={handleUploadImage} loading={loading}>Salvar</Button>
      </Content>
    </StandardContainer>
  )
}

export default PlaceUpload;
