import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.header`
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: auto;
    background: transparent;
    border: 0;
    padding: 15px;
    background: #3e3b47;
    border-radius: 10px;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;

      &:hover {
        color: ${shade(0.2, '#008FC3')};
      }
    }
  }
`;
