import React, { useCallback, useRef, useEffect, useState } from 'react';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink, FiInfo } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content, EditorTitle } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import { Editor } from '@tinymce/tinymce-react';
import Select from '../../../components/Select';
import { StatusEnum } from '../../../utils/StatusEnum';


interface PlaceData {
  name: string;
  description?: string;  
  image_url?: string;
  video_url?: string;
  link_portal: string;
  latitude: number;
  longitude: number;
  altitude?: number;
  visible: boolean;
}

interface PlaceFormData {
  name: string;
  description?: string;  
  image_url?: string;
  video_url?: string;
  link_portal: string;
  latitude: number;
  longitude: number;
  altitude?: number;
  visible: boolean;
}

interface LocationState {
  id: string;
};

const visibleOptions = [
  { value: StatusEnum.INATIVO, label: 'Não' },
  { value: StatusEnum.ATIVO, label: 'Sim' },
];

const EditPlace = () => {
  const [place, setPlace] = useState<PlaceData>();
  const [loading, setLoading] = useState(false);
  const [placeText, setPlaceText] = useState('');
  
  const formRef = useRef<FormHandles>(null);
  const editorRef = useRef<any>(null);
  const { addToast } = useToast();
  const [countChars, setCountChars] = useState(0);
  const MAX_CHARS = 4000

  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/places/${state.id}`).then(response => {
      setPlace(response.data);
      setPlaceText(response.data.description);
    });

  }, [state.id]);

  const getCountChars = (): number => {
    const rawContent = editorRef.current.getContent({ format: 'raw' }).replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g,"").trim()
    return rawContent.length
  }

  const saveText = () => {
    if (editorRef.current) {
      setPlaceText(editorRef.current.getContent());
    }
  };

  const handleSubmit = useCallback(
    async (data: PlaceFormData) => {      
      try {
        formRef.current?.setErrors({});

        data.description = placeText;
        
        if (getCountChars() > MAX_CHARS){
          alert(`A quantidade de caracteres na descrição está excedendo o máximo permitido: ${MAX_CHARS} caracteres`)
          return false
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          description: Yup.string(),
          video_url: Yup.string(),
          link_portal: Yup.string(),
          latitude: Yup.number().required('Latitude é obrigatória'),
          longitude: Yup.number().required('Longitude é obrigatória'),
          altitude: Yup.number()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true)

        await api.put(`/places/${state.id}`, {
          ...data          
        });

        history.push('/places');

        addToast({
          type: 'success',
          title: 'Ponto do mapa atualizado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar ponto do mapa',
          description: 'Ocorreu um erro ao atualizar ponto do mapa, tente novamente.',
        });
      }
      setLoading(false);
    },
    [
      addToast,
      history,
      state.id,
      placeText      
    ],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar Ponto do Mapa</h1>
        <Form
          ref={formRef}
          initialData={{
            name: place?.name,
            description: place?.description,
            image_url: place?.image_url,
            video_url: place?.video_url,
            link_portal: place?.link_portal,
            latitude: place?.latitude,
            longitude: place?.longitude,
            altitude: place?.altitude
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Input name="name" icon={BiFont} label="Nome do local" placeholder="Nome do ponto no mapa" />
            <EditorTitle>Descrição:</EditorTitle>
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={place?.description}
              apiKey={process.env.REACT_APP_TINYMCE_KEY}
              onBlur={saveText}
              init={{
                width: "100%",
                height: 300,
                menubar: false,
                plugins: 'code',
                toolbar: `
                  undo redo | bold italic | code
                `,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                setup: function (ed) {
                  var allowedKeys = [8, 37, 38, 39, 40, 46, 16, 17, 18]; // backspace, delete and cursor keys, shift, ctrl, alt
                  ed.on('keydown', function (e) {
                    if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
                    if (getCountChars() + 1 > MAX_CHARS) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                    return true;
                  });
                  ed.on('keyup', function (e) {
                    setCountChars(getCountChars())                      
                  });
                },
                init_instance_callback: function () { // initialize counter div
                    setCountChars(getCountChars())
                },
              }}
            />
            <span>{countChars} de {MAX_CHARS}</span>            
          </div>
          <div>
            <Input name="video_url" icon={FiYoutube} label="URL do Youtube" placeholder="URL do Youtube" />            
            <Input name="link_portal" icon={FiLink} label="Link do portal" placeholder="Link do portal" />
            <Input name="latitude" icon={FiInfo} label="Latitude" placeholder="Latitude" />
            <Input name="longitude" icon={FiInfo} label="Longitude" placeholder="Longitude" />
            <Input name="altitude" icon={FiInfo} label="Altitude" placeholder="Altitude" />
            <Select
              name="visible"
              options={visibleOptions}
              defaultOption={String(place?.visible)}
              label="Vísivel para os usuários"
              style={{ marginBottom: '7px' }}
            />
          </div>
          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditPlace;
