import React, { FunctionComponent, InputHTMLAttributes, useRef, useState } from "react";

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Input from '../../../../components/Input';
import { BiFilter, BiFont, BiMinus, BiPlus } from "react-icons/bi";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import { Box } from "@material-ui/core";
import DateInput from "../../../../components/DateInput";
import DatetimeInput from "../../../../components/DatetimeInput";
import Checkbox from "../../../../components/Checkbox";

interface TaskFormProps {
    
}

const TaskForm: FunctionComponent<TaskFormProps> = () => {

    const formRef = useRef<FormHandles>(null);
    const [selectedType, setSelectedType] = useState('')
    const listTypes = [
        {label: 'Texto', value: 'text'},
        {label: 'Lista', value: 'list'},
        {label: 'Data', value: 'date'},
        {label: 'Número', value: 'number'}
    ]
    const handleSubmit = () => { console.log('form enviado') }

    return (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={
            {
              phrase: ""
            }
          }
        >
          <div>
            <Input name="phrase" icon={BiFont} label="Frase para o usuário" placeholder="Frase" />
            <Select name="fieldType" label="Tipo de campo" options={listTypes} onChange={(e) => setSelectedType(e.target.value)} />
            {(() => {
                switch (selectedType) {
                    case 'text':
                        return <Input name="regexValidation" icon={BiFilter} label="Regex do campo" placeholder="Regex" />;
                    case 'date':
                        return (
                            <Box sx={{display: 'flex', marginTop: '1rem'}} style={{gap: '50px'}}>
                                <DatetimeInput id="min_date_task" label="Min" name="min" onChange={(e) => console.log(e.target.value)}/>
                                <DatetimeInput id="max_date_task" label="Max" name="max" onChange={(e) => console.log(e.target.value)}/>
                            </Box>
                        );
                    case 'number':
                        return (
                                <Box sx={{display: 'flex', marginTop: '1rem'}} style={{gap: '50px'}}>
                                    <Input name="min_number_task" icon={BiMinus} label="Min" placeholder="Min"/>
                                    <Input name="max_number_task" icon={BiPlus} label="Max" placeholder="Max"/>
                                </Box>
                            );
                    case 'list':
                            return <Checkbox name="select_only_one" checkboxName="Permitir apenas uma seleção" checked={false} onChecked={() => {}} />;
                    default:
                        break;
                }
            })()}
            <Button type="submit">Salvar</Button>
          </div>

        </Form>
    );
}
 
export default TaskForm;