import React, { useCallback, useRef, useState } from 'react';

import { BiFont } from 'react-icons/bi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';

import { Content } from './styles';

interface AuthorFormData {
  name: string;
}

const CreateAuthor = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: AuthorFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do autor é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
        } = data;

        await api.post('/authors', {
          name,
        });

        history.push('/author');

        addToast({
          type: 'success',
          title: 'Autor cadastrado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar autor',
          description: 'Ocorreu um erro ao cadastrar autor, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h1>Criar autor</h1>
          <Input name="name" icon={BiFont} label="Nome do autor" placeholder="Nome do autor" />

          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  );
}

export default CreateAuthor;
