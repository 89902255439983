import React, { useCallback, useRef, useEffect, useState } from 'react';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink } from 'react-icons/fi';
import { GrSoundcloud } from 'react-icons/gr';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content, EditorTitle } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import { Editor } from '@tinymce/tinymce-react';

import { NewsTypeEnum } from '../../../utils/NewsTypeEnum';
import { ObjectEvent } from 'ol/Object';
import { AlternativeContainer, QuestionContainer } from '../Create/styles';
import Textarea from '../components/TextArea';

interface QuestionGameBibleFormData {
  id: string;
  question: string;
  alternative1: string;
  alternativeCorrect1: string;
  alternative2: string;
  alternativeCorrect2: string;
  alternative3: string;
  alternativeCorrect3: string;
  alternative4: string;
  alternativeCorrect4: string;
  difficultyLevel: string;
  type: string;
}

interface LocationState {
  id: string;
  //   screen: string;
}

const optionsLevel = [
  { value: 'Facil', label: 'Fácil' },
  {
    value: 'Medio',
    label: 'Médio',
  },
  {
    value: 'Dificil',
    label: 'Difícil',
  },
];

const optionsChoice = [
  {
    value: 'UNICA_ESCOLHA',
    label: 'Única escolha',
  },
];

const EditQuestionGameBible = () => {
  const [loading, setLoading] = useState(false);
  const [objQuestion, setObjQuestion] = useState<QuestionGameBibleFormData>();
  const [alternatives, setAlternatives] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();

  const { state } = useLocation<LocationState>();

  console.log(state, 'state');

  useEffect(() => {
    console.log(state.id, 'state');
    api.get(`/game-bible/${state.id}`).then(response => {
      //   console.log(response.data, 'data');
      setObjQuestion(response.data);
      setAlternatives([
        response.data.altervative1,
        response.data.altervative2,
        response.data.altervative3,
        response.data.altervative4,
      ]);

      const verifyCorrectAnswer = [
        response.data.alternativeCorrect1,
        response.data.alternativeCorrect2,
        response.data.alternativeCorrect3,
        response.data.alternativeCorrect4,
      ];

      const initialCheckedState = verifyCorrectAnswer.map(
        answer => answer === 'S',
      );
      setIsChecked(initialCheckedState);
    });
  }, []);

  const handleCheckboxChange = (index: number) => {
    const newIsChecked = isChecked.map((value, idx) => idx === index); // marca o checkbox atual
    setIsChecked(newIsChecked); // define o novo estado de isChecked
  };

  const handleAlternativeChange = (index: number, value: string) => {
    const newAlternatives = [...alternatives];
    newAlternatives[index] = value;
    setAlternatives(newAlternatives);
  };

  const handleSubmit = useCallback(
    async (data: QuestionGameBibleFormData) => {
      console.log(isChecked, 'estado de isChecked');
      try {
        // se nenhum checkbox estiver marcado (valores em isChecked, todos false ),
        //  a expressão isChecked.some() retorna false
        //  o ! irá negar esse valor, tornando a condição do if true,
        //  sendo true, a mensagem de erro é exibida
        if (!isChecked.some(value => value === true)) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione uma resposta correta.',
          });
          return;
        }

        if (data.question === '') {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Envie uma questão.',
          });
          return;
        }

        if (data.difficultyLevel === '') {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione um nível',
          });
          return;
        }
        if (data.type === '') {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione única escolha',
          });
          return;
        }

        const verifyAlternatives = [
          data.alternative1,
          data.alternative2,
          data.alternative3,
          data.alternative4,
        ];

        for (let index: number = 0; index < verifyAlternatives.length; index++) {
          if (verifyAlternatives[index] === '') {
            addToast({
              type: 'error',
              title: 'Erro',
              description: 'Preencha todas as alternativas.',
            });
            return;
          }
        }

        const formData = {
          ...data,
          alternativeCorrect1: isChecked[0] ? 'S' : 'N',
          alternativeCorrect2: isChecked[1] ? 'S' : 'N',
          alternativeCorrect3: isChecked[2] ? 'S' : 'N',
          alternativeCorrect4: isChecked[3] ? 'S' : 'N',
        };

        setLoading(true);
        api
          .put(`/game-bible/${state.id}`, {
            ...formData,
          })
          .then(res => {
            history.push('/bible-game');
            addToast({
              type: 'success',
              title: 'Questão atualizada!',
            });
          })
          .catch(err => console.log(err));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar questão',
          description:
            'Ocorreu um erro ao atualizar a questão, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id, isChecked],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar Pergunta</h1>
        <Form
          ref={formRef}
          initialData={{
            question: objQuestion?.question,
            alternative1: objQuestion?.alternative1,
            alternativeCorrect1: objQuestion?.alternativeCorrect1,
            alternative2: objQuestion?.alternative2,
            alternativeCorrect2: objQuestion?.alternativeCorrect2,
            alternative3: objQuestion?.alternative3,
            alternativeCorrect3: objQuestion?.alternativeCorrect3,
            alternative4: objQuestion?.alternative4,
            alternativeCorrect4: objQuestion?.alternativeCorrect4,
            difficultyLevel: objQuestion?.difficultyLevel,
            type: objQuestion?.type,
          }}
          onSubmit={handleSubmit}
        >
          <QuestionContainer>
            <Textarea
              name="question"
              label="Questão"
              placeholder="Descrição da questão"
              defaultValue={objQuestion?.question}
            />
            {alternatives.length > 0 &&
              alternatives.map((alternative, index) => (
                <AlternativeContainer key={index}>
                  <Checkbox
                    name={`alternativeCorrect${index + 1}`}
                    checkboxName={''}
                    checked={isChecked[index]}
                    onChecked={() => handleCheckboxChange(index)}
                  />
                  <Input
                    name={`alternative${index + 1}`}
                    icon={BiFont}
                    label={`Alternativa ${index + 1}`}
                    value={alternative}
                    onChange={e =>
                    handleAlternativeChange(index, e.target.value)
                    }
                  />
                </AlternativeContainer>
              ))}
            <Select
              name="difficultyLevel"
              options={optionsLevel}
              label="Selecione a dificuldade"
              style={{ marginBottom: '10px' }}
              defaultOption={objQuestion?.difficultyLevel}
            />
            <Select
              name="type"
              options={optionsChoice}
              label="Selecione única escolha"
              style={{ marginBottom: '10px' }}
              defaultOption={objQuestion?.type}
            />
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </QuestionContainer>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default EditQuestionGameBible;
