import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { MdComputer, MdSearch } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';
import { Content } from './styles';

interface CameraFormData {
  alias: string;
  url: string
}

const CreateLink = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSubmit = useCallback(
    async (data: CameraFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          alias: Yup.string().required(),
          url: Yup.string().required()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/links', data);

        history.push('/links');

        addToast({
          type: 'success',
          title: 'Link Cadastrado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar Link',
          description: 'Ocorreu um erro ao cadastrar link, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, checked],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Criar link</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <div>
            <Input name="alias" icon={MdSearch} label="Link alias" placeholder="Nome para o link" />
            <Input name="url" icon={MdComputer} label="Link url" placeholder="Url ex: https://google.com..." />
            <Button type="submit" loading={loading}>Salvar</Button>
          </div>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default CreateLink;
