import React, { useCallback, useEffect, useState } from 'react';
import { Cron } from 'react-js-cron';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import "antd/dist/antd.css";
import 'react-js-cron/dist/styles.css';
import Button from '../../components/Button';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import { Content } from './styles';

interface Data {
  id: string | undefined | null;
  code: number | undefined | null;
  cron: string;
  job: { name: string, id: number };
}

interface IVideo {
  id: number;
  cron: string;
  job: { name: string, id: number };
}

function createData(
  id: string | undefined | null,
  code: number | undefined | null,
  cron: string,
  job: { name: string, id: number },
): Data {
  return { id, code, cron, job };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'cron', numeric: false, disablePadding: false, label: 'Cron' },
];

export const CronList = () => {
  const [rows, setRows] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  const location = useLocation<{ job: { name: string, id: number } }>();

  const { job } = location.state;



  useEffect(() => {
    getData();
  }, []);



  const getData = useCallback(async () => {
    const { data } = await api.get(`/jobs/${job.id}/cron`);

    setRows(data.map((item: any) => createData(String(item.id), item.id, item.cron, item.job)));

    setLoading(false);
  }, [rows])


  const handleDelete = useCallback(async (id: number) => {
    await api.delete(`/jobs/cron/${id}`);

    addToast({
      type: 'success',
      title: 'Cron removido com sucesso',
    });


    getData();
  }, []);

  const handleSubmit = useCallback(async (cron: string, id: number) => {
    console.log(cron, id)
    await api.put(`/jobs/cron/update/save`, {
      cron,
      job_id: job.id,
      id
    });

    addToast({
      type: 'success',
      title: 'Cron criado com sucesso',
    });

    getData();

  }, []);




  return (
    <>
      <StandardContainer>
        <Header />
        <Content style={{ display: "flex", }}>
          <h1 style={{ color: "white" }}>Crons do job: {job.name}</h1>

          {rows.map((cron: any, index: number) => <div style={{ marginBottom: "3%" }}>
            <span style={{ fontSize: 20 }}>{cron.cron}</span>
            <Cron
              value={cron.cron}
              setValue={(value: string) => {
                const oldCron = rows.find((item: any) => item.code === cron.code);
                if (oldCron) {
                  oldCron.cron = value;
                  setRows([...rows]);
                }
              }} />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignContent: "space-between" }}>

              <Button onClick={() => { handleSubmit(cron.cron, cron.id) }} loading={loading} >Salvar</Button>

              <Button style={{ marginLeft: 10 }} onClick={() => { handleDelete(cron.code) }} loading={loading} >Deletar</Button>


              {rows.length - 1 == index && (
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    const newCron = createData(null, null, "*/2 * * * *", job);
                    setRows([...rows, newCron]);
                  }} loading={loading} >Adicionar</Button>)}

            </div>



          </div>)}

          {rows.length == 0 && (
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                const newCron = createData(null, null, "*/2 * * * *", job);
                setRows([...rows, newCron]);
              }} loading={loading} >Adicionar</Button>)}



        </Content>
      </StandardContainer>
    </>
  )
}

