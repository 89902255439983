import { useReducer } from 'react';
import { useAuth } from '../../../hooks/auth';
import { PermissionsActionsEnum } from '../../../utils/PermissionsActionsEnum';

const usePermissions = () => {
  const initialState = {
    permissions: [],
    selectedUser: undefined,
  };

  const { permissions: allPermissions } = useAuth();

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case PermissionsActionsEnum.LOAD_PERMISSIONS: {
        const user_permissions = action.payload.user_permissions;

        const permissions = allPermissions.map((permission) => {
          const userPermission = user_permissions.find((user_permission: any) => user_permission.permission_id === permission.id);

          if (userPermission) {
            return {
              ...permission,
              checked: true,
            };
          }

          return {
            ...permission,
            checked: false,
          }
        });

        return { permissions };
      }
      case PermissionsActionsEnum.CHANGE_PERMISSIONS: {
        const selectedPermissionId = action.payload.permission_id;

        const permissions = state.permissions.map((permission: any) => {
          if (permission.id === selectedPermissionId) {
            return {
              ...permission,
              checked: !permission.checked,
            }
          }

          return {
            ...permission,
          }
        });

        return { permissions };
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

export default usePermissions;
