import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdLiveTv } from 'react-icons/md';
import { FiYoutube } from 'react-icons/fi';
import { BiRadio } from 'react-icons/bi';
import { MdOndemandVideo } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import { StatusEnum } from '../../../utils/StatusEnum';
import { PriorityEnum } from '../../../utils/PriorityEnum';
import { MediaTypeEnum } from '../../../utils/MediaTypeEnum';
import Checkbox from '../../../components/Checkbox';

interface MultimediaFormData {
  name: string;
  android_url: string;
  android_priority: string;
  ios_url: string;
  ios_priority: string;
  youtube_url: string;
  youtube_priority: string;
  media_type: string;
  status: string;
  radio_url: string;
  autoplay: string;
}

const statusOptions = [
  { value: StatusEnum.INATIVO, label: 'Inativo' },
  { value: StatusEnum.ATIVO, label: 'Ativo' },
];

const priorityOptions = [
  { value: PriorityEnum.ALTA, label: 'Alta' },
  { value: PriorityEnum.MEDIA, label: 'Média' },
  { value: PriorityEnum.BAIXA, label: 'Baixa' },
];

const mediaTypeOptions = [
  { value: MediaTypeEnum.RADIO.toString(), label: 'Rádio' },
  { value: MediaTypeEnum.TV.toString(), label: 'TV' },
  { value: MediaTypeEnum.RADIO_TV.toString(), label: 'Rádio e TV' },
];

const CreateMultimedia = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAutoPlay, setCheckedAutoPlay] = useState(false);
  const [enableVideo, setEnableVideo] = useState(true);

  const handleSubmit = useCallback(
    async (data: MultimediaFormData) => {
      setLoading(true);

      if (checkedAutoPlay) {
        data.autoplay = 'Y';
      } else {
        data.autoplay = 'N';
      }

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da câmera é obrigatório'),
          android_url: Yup.string(),
          android_priority: Yup.string().when('android_url', {
            is: val => !!val.length,
            then: Yup.string().required('Prioridade para m3u8 é obrigatória'),
            otherwise: Yup.string(),
          }),
          ios_url: Yup.string(),
          ios_priority: Yup.string().when('ios_url', {
            is: val => !!val.length,
            then: Yup.string().required('Prioridade para JMV é obrigatória'),
            otherwise: Yup.string(),
          }),
          youtube_url: Yup.string(),
          youtube_priority: Yup.string().when('youtube_url', {
            is: val => !!val.length,
            then: Yup.string().required(
              'Prioridade para Youtube é obrigatória',
            ),
            otherwise: Yup.string(),
          }),
          media_type: Yup.string().required('Tipo de mídia é obrigatório'),
          status: Yup.string().required('Status da transmissão é obrigatório'),
          radio_url: Yup.string(),
          autoplay: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const status = data.status === 'true' ? true : false;

        const {
          name,
          android_url,
          android_priority,
          ios_url,
          ios_priority,
          youtube_url,
          youtube_priority,
          media_type,
          radio_url,
          autoplay,
        } = data;

        let mediaType;

        switch (media_type) {
          case MediaTypeEnum.RADIO.toString():
            mediaType = 1;
            break;
          case MediaTypeEnum.TV.toString():
            mediaType = 2;
            break;
          case MediaTypeEnum.RADIO_TV.toString():
            mediaType = 3;
            break;
          default:
            mediaType = null;
            break;
        }

        const response = await api.post('/stream_multimedia', {
          name,
          android_url,
          android_priority: android_url ? Number(android_priority) : null,
          ios_url,
          ios_priority: ios_url ? Number(ios_priority) : null,
          youtube_url,
          youtube_priority: youtube_url ? Number(youtube_priority) : null,
          media_type: mediaType,
          active: status,
          is_default: checked,
          enable_video: enableVideo,
          radio_url,
          autoplay,
        });

        history.push('/multimedia/upload', { id: response.data.id });

        addToast({
          type: 'success',
          title: 'Transmissão ao vivo cadastrada!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar transmissão ao vivo',
          description:
            'Ocorreu um erro ao cadastrar transmissão ao vivo, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, checked, enableVideo, checkedAutoPlay],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Criar transmissão ao vivo</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Select
              name="status"
              options={statusOptions}
              label="Selecione o status da transmissão"
              style={{ marginBottom: '7px' }}
            />
            <Input
              name="name"
              icon={MdLiveTv}
              label="Nome da transmissão"
              placeholder="Nome da transmissão"
            />

            <Select
              name="android_priority"
              options={priorityOptions}
              label="Selecione a prioridade para o m3u8"
              style={{ marginBottom: '7px' }}
            />
            <Input
              name="android_url"
              icon={MdOndemandVideo}
              label="Link para m3u8"
              placeholder="Link para m3u8"
            />

            <Select
              name="ios_priority"
              options={priorityOptions}
              label="Selecione a prioridade para o JMV"
              style={{ marginBottom: '7px' }}
            />
            <Input
              name="ios_url"
              icon={MdOndemandVideo}
              label="Link para JMV"
              placeholder="Link para JMV"
            />
          </div>
          <div>
            <Select
              name="youtube_priority"
              options={priorityOptions}
              label="Selecione a prioridade para o Youtube"
              style={{ marginBottom: '7px' }}
            />
            <Input
              name="youtube_url"
              icon={FiYoutube}
              label="Link para youtube"
              placeholder="Link para youtube"
            />
            <Input
              name="radio_url"
              icon={BiRadio}
              label="Link para rádio"
              placeholder="Link para rádio"
            />

            <Select
              name="media_type"
              options={mediaTypeOptions}
              label="Selecione o tipo de mídia"
              style={{ marginBottom: '7px' }}
            />

            <Checkbox
              name="checkbox"
              checkboxName="Transmissão padrão"
              checked={checked}
              onChecked={() => setChecked(!checked)}
            />
            <Checkbox
              name="checkbox_enable_video"
              checkboxName="Vídeo habilitado"
              checked={enableVideo}
              onChecked={() => setEnableVideo(!enableVideo)}
            />
            <Checkbox
              name="checkbox_auto_play"
              checkboxName="AutoPlay habilitado"
              checked={checkedAutoPlay}
              // onChecked={() => {
              //   const newCheckedAutoPlay = !checkedAutoPlay;
              //   console.log(
              //     'Novo estado de checkedAutoPlay:',
              //     newCheckedAutoPlay,
              //   );
              //   setCheckedAutoPlay(newCheckedAutoPlay);
              // }}
              onChecked={() => setCheckedAutoPlay(!checkedAutoPlay)}
            />
          </div>
          <Button type="submit" loading={loading}>
            Salvar
          </Button>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default CreateMultimedia;
