import React, { useCallback, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import DatetimeInput from '../../../components/DatetimeInput';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';
import Button from '../../../components/Button';
import api from '../../../services/api';

interface LocationState {
  id: string;
};

interface ScheduleFormData {
  dateStart: string;
  dateEnd: string;
}

const ScheduleBanner = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  const { addToast } = useToast();

  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/banners/${state.id}`).then(response => {
      if (!response.data.schedule_publication_start &&
          !response.data.schedule_publication_end
      ) {
        return;
      }

      const startDate = format(new Date(response.data.schedule_publication_start), "yyyy-MM-dd'T'HH:mm");
      const endDate = format(new Date(response.data.schedule_publication_end), "yyyy-MM-dd'T'HH:mm");

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    });
  }, [state.id]);

  const handleSubmit = useCallback(
    async (data: ScheduleFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        data.dateStart = selectedStartDate;
        data.dateEnd = selectedEndDate;

        const schema = Yup.object().shape({
          dateStart: Yup.string().required('Data de início é obrigatória'),
          dateEnd: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put(`/banners/${state.id}/schedule`, {
          schedule_publication_start: new Date(data.dateStart).toISOString(),
          schedule_publication_end: new Date(data.dateEnd).toISOString(),
          active: true,
        });

        history.push('/banner', { id: response.data.id });

        addToast({
          type: 'success',
          title: 'Publicação agendada com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao agendar publicação da banner',
          description: 'Ocorreu um erro ao agendar publicação da banner, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, selectedStartDate, selectedEndDate, state.id, history],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h1>Agendar publicação de banner</h1>
          <DatetimeInput
            id="datetime-start"
            label="Data e hora iniciais"
            name="dateStart"
            value={selectedStartDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedStartDate(e.target.value)}
          />
          <DatetimeInput
            id="datetime-end"
            label="Data e hora finais"
            name="dateEnd"
            value={selectedEndDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedEndDate(e.target.value)}
          />
          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default ScheduleBanner;
