import React from 'react';
import { FiDatabase } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import usePermission from '../../hooks/usePermission';
import { PermissionsEnum } from '../../utils/PermissionsEnum';
import Submenu from '../Submenu';

import {
  Container,
  Content,
  StyledLink,
  Logo,
  MyProfile,
  MyFiPieChart,
  MyBanner,
  MyPackage,
  MyCamera,
  MyMultimedia,
  MyNews,
  MyPen,
  MyAuthor,
  MySearch,
  MyNotification,
  Watermark,
  UsersIcon,
  PermissionsIcon,
  MyDailyMessaage,
  MyLink,
  InfoIcon,
  PlayIcon,
  MyReport,
  ClockIcon,
  MyChat,
  GameIcon
} from './styles';

interface ToggleProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  toggle: boolean;
}

const Sidebar: React.FC<ToggleProps> = ({ toggle }) => {
  const { user } = useAuth();

  return (
    <Container>
      <Content toggle={toggle}>
        <Logo>
          <Link to="/profile">
          <img
            className="Logo"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlp34sSQ-K-4fB-unHqgYvktJ1ATemSOUBvAnAn57Ax0oA0Qzba-LsxdwPoGnmbCvnlrI&usqp=CAU"
            alt="Avatar"
          />
          <h1>
            <strong>{user.name}</strong>
          </h1>
          </Link>
        </Logo>
        <StyledLink to="/" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <MyFiPieChart size={18} />
          Dashboard
        </StyledLink>

        <StyledLink to="/cache/list" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <MyReport size={18} />
          Cache
        </StyledLink>

        <Submenu />

        <StyledLink to="/camera" hasPermission={usePermission(PermissionsEnum.CAMERA)}>
          <MyCamera />
          Câmera
        </StyledLink>

        <StyledLink to="/multimedia" hasPermission={usePermission(PermissionsEnum.STREAM_MULTIMEDIA)}>
          <MyMultimedia />
          Transmissões ao vivo
        </StyledLink>

        {/* <StyledLink to="/watermark" hasPermission={usePermission(PermissionsEnum.WATERMARK)}>
          <Watermark size={18} />
          Marca d'Água
        </StyledLink> */}

        <StyledLink to="/banner" hasPermission={usePermission(PermissionsEnum.BANNER)}>
          <MyBanner />
          Banner
        </StyledLink>

        <StyledLink to="/news" hasPermission={usePermission(PermissionsEnum.NEWS)}>
          <MyNews />
          Notícias
        </StyledLink>

        <StyledLink to="/daily/messages" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <MyDailyMessaage />
          Mensagem do Dia
        </StyledLink>

        <StyledLink to="/links" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <MyLink />
          Links
        </StyledLink>

        <StyledLink to="/chats" hasPermission={usePermission(PermissionsEnum.CHATBOT_DEVOTEE_FAMILY)}>
          <MyChat />
          Chat
        </StyledLink>

        <StyledLink to="/video" hasPermission={usePermission(PermissionsEnum.APARECIDA_PLAY)}>
          <PlayIcon />
          Aparecida Play - videos
        </StyledLink>

        <StyledLink to="/channel" hasPermission={usePermission(PermissionsEnum.APARECIDA_PLAY)}>
          <PlayIcon />
          Aparecida Play - canais
        </StyledLink>

        <StyledLink to="/podcast" hasPermission={usePermission(PermissionsEnum.APARECIDA_PLAY)}>
          <PlayIcon />
          Aparecida Play - podcast
        </StyledLink>

        <StyledLink to="/video/search" hasPermission={usePermission(PermissionsEnum.APARECIDA_PLAY)}>
          <PlayIcon />
          Aparecida Play - pesquisas
        </StyledLink>

        <StyledLink to="/editorial" hasPermission={usePermission(PermissionsEnum.EDITORIALS)}>
          <MyPen />
          Editoria
        </StyledLink>

        <StyledLink to="/author" hasPermission={usePermission(PermissionsEnum.AUTHOR)}>
          <MyAuthor />
          Autor
        </StyledLink>

        <StyledLink to="/version" hasPermission={usePermission(PermissionsEnum.VERSION)}>
          <MyPackage />
          Versão
        </StyledLink>

        <StyledLink to="/bible" hasPermission={usePermission(PermissionsEnum.BIBLE)}>
          <MySearch />
          Buscas na Bíblia
        </StyledLink>

        <StyledLink to="/notifications" hasPermission={usePermission(PermissionsEnum.NOTIFICATION)}>
          <MyNotification />
          Notificações
        </StyledLink>

        <StyledLink to="/places" hasPermission={usePermission(PermissionsEnum.PLACE)}>
          <InfoIcon />
          Pontos do Mapa
        </StyledLink>

        <StyledLink to="/users" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <UsersIcon />
          Usuários
        </StyledLink>

        <StyledLink to="/permissions" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <PermissionsIcon />
          Permissões
        </StyledLink>

        <StyledLink to="/jobs" hasPermission={usePermission(PermissionsEnum.ADMIN)}>
          <ClockIcon />
          Cron jobs
        </StyledLink>

        <StyledLink to="/game" hasPermission={usePermission(PermissionsEnum.GAME)}>
          <GameIcon />
          Game
        </StyledLink>

        <StyledLink to="/bible-game" hasPermission={usePermission(PermissionsEnum.BIBLEGAME)}>
          <GameIcon />
          Game Bíblia
        </StyledLink>

        <StyledLink to="/tasksgame" hasPermission={usePermission(PermissionsEnum.TASKSGAME)}>
          <GameIcon />
          Tasks Game
        </StyledLink>

        <StyledLink to="/profile" hasPermission>
          <MyProfile />
          Perfil
        </StyledLink>
      </Content>
    </Container>
  );
};

export default Sidebar;
