import { shade } from 'polished';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;
`;

export const ReorderButton = styled.button`
  display: flex;
  background: #008FC3;
  width: 30px;
  height: 100%;
  padding: 10px 0;
  margin: 0 5px;
  border-radius: 7px;
  border: 0;
  color: #312e38;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#008FC3')};
  }
`;

export const ChartContainer = styled.div`
  width: 70%;
  min-height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
`;

export const NoDataMessage = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #383838;
  font-size: 16px;
`;


