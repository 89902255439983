import React, { useCallback, useRef, useEffect, useState } from 'react';
import { BiFont } from 'react-icons/bi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import ColorPickerInput from '../../../components/ColorPickerInput';

interface EditorialFormData {
  name: string;
  color: string;
}

interface LocationState {
  id: string;
};

const EditEditorial = () => {
  const [editorial, setEditorial] = useState<EditorialFormData>();
  const [loading, setLoading] = useState(false);
  const [colorPicker, setColorPicker] = useState('');

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/editorials/${state.id}`).then(response => {
      setEditorial(response.data);
      setColorPicker(response.data.color);
    });

  }, [state.id]);

  const handleSubmit = useCallback(
    async (data: EditorialFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        data.color = colorPicker;

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da editoria é obrigatório'),
          color: Yup.string().required('Cor da editoria é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          color,
        } = data;

        await api.put(`/editorials/${state.id}`, {
          name,
          color,
        });

        history.push('/editorial');

        addToast({
          type: 'success',
          title: 'Editoria atualizada!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar editoria',
          description: 'Ocorreu um erro ao atualizar editoria, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id, colorPicker],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          initialData={{
            name: editorial?.name,
          }}
          onSubmit={handleSubmit}
        >
          <h1>Editar editoria</h1>
          <Input name="name" icon={BiFont} label="Nome da editoria" placeholder="Nome da editoria" />
          <ColorPickerInput id="color-input" defaultColor={colorPicker} onChange={(color) => setColorPicker(color)} />

          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditEditorial;
