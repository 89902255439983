import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink } from 'react-icons/fi';
import { GrSoundcloud } from 'react-icons/gr';

import Button from '../../../components/Button';
import * as tinymce from '@tinymce/tinymce-react';
import { Editor } from '@tinymce/tinymce-react';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';

import { Content, EditorTitle } from './styles';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { NewsTypeEnum } from '../../../utils/NewsTypeEnum';
import { useToast } from '../../../hooks/toast';
import Checkbox from '../../../components/Checkbox';
import { act } from '@testing-library/react';

interface TaskGameFormData {
  description: string;
  screen: string;
  active: string;
}

const optionsScreens = [
  { value: 'AparecidaPlayHome', label: 'Home do Aparecida Play' },
  { value: 'NewsDetails', label: 'Detalhes da Notícia' },
  { value: 'LiveSanctuaryHome', label: 'Encontre Aparecida ao Vivo' },
  // {
  //   value: 'AparecidaPlayPlayer',
  //   label: 'Área AparecidaPlay', -- TIREI, PQ REMETE PRO MESMO LUGAR DO ITEM ACIMA. NO APP, NO CARROSSEL, SÓ TEM AparecidaPlayHome

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Encontre Aparecida Play',
  // }, --  // Não há como ter dois values iguais no select (value acima igual a alguns de baixo)

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Devotos Mirins no Aparecida Play',
  // }, -- Não há como ter dois values iguais no select (value acima igual a este e os de baixo)

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Santuário Nacional no Aparecida Play',
  // },
  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Canal a12 no Aparecida Play',
  // },
  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Rádio Aparecida no Aparecida Play',
  // },

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Canal TV Aparecida no Aparecida Play',
  // },

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Rádio Pop no Aparecida Play',
  // },

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Jovens de Maria no Aparecida Play',
  // },

  { value: 'AparecidaTv', label: 'TV Aparecida' },
  {
    value: 'DailyMeditation',
    label: 'Meditação Diária',
  },
  {
    value: 'OurGodWithUsStackScreen',
    label: 'Deus Conosco',
  },
  { value: 'SaintOfDay', label: 'Santo do Dia' },
  { value: 'DayToDay', label: 'Dia a Dia' },
  { value: 'OnlineBible', label: 'Bíblia online' },
  { value: 'SearchBible', label: 'Busca na Bíblia online' },
  // {
  //   value: 'ChoiceBibleBookChapterVerse',
  //   label:
  //     'Página para escolher livro, capítulo e versículo no Novo Testamento',
  // }, // retirei, para que fiquem ativas apenas as duas de baixo, de forma separada : ChooseOldTestament/ChooseNewTestament
  {
    value: 'ChooseNewTestament',
    label: 'Encontre o Novo Testamento',
  },
  {
    value: 'ChooseOldTestament',
    label: 'Encontre o Antigo Testamento',
  },
  { value: 'RadioAparecidaHome', label: 'Home Rádio Aparecida' },
  {
    value: 'RadioAparecida',
    label: 'Vídeos da Rádio Aparecida',
  },
  { value: 'RadioPopHome', label: 'Home da Rádio Pop' },
  {
    value: 'RadioPop',
    label: 'Vídeos da Rádio Pop',
  },
  { value: 'DevoteesFamilyHome', label: 'Família dos Devotos' },
  { value: 'DevoteeArea', label: 'Área do devoto' },
  { value: 'NewRegister', label: 'Novo Cadastro de devoto' },
  { value: 'NewRegisterChat', label: 'Chat novo cadastro de devoto' },
  { value: 'Magazine', label: 'Revista de Aparecida' },
  { value: 'Donation', label: 'Doação por meio da família dos Devotos' },
  { value: 'NewsHome', label: ' Página de Notícias do a12' },
  // { value: 'RegisterScreen', label: 'Página cadastro Game' }, - TIREI, PQ ENQUANTO A PESSOA ESTÁ
  // JOGANDO, NÃO APARECE O CADASTRO DO GAME, LOGO, NÃO HÁ COMO HAVER A PERGUNTA POR N TER COMO ACERTAR
  // { value: 'RankingScreen', label: 'Página Ranking Game' }, - TIREI, PQ ENQUANTO A PESSOA ESTÁ
  // JOGANDO, NÃO APARECE O RANKING DO GAME, LOGO, NÃO HÁ COMO HAVER A PERGUNTA POR N TER COMO ACERTAR
  // {
  //   value: 'ForgetPassword',
  //   label: 'Área "esqueci minha senha" do devoto que possui login',
  // }, -- AO CLICAR, REMETE PRA UMA PÁGINA FORA DO APP (a12.com/entrar?redirect....), acredito q por conta disso, não seja rastreado no app, por isso tirei
  { value: 'About', label: 'Página sobre menu lateral, com dados de contato' },
];

const CreateTaskGame = () => {
  const [loading, setLoading] = useState(false);
  const [checkedactive, setCheckedActive] = useState(false);
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const editorRef = useRef<any>(null);

  const handleSubmit = useCallback(
    async (data: TaskGameFormData) => {
      try {
        formRef.current?.setErrors({});
        if (checkedactive) {
          data.active = 'Y';
        } else {
          data.active = 'N';
        }

        setLoading(true);

        if (
          data.description !== '' &&
          data.screen !== '' &&
          data.active !== ''
        ) {
          await api.post('/tasksgame/task/create', {
            ...data,
          });
          history.push('/tasksgame');

          addToast({
            type: 'success',
            title: 'Task cadastrada!',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar task',
            description: 'Preencha todos os dados.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar task',
          description: 'Ocorreu um erro ao cadastrar a task, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, checkedactive],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Criar task</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input
              name="description"
              icon={BiFont}
              label="Descrição"
              placeholder="Descrição da task"
            />
            <div style={{ marginTop: '15px' }}>
              <Checkbox
                name="checkedActive"
                checkboxName="Ativa"
                checked={checkedactive}
                onChecked={() => setCheckedActive(!checkedactive)}
              />
            </div>

            <Select
              name="screen"
              options={optionsScreens}
              label="Selecione a tela"
              style={{ marginBottom: '10px' }}
            />
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </div>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default CreateTaskGame;
