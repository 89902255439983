import React, { useEffect, useState } from 'react';

import {
  Col,
  Content, Row, TitleGraph,
} from './styles';

import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import api from '../../../services/api';
import DatetimeInput from '../../../components/DatetimeInput';
import Button from '../../../components/Button';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

interface IStats{
  total: number;
  content_type: string;
}

const ReportContentType: React.FC = () => {

  const [data, setData] = useState<IStats[]>([])
  const [loading, setLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm')); //7 dias atrás
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm')); //até a data/hora atual
  const [maxValue, setMaxValue] = useState(10000);
  
  useEffect(() => {
    reloadGraphs()
  }, [])

  const reloadGraphs = () => {
    loadStats()
  }

  const formatDateTime = (dateTime: string) => {
    return dateTime.replace("T"," ")
  }

  const loadStats = () => {
    setLoading(true)
    api.get(`/reports/content-type-screen?start=${formatDateTime(selectedStartDate)}&end=${formatDateTime(selectedEndDate)}`).then(response => {
      setLoading(false)
      let data:IStats[] = response.data
      setMaxValue(Math.floor(Math.max(...data.map((item) => Number(item.total)))))      
      setData(data)
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    });
  }

  
  const exportStats = () => {
    setLoading(true)
    api.get(`/reports/content-type-screen/export?start=${formatDateTime(selectedStartDate)}&end=${formatDateTime(selectedEndDate)}`)
      .then(response => {
        setLoading(true)

        const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `contentType-${selectedStartDate}-${selectedEndDate}.xlsx`);
        document.body.appendChild(link);
        link.click();


      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false));
  }

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Row>
          <DatetimeInput
            id="datetime-start"
            label="Data e hora iniciais"
            name="dateStart"
            value={selectedStartDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedStartDate(e.target.value)}
          />
          <DatetimeInput
            id="datetime-end"
            label="Data e hora finais"
            name="dateEnd"
            value={selectedEndDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedEndDate(e.target.value)}
          />
          <Button type="button" onClick={reloadGraphs} loading={loading} style={{maxWidth: 150}}>Filtrar</Button>
          <Button type="button" onClick={exportStats} loading={loading} style={{marginLeft:10, maxWidth: 150 }}>Exporta</Button>
        </Row>
        <Row>
          <Col style={{width: "100%"}}>
            <TitleGraph>Pessoas por tipo de conteúdo</TitleGraph>
            { loading && <CircularProgress size={32} /> }
            { !loading && 
              <>
                { data.length > 0 &&
                  <ResponsiveContainer width="100%" height="100%" minHeight={400}>
                    <ComposedChart
                      width={400}
                      height={400}
                      data={data}
                      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}                
                    >
                      <CartesianGrid strokeDasharray="1 1" />
                      <XAxis dataKey={"content_type"} height={50} />
                      <YAxis tickCount={100} domain={[0, maxValue]} />
                      <Tooltip                       
                        labelStyle={{ color: "#000" }}                        
                        formatter={(value: number) => {
                          const numberFormat = new Intl.NumberFormat('pt')
                          return numberFormat.format(value)
                        }} />
                      <Legend />
                      <Bar name='Pessoas' dataKey="total" fill="#8884d8" />                      
                    </ComposedChart>
                  </ResponsiveContainer>
                }
                { data.length === 0 && 
                  <>Não há dados no período</>
                }
              </>
            }
          </Col>
        </Row>        
      </Content>
    </StandardContainer>
  );
};

export default ReportContentType;
