import React from 'react';
import {
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxType,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';

type CheckboxProps = {
  name: string;
  checkboxName: string;
  checked: boolean;
  defaultValue?: string;
  onChecked: () => void;
};

const CustomCheckbox = withStyles({
  root: {
    color: '#008FC3',
    '&$checked': {
      color: '#00698f',
    },
  },
  checked: {},
})((props: MaterialCheckboxType) => <MaterialCheckbox color="default" {...props} />);

const Checkbox: React.FC<CheckboxProps> = ({ name, checkboxName, checked, defaultValue, onChecked }) => {
  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          name={name}
          checked={checked}
          defaultValue={defaultValue}
          onChange={onChecked}
        />
      }
      label={checkboxName}
    />
  );
};

export default Checkbox;
