import { shade } from 'polished';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;
`;

export const ContainerImportTasks = styled.div`
  text-align: left;
  margin: 18px 0;
`

export const LoadingOverlayContainer = styled.div`
  position: relative; 
`;

export const ImportButtonCancel = styled.div`
  display: flex;
  background: #ec5353;
  width: auto;
  height: 100%;
  padding: 10px 10px;
  margin: 0 5px;
  border-radius: 7px;
  border: 0;
  color: #FFF;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${shade(0.2, '#ff0000')};
  }
`;
