import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  question: string;
  alternative1: string;
  alternativeCorrect1: string;
  alternative2: string;
  alternativeCorrect2: string;
  alternative3: string;
  alternativeCorrect3: string;
  alternative4: string;
  alternativeCorrect4: string;
  difficultyLevel: string;
  type: string;
}

interface IQuizGameBible {
  id: number;
  question: string;
  alternative1: string;
  alternativeCorrect1: string;
  alternative2: string;
  alternativeCorrect2: string;
  alternative3: string;
  alternativeCorrect3: string;
  alternative4: string;
  alternativeCorrect4: string;
  difficultyLevel: string;
  type: string;
}

function createData(
  id: string,
  code: number,
  question: string,
  alternative1: string,
  alternativeCorrect1: string,
  alternative2: string,
  alternativeCorrect2: string,
  alternative3: string,
  alternativeCorrect3: string,
  alternative4: string,
  alternativeCorrect4: string,
  difficultyLevel: string,
  type: string,
): Data {
  return {
    id,
    code,
    question,
    alternative1,
    alternativeCorrect1,
    alternative2,
    alternativeCorrect2,
    alternative3,
    alternativeCorrect3,
    alternative4,
    alternativeCorrect4,
    difficultyLevel,
    type,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  {
    id: 'question',
    numeric: false,
    disablePadding: false,
    label: 'Questão',
  },
  {
    id: 'alternative1',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 1',
  },
  {
    id: 'alternativeCorrect1',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 1 Correta',
  },
  {
    id: 'alternative2',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 2',
  },
  {
    id: 'alternativeCorrect2',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 2 Correta',
  },
  {
    id: 'alternative3',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 3',
  },
  {
    id: 'alternativeCorrect3',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 3 Correta',
  },
  {
    id: 'alternative4',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 4',
  },
  {
    id: 'alternativeCorrect4',
    numeric: false,
    disablePadding: false,
    label: 'Alternativa 4 Correta',
  },
  {
    id: 'difficultyLevel',
    numeric: false,
    disablePadding: false,
    label: 'Dificuldade',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Tipo' },
];

const GameBible = () => {
  const [rows, setRows] = useState<IQuizGameBible[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/game-bible/', { params: { limit: 10000 } }).then(response => {
      setRows(response.data);
      console.log(response.data, 'dados');
      setLoading(false);
    });
  }, [deleted]);

  const onDelete = useCallback(
    id => {
      api.delete(`/game-bible/${id}`).then(response => {
        setDeleted(!deleted);
        addToast({
          type: 'success',
          title: 'Pergunta deletada com sucesso!',
        });
      });
    },
    [setDeleted, deleted, addToast],
  );

  const newsRows = useMemo(() => {
    return rows.map(row =>
      createData(
        row.id.toString(),
        row.id,
        row.question,
        row.alternative1,
        row.alternativeCorrect1,
        row.alternative2,
        row.alternativeCorrect2,
        row.alternative3,
        row.alternativeCorrect3,
        row.alternative4,
        row.alternativeCorrect4,
        row.difficultyLevel,
        row.type,
      ),
    );
  }, [rows]);

  const onCreate = useCallback(() => {
    history.push('/bible-game/question/create');
  }, [history]);

  const onEdit = useCallback(
    row => {
      history.push('/bible-game/:id', { id: row.code });
    },
    [history],
  );

  const tableBodyCells = useCallback(
    (isItemSelected, labelId, row) => (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.question}</TableCell>
        <TableCell align="left">{row.alternative1}</TableCell>
        <TableCell align="left">{row.alternativeCorrect1}</TableCell>
        <TableCell align="left">{row.alternative2}</TableCell>
        <TableCell align="left">{row.alternativeCorrect2}</TableCell>
        <TableCell align="left">{row.alternative3}</TableCell>
        <TableCell align="left">{row.alternativeCorrect3}</TableCell>
        <TableCell align="left">{row.alternative4}</TableCell>
        <TableCell align="left">{row.alternativeCorrect4}</TableCell>
        <TableCell align="left">{row.difficultyLevel}</TableCell>
        <TableCell align="left">{row.type}</TableCell>
      </>
    ),
    [],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title="Game Bíblia"
          headCells={headCells}
          rows={newsRows}
          orderByDefaultColumn="updated_at"
          defineOrder="desc"
          onCreate={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  );
};

export default GameBible;
