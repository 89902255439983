import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import BpmnModeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-font/dist/css/bpmn-embedded.css";
import initDiagram from "../../services/bpmn-js/init/initDiagram";
import customControlsModule from "../../services/bpmn-js/custom";
import FormBpmn from "./FormBpmn";

export type TBpmnDefinition = 'bpmn:Task' | 'bpmn:ExclusiveGateway' | 'bpmn:EndEvent' | 'bpmn:StartEvent' | 'bpmn:Participant' | 'bpmn:SequenceFlow';

export type TOptionsGateway = {
  label: string
  value: string
}

interface AddBpmnProps {
    
}
 
const AddBpmn: FunctionComponent<AddBpmnProps> = () => {

  const [showModal, setshowModal] = useState(false);
  const [selectedDefintion, setSelectedDefintion] = useState<TBpmnDefinition | null | undefined>(null);
  const [optionsGateway, setOptionsGateway] = useState<TOptionsGateway[]>([]);

    /*async function openDiagram(bpmnXML: string) {

        // import diagram
        try {

          await modeler.importXML(bpmnXML);

          // access modeler components
          var canvas: any = modeler.get('canvas');
          var overlays: any = modeler.get('overlays');


          // zoom to fit full viewport
          canvas.zoom('fit-viewport');

          // attach an overlay to a node
          overlays.add('SCAN_OK', 'note', {
            position: {
              bottom: 0,
              right: 0
            },
            html: '<div class="diagram-note">Mixed up the labels?</div>'
          });

          // add marker
          canvas.addMarker('SCAN_OK', 'needs-discussion');
        } catch (err) {

          console.error('could not import BPMN 2.0 diagram', err);
        }
      }*/

  const deleteOptionById = (id: string) => {
    setOptionsGateway(oldValues => {
          return oldValues.filter(option => option.value !== id)
    })
  }

  const replaceOptionById = (id: string, newOption: TOptionsGateway) => {
    setOptionsGateway(oldValues => {
          let option =  oldValues.find(option => option.value == id)
          if(option){
            let i = oldValues.indexOf(option)
            oldValues[i] = newOption
          }
          return oldValues
          
    })
  }

  useEffect(() => {

    const modeler = new BpmnModeler({ 
        container: '#js-canvas',
        additionalModules: [customControlsModule]
    })

    if(modeler){

      // import XML
        modeler.importXML(initDiagram)
        .then(() => {
            const canvas: any = modeler.get("canvas");
            canvas.zoom("fit-viewport");
          })
          .catch((err) => console.log(err));

          // Show modal Event
          const eventBus: any = modeler.get("eventBus");
          eventBus.on("element.dblclick", function (event: any) {
            const { element } = event;
      
            // ignore root element
            if (element.parent) {
              //console.log(element)
              //console.log("You have clicked the element with id " + element.id + ' type: ' + element.type);
              
              if(element.type == 'bpmn:Participant') return

              setSelectedDefintion(element.type)
              setshowModal(true)
            }
          });

          // GET OptionsGateway Event
          eventBus.on("shape.added", function (event: any) {
            const { element } = event;

            console.log('criou: ', event)
      
            // ignore root element
            if (element.parent) { 
              if(element.type == 'bpmn:Task'){
                
                if(!element.businessObject["name"]) element.businessObject["name"] = `Unnamed task`

                const optionGateway = {
                  label: element.businessObject["name"],
                  value: element.id,
                }

                setOptionsGateway((optionsGateway) => [...optionsGateway, optionGateway]);
              }
            }
          });

          eventBus.on('commandStack.changed', function(event: any){
            console.log('atualizou: ', event)
          })

          /// DELETE AND UPDATE
          eventBus.on('element.changed', function (event: any) {
            const { element } = event;

            console.log('changed: ', event)

            if (element.parent) { 
              if(element.type == 'bpmn:Task'){

                const updatedOptionGateway = {
                  label: element.businessObject["name"],
                  value: element.id,
                }

                // replace
                replaceOptionById(element.id, updatedOptionGateway)
              }
            }

            if (!event.gfx) {
              console.log('deletou ', element);

              // delete
              deleteOptionById(element.id)
            }
          });
    }
  }, []);

  useEffect(() => {
    console.log('optionsGateway: ', optionsGateway)

  }, [optionsGateway])


    return (
        <>
            <div 
              style={{ height: "100vh", width: '100vw', color: "#000" }} 
              id="js-canvas" 
            />
            <div id="propview" />
           <FormBpmn setShowModal={setshowModal} showModal={showModal} bpmnDefinition={selectedDefintion} optionsGateway={optionsGateway} />
        </>
    );
}
 
export default AddBpmn;