import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Col,
  Content, Row, TitleGraph
} from './styles';

import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Button from '../../../components/Button';
import DatetimeInput from '../../../components/DatetimeInput';
import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';
import api from '../../../services/api';
import Select from '../../../components/Select';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';


interface IStats {
  total: number;
  title: string;
}

const ReportAreasAccess: React.FC = () => {

  const [data, setData] = useState<IStats[]>([])
  const [loading, setLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(30, 'days').format("yyyy-MM-DDTHH:mm"));
  const [channel, setChannel] = useState(""); //7 dias atrás
  const [value, setValue] = useState(""); //7 dias atrás
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format("yyyy-MM-DDTHH:mm")); //até a data/hora atual
  const [maxValue, setMaxValue] = useState(10000);


  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    reloadGraphs()

  }, [])

  const reloadGraphs = () => {
    loadStats()
  }


  const loadStats = () => {
    setLoading(true)
    api.get(`/areas/report/data?start=${selectedStartDate}&end=${selectedEndDate}`).then(response => {
      setLoading(false)
      let data: IStats[] = response.data
      setMaxValue(Math.floor(Math.max(...data.map((item) => Number(item.total) / 60))))
      setData(data)

      setMaxValue(Math.max(...data.map(o => o.total)))
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    });
  }

  const exportStats = () => {
    setLoading(true)
    api.get(`/areas/report/data/export?start=${selectedStartDate}&end=${selectedEndDate}`)
      .then(response => {
        setLoading(true)

        const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `acessos-areas-${moment(selectedStartDate).format('DD-MMM-YYYY')}-${moment(selectedEndDate).format('DD-MMM-YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();


      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false));
  }


  const handleSelectUser = useCallback((data: NamedNodeMap) => {
    const [, id] = data[2].nodeValue!.split('channel');

    setChannel(id)

  }, []);


  return (
    <StandardContainer>
      <Header />
      <Content>
        <Row>
          <Form
            ref={formRef}
            onSubmit={() => { }}
          >
            <DatetimeInput
              id="datetime-start"
              label="Data e hora iniciais"
              name="dateStart"
              value={selectedStartDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedStartDate(e.target.value)}
            />
            <DatetimeInput
              id="datetime-end"
              label="Data e hora finais"
              name="dateEnd"
              value={selectedEndDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedEndDate(e.target.value)}
            />

          </Form>


          <Button type="button" onClick={reloadGraphs} loading={loading} style={{ maxWidth: 150 }}>Filtrar</Button>
          <Button type="button" onClick={exportStats} loading={loading} style={{ marginLeft: 10, maxWidth: 150 }}>Exporta</Button>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <TitleGraph>Número de acesso por área</TitleGraph>
            {loading && <CircularProgress size={32} />}
            {!loading &&
              <>
                {data.length > 0 &&
                  <ResponsiveContainer width="100%" height="100%" minHeight={400}>
                    <ComposedChart
                      width={600}
                      height={400}
                      data={data}
                      margin={{ top: 10, right: 10, left: 10, bottom: 5 }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey={"title"} height={50} />
                      <YAxis tickCount={10} domain={[0, maxValue]} />
                      <Tooltip
                        labelStyle={{ color: "#000" }}
                      />
                      <Legend />
                      <Bar name='Acessos' dataKey="total" fill="#8884d8" />
                    </ComposedChart>
                  </ResponsiveContainer>
                }
                {data.length === 0 &&
                  <>Não há dados no período</>
                }
              </>
            }
          </Col>
        </Row>
      </Content>
    </StandardContainer >
  );
};

export default ReportAreasAccess;
