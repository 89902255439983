import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink } from 'react-icons/fi';
import { GrSoundcloud } from 'react-icons/gr';

import Button from '../../../components/Button';
import * as tinymce from '@tinymce/tinymce-react';
import { Editor } from '@tinymce/tinymce-react';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';

import {
  Content,
  EditorTitle,
  QuestionContainer,
  AlternativeContainer,
} from './styles';
import Textarea from '../components/TextArea';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { NewsTypeEnum } from '../../../utils/NewsTypeEnum';
import { useToast } from '../../../hooks/toast';
import Checkbox from '../../../components/Checkbox';
import { act } from '@testing-library/react';

interface QuestionGameBibleFormData {
  question: string;
  alternative1: string;
  alternativeCorrect1: string;
  alternative2: string;
  alternativeCorrect2: string;
  alternative3: string;
  alternativeCorrect3: string;
  alternative4: string;
  alternativeCorrect4: string;
  difficultyLevel: string;
  type: string;
}

const optionsLevel = [
  { value: 'Facil', label: 'Fácil' },
  {
    value: 'Medio',
    label: 'Médio',
  },
  {
    value: 'Dificil',
    label: 'Difícil',
  },
];

const optionsChoice = [
  {
    value: 'UNICA_ESCOLHA',
    label: 'Única escolha',
  },
];

const CreateQuestionGameBible = () => {
  const [loading, setLoading] = useState(false);
  const [alternatives, setAlternatives] = useState(['', '', '', '']);
  const [correctIndex, setCorrectIndex] = useState<number | null>(null);
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const editorRef = useRef<any>(null);

  const handleCheckboxChange = (index: number) => {
    setCorrectIndex(index);
  };

  const handleAlternativeChange = (index: number, value: string) => {
    const newAlternatives = [...alternatives];
    newAlternatives[index] = value;
    setAlternatives(newAlternatives);
  };

  const handleSubmit = useCallback(
    async (data: QuestionGameBibleFormData) => {
      console.log(data);
      try {
        if (correctIndex === null) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione uma resposta correta.',
          });
          return;
        }
        if (data.question === '') {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Envie uma questão.',
          });
          return;
        }

        if (data.difficultyLevel === '') {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione um nível',
          });
          return;
        }
        if (data.type === '') {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Selecione única escolha',
          });
          return;
        }
        const verifyAlternatives = [
          data.alternative1,
          data.alternative2,
          data.alternative3,
          data.alternative4,
        ];

        for (let index: number = 0; index < verifyAlternatives.length; index++) {
          if (verifyAlternatives[index] === '') {
            addToast({
              type: 'error',
              title: 'Erro',
              description: 'Preencha todas as alternativas.',
            });
            return;
          }
        }

        const formData = {
          ...data,
          alternative1: alternatives[0],
          alternativeCorrect1: correctIndex === 0 ? 'S' : 'N',
          alternative2: alternatives[1],
          alternativeCorrect2: correctIndex === 1 ? 'S' : 'N',
          alternative3: alternatives[2],
          alternativeCorrect3: correctIndex === 2 ? 'S' : 'N',
          alternative4: alternatives[3],
          alternativeCorrect4: correctIndex === 3 ? 'S' : 'N',
        };
        // console.log(formData, 'formdATA');

        setLoading(true);

        await api.post('/game-bible/questions/create', formData);

        history.push('/bible-game');
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Questão criada com sucesso.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar a pergunta',
          description:
            'Ocorreu um erro ao cadastrar a pergunta, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, alternatives, correctIndex],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Criar Pergunta</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <QuestionContainer>
            <Textarea
              name="question"
              label="Questão"
              placeholder="Descrição da questão"
            />
            {alternatives.map((alternative, index) => (
              <AlternativeContainer key={index}>
                <Checkbox
                  name={`alternativeCorrect${index + 1}`}
                  checkboxName={''}
                  checked={correctIndex === index}
                  onChecked={() => handleCheckboxChange(index)}
                />
                <Input
                  name={`alternative${index + 1}`}
                  icon={BiFont}
                  label={`Alternativa ${index + 1}`}
                  //  placeholder={`Alternativa ${index + 1}`}
                  value={alternative}
                  onChange={e => handleAlternativeChange(index, e.target.value)}
                />
              </AlternativeContainer>
            ))}
            <Select
              name="difficultyLevel"
              options={optionsLevel}
              label="Selecione a dificuldade"
              style={{ marginBottom: '10px' }}
            />
            <Select
              name="type"
              options={optionsChoice}
              label="Selecione única escolha"
              style={{ marginBottom: '10px' }}
            />
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </QuestionContainer>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default CreateQuestionGameBible;
