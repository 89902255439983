import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TablePlace from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string,
  name: string,
  description: string,
  latitude: number,
  longitude: number,
  altitude: number,
  created_at: Date,
  image_url: string,
  video_url: string,
  visible: boolean,
  link_portal: string
}

interface IPlace {
  id: string,
  name: string,
  description: string,
  latitude: number,
  longitude: number,
  altitude: number,
  created_at: Date,
  image_url: string,
  video_url: string,
  visible: boolean,
  link_portal: string
}

function createData(
  id: string,
  name: string,
  description: string,
  latitude: number,
  longitude: number,
  altitude: number,
  created_at: Date,
  image_url: string,
  video_url: string,
  visible: boolean,
  link_portal: string,
): Data {
  return { id, name, description, latitude, longitude, altitude, created_at, image_url, video_url, visible, link_portal };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'latitude', numeric: false, disablePadding: false, label: 'Latitude' },
  { id: 'longitude', numeric: false, disablePadding: false, label: 'Longitude' },
  { id: 'altitude', numeric: false, disablePadding: false, label: 'Altitude' },
  { id: 'visible', numeric: false, disablePadding: false, label: 'Visível?' },
  { id: 'image_url', numeric: false, disablePadding: false, label: 'Imagem' },
];

const Place = () => {
  const [rows, setRows] = useState<IPlace[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/places/').then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/places/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Ponto do mapa deletado com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])

  const placeRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.name, row.description, row.latitude, row.longitude, row.altitude, row.created_at, row.image_url, row.video_url, row.visible, row.link_portal));
  }, [rows]);

  const onUpload = useCallback((row) => {
    history.push('/places/upload', { id: row.id });
  }, [history]);

  const onCreate = useCallback(() => {
    history.push('/places/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/places/edit', { id: row.id });
  }, [history]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.latitude}</TableCell>
      <TableCell align="left">{row.longitude}</TableCell>
      <TableCell align="left">{row.altitude}</TableCell>
      <TableCell align="left">{row.visible ? 'Sim' : 'Não'}</TableCell>
      <TableCell align="left"><a href={row.image_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>Abrir imagem</a></TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TablePlace<Data, HeadCell[]>
          title='Pontos do Mapa'
          headCells={headCells}
          rows={placeRows}
          orderByDefaultColumn='code'
          onCreate={onCreate}
          onDelete={onDelete}
          onUpload={onUpload}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Place;
