import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  width?: string | '100%';
}

const Card = styled.div<CardProps>`
  width: ${props => props.width};
  padding: 2rem;
  background: #514f59;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const RawCard: React.FC<CardProps> = ({ width, style, ...components }) => {
  return <Card width={width} style={style}>{components.children}</Card>;
};

export default RawCard;
