import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';
import { Content } from './styles';
import { MdComputer, MdSearch } from 'react-icons/md';

interface CameraFormData {
  alias: string;
  url: string
}

interface CameraData {
  id: number
  alias: string;
  url: string
}

interface LocationState {
  id: string;
};



const EditLink = () => {
  const [link, setLink] = useState<CameraData>();
  const [loading, setLoading] = useState(false);
  

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/links/${state.id}`).then(response => {
      setLink(response.data);
      
    });

  }, [state.id]);

  const handleSubmit = useCallback(
    async (data: CameraFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          alias: Yup.string().required(),
          url: Yup.string().required()
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/links/${state.id}`, data);

        history.push('/links');

        addToast({
          type: 'success',
          title: 'Link atualizado!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar o Link',
          description: 'Ocorreu um erro ao atualizar o link, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar link</h1>
        <Form
          ref={formRef}
          initialData={{
            alias: link?.alias,
            url: link?.url
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Input name="alias" icon={MdSearch} label="Link alias" placeholder="Nome para o link" />
            <Input name="url" icon={MdComputer} label="Link url" placeholder="Url ex: https://google.com..." />
            <Button type="submit" loading={loading}>Salvar</Button>
          </div>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditLink;
