/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';

interface User {
  id: number;
  name: string;
  email: string;
}

interface UserPermission {
  id: number;
  user_id: number;
  permission_id: number;
}

interface Permission {
  id: number;
  title: string;
  code: number;
}

interface AuthState {
  token: string;
  user: User;
  user_permissions: UserPermission[],
  permissions: Permission[],
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  user_permissions: UserPermission[],
  permissions: Permission[],
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@A12:token');
    const user = localStorage.getItem('@A12:user');
    const user_permissions = localStorage.getItem('@A12:user_permissions');
    const permissions = localStorage.getItem('@A12:permissions');

    if (token && user && user_permissions && permissions) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user), user_permissions: JSON.parse(user_permissions), permissions: JSON.parse(permissions) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions/user', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@A12:token', token);
    localStorage.setItem('@A12:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    const user_permissions = await api.get(`/permission_users/user/${user.id}`);
    localStorage.setItem('@A12:user_permissions', JSON.stringify(user_permissions.data));

    const permissions = await api.get('/permissions');
    localStorage.setItem('@A12:permissions', JSON.stringify(permissions.data));

    setData({ token, user, user_permissions: user_permissions.data, permissions: permissions.data });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@A12:permissions');
    localStorage.removeItem('@A12:user_permissions');
    localStorage.removeItem('@A12:token');
    localStorage.removeItem('@A12:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@A12:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
        user_permissions: data.user_permissions,
        permissions: data.permissions,
      });
    },
    [setData, data.token, data.user_permissions, data.permissions],
  );

  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response.status === 401) {
          alert('Sessão expirada, faça novamente login');

          signOut();
        }

        return Promise.reject(err);
      },
    );
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{ user: data.user, user_permissions: data.user_permissions, permissions: data.permissions, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
