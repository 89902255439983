import styled from 'styled-components';
import { shade } from 'polished';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 auto;

  form {
    margin: 40px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 24px;
    }
  }
`;

export const UploadNewsInput = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  input {
    width: 386px;
    height: 56px;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px;
    background-color: #fff;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: #008FC3;
    border-radius: 50%;
    right: 5px;
    bottom: -25px;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #312e38;
    }

    &:hover {
      background: ${shade(0.2, '#008FC3')};
    }
  }
`;
