import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import api from '../../services/api';
import { Content } from './styles';

import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import Axios from 'axios';

interface Data {
  id: string;
  code: number;
  text: string;
  created_on: Date;

}
interface IVideo {
  id: number;
  text: string;
  created_on: Date;
}

function createData(
  id: string,
  code: number,
  text: string,
  created_on: Date
): Data {
  return { id, code, text, created_on };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'text', numeric: false, disablePadding: false, label: 'Text' },
  { id: 'created_on', numeric: false, disablePadding: false, label: 'Data' },
];

const VideoSearchList = () => {
  const [rows, setRows] = useState<IVideo[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const [limitToParent, setLimitToParent] = useState<number>(10)
  const [pageToParent, setPageToParent] = useState<number>(0)

  

  useEffect(() => {    
    api.get(`/videos/search/all`, {
      params: {
        "limit": limitToParent,
        "page": pageToParent
      }
    }).then(response => {
      setRows(response.data);
      setLoading(false)
    });
  }, [deleted, limitToParent, pageToParent]);


  const newsRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.text, row.created_on));
  }, [rows]);


  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.text}</TableCell>
      <TableCell align="left">{format(parseISO(row.created_on), "dd/MM/YYY HH:mm:ss", { locale: ptBR })}</TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title='Busca de Videos'
          headCells={headCells}
          rows={newsRows}
          tableBodyCells={tableBodyCells}
          loading={loading}
          setLimitToParent={setLimitToParent}
          setPageToParent={setPageToParent}
        />
      </Content>
    </StandardContainer>
  )
}

export default VideoSearchList;
