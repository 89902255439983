import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableCamera from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import { DeleteButton } from '../Banner/styles';
import { IoIosTrash } from 'react-icons/io';

interface Data {
  id: string;
  code: number;
  name: string;
}

function createData(
  id: string,
  code: number,
  name: string
): Data {
  return { id, code, name };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'name', numeric: false, disablePadding: false, label: '' },
];

const CacheKeyList = () => {
  const [rows, setRows] = useState<string[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/cache/')
      .then(response => {
        setRows(response.data);
        setLoading(false);
      });
  }, [deleted]);

  const onDelete = useCallback((name) => {
    api.delete(`/cache/${name}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Dados do cache removidos com sucesso!',
      });
    });

    api.get('/cache/')
      .then(response => {
        setRows(response.data);
      });
  }, [rows])

  const dadosRows = useMemo(() => {
    return rows.map((row, index) => {
      const id = index + 1;
      return createData(id.toString(), id, row)
    });
  }, [rows]);



  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left"><DeleteButton onClick={() => onDelete(row.name)}><IoIosTrash/></DeleteButton></TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableCamera<Data, HeadCell[]>
          title='Cache Keys'
          headCells={headCells}
          rows={dadosRows}
          orderByDefaultColumn='code'
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default CacheKeyList;
