import styled from 'styled-components';

interface IToggleProps {
  toggle: boolean;
}

export const Container = styled.div<IToggleProps>`
  display: grid;
  grid-template-columns: ${props => props.toggle ? '1fr 5fr' : '1fr'};
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  div#content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Content = styled.div<IToggleProps>`
  width: 90%;
  height: 100%;
  @media (max-width: 1024px) {
    width: 86%;
  }
`;

export const Toggle = styled.button<IToggleProps>`
  position: fixed;
  top: 1rem;
  margin-left: -1.5rem;
  width: 3rem;
  height: 4rem;
  border-radius: 0 50% 50% 0;
  z-index: 100;
  left: ${props => props.toggle ? '18vw' : '0'};
  background: #28262e;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  svg {
    margin-left: 1rem;
    color: #A4A4A4;
  }
  @media (max-width: 1024px) {
    left: ${props => props.toggle ? '70vw' : '0vw'};
  }
  @media print {
    display: none;
  }
`;
