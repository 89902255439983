import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableUsers from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { DeleteButton } from '../Banner/styles';
import { IoIosTrash, IoMdKey, IoMdKeypad } from 'react-icons/io';

interface Data {
  id: string;
  code: number;
  name: string;
}

interface IUsers {
  id: number;
  name: string;
}

function createData(
  id: string,
  code: number,
  name: string,
): Data {
  return { id, code, name };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Actions' },
];

const Users = () => {
  const [rows, setRows] = useState<IUsers[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/users/').then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const usersRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.name));
  }, [rows]);

  const onCreate = useCallback(() => {
    history.push('/users/new');
  }, [history]);

  const onDelete = useCallback((id) => {
    api.delete(`/users/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Usuário deletado com sucesso!',
      });
    }).catch((err) => {
      addToast({
        type: 'error',
        title: 'Não foi possível deletar este usuário!',
      });
    });
  }, [setDeleted, deleted, addToast]);

  const onEdit = (id: number, name: string) => {
    history.push('/users/edit/password', { id, name });
  }

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left"><DeleteButton onClick={() => onEdit(row.id, row.name)}><IoMdKey /></DeleteButton></TableCell>

    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableUsers<Data, HeadCell[]>
          title='Usuário'
          headCells={headCells}
          rows={usersRows}
          orderByDefaultColumn='code'
          onCreate={onCreate}
          onDelete={onDelete}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Users;
