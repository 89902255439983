import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, ColorPicker, Error } from './styles';

interface ColorPickerProps {
  id: string;
  defaultColor: string;
  error?: string;
  onChange: (e: string) => void;
};

const ColorPickerInput: React.FC<ColorPickerProps> = ({
  id,
  defaultColor,
  error,
  onChange,
}) => {
  return (
    <Container id={id}>
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#cd351d" size={20} />
        </Error>
      )}
      <p>Selecione a cor da editoria</p>
      <ColorPicker disableAlpha color={defaultColor} onChangeComplete={(color) => onChange(color.hex)} />
    </Container>
  );
}

export default ColorPickerInput
