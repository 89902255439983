import React, { useCallback, useEffect } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useState } from 'react';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content, UploadBannerInput } from './styles';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';

interface IWatermark {
  id: number;
  image: string;
  position: string;
  opacity: number;
}

const Watermark = () => {
  const [watermark, setWatermark] = useState<IWatermark | null>(null);
  
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { addToast } = useToast();
  const history = useHistory();

  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [preview, setPreview] = useState<string>()

  const loadSavedWatermark = () => {
    api.get('/watermark/').then(response => {
      let watermarks = response.data
      if (watermarks?.length > 0){
        console.log(watermarks[0])
        setWatermark(watermarks[0]);
      }      
    });
  }

  useEffect(() => {
    loadSavedWatermark()
  }, []);

  useEffect(() => {
    // create the preview
    if (selectedFile){
      const objectUrl = window.URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
  
      // free memory when ever this component is unmounted
      return () => window.URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e:any) => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }

  const initializeForm = () => {
    setSelectedFile(null)
    setShowForm(true)
  }

  const cancel = () => {
    setSelectedFile(null)
    setShowForm(false)
  }

  const handleUploadImage = useCallback(async () => {
    setLoading(true);
    try {
      if (selectedFile) {
        const data = new FormData();
        data.append('watermark', selectedFile);

        await api.patch(`/watermark/image`, data);
        history.push('/watermark');

        addToast({
          type: 'success',
          title: "Marca d'água atualizada!",
        });

        loadSavedWatermark()
        setShowForm(false)
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar imagem!',
        description: 'Tente novamente mais tarde :)',
      });
    }

    setLoading(false);
  }, [addToast, selectedFile, history]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Marca d'Água das câmeras ao vivo</h1>
        <br />
        
        {( watermark != null ) &&  
          <>
            <img src={watermark.image} alt="Imagem para câmeras" />
          </> 
        }

        { !showForm && 
          <Button onClick={() => { initializeForm() }}>Enviar imagem</Button>
        }
        
        { showForm && 
          <>
            <UploadBannerInput>
              <input disabled value={selectedFile?.name} />
              <label htmlFor="uploadWatermark">
                <FiUpload />
                <input type="file" id="uploadWatermark" onChange={onSelectFile} />
              </label>
            </UploadBannerInput>
            {( selectedFile ) &&  <img src={preview} alt="Imagem para câmeras" /> }
            <Button onClick={handleUploadImage} loading={loading}>Salvar</Button>
            <Button onClick={cancel} style={{backgroundColor: "#999"}} loading={loading}>Cancelar</Button>
          </>      
        }

      </Content>
    </StandardContainer>
  )
}

export default Watermark;
