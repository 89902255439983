import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Content,
  VersionCard,
} from './styles';

import StandardContainer from '../../components/StandardContainer';
import Header from '../../components/Header';
import { FormHandles } from '@unform/core';
import { VersionStatusEnum } from '../../utils/VersionStatusEnum';
import * as Yup from 'yup';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import { Form } from '@unform/web';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { FiPackage } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import RawCard from '../../components/Card';
import Select from '../../components/Select';

interface ICreateVersionData {
  version: string;
  status: VersionStatusEnum.FORCAR | VersionStatusEnum.INDIFERENTE | VersionStatusEnum.RECOMENDAR;
}

interface IVersion {
  id: number;
  version: string;
  status: string;
}

const statusOptions = [
  { value: VersionStatusEnum.FORCAR, label: 'Forçar' },
  { value: VersionStatusEnum.RECOMENDAR, label: 'Recomendar' },
  { value: VersionStatusEnum.INDIFERENTE, label: 'Indiferente' },
];

const Version: React.FC = () => {
  const [versions, setVersions] = useState<IVersion[]>([]);
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function loadVersions(): Promise<void> {
      const response = await api.get('versions');
      setVersions(response.data);
    }

    loadVersions();
  }, [user.id]);

  const handleSubmit = useCallback(
    async (data: ICreateVersionData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          version: Yup.string().required('Versão é obrigatória'),
          status: Yup.string().required('Selecione o status da versão'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/versions', data);

        setVersions([...versions, response.data]);

        addToast({
          type: 'success',
          title: 'Cadastro realizado',
          description: 'Nova versão adicionada com sucesso.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar versão',
          description: 'Ocorreu um erro ao cadastrar uma nova versão, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, versions],
  );

  const handleDelete = useCallback(async (id: number) => {
    try {
      const response = await api.delete(`/versions/${id}`);

      if (!response.data) {
        throw new Error();
      }

      const index = versions.findIndex(version => version.id === id);

      versions.splice(index, 1);

      setVersions(versions);

      addToast({
        type: 'success',
        title: 'Versão removida com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: 'Erro ao deletar a versão, tente novamente.',
      });
    }
  }, [addToast, versions]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <RawCard>
          <h2 style={{ marginBottom: '20px' }}>Adicionar versão</h2>
          <Form ref={formRef} onSubmit={handleSubmit}>
              <Input name="version" icon={FiPackage} label="Versão" placeholder="Versão" />
              <Select
                name="status"
                options={statusOptions}
                label="Selecione o status da versão"
              />
            <Button type="submit" loading={loading}>Cadastrar</Button>
          </Form>
        </RawCard>
        <RawCard style={{ height: '500px', overflowY: 'auto' }}>
          <h2>Versões</h2>
          {versions.map((version) => (
            <VersionCard>
              <div>{`v${version.version} - ${version.status}`}</div>
              <button onClick={() => handleDelete(version.id)}><AiOutlineDelete /></button>
            </VersionCard>
          ))}
        </RawCard>
      </Content>
    </StandardContainer>
  );
};

export default Version;
