export enum PermissionsEnum {
  ADMIN = 1,
  CAMERA = 2,
  STREAM_MULTIMEDIA = 3,
  WATERMARK = 4,
  BANNER = 5,
  NEWS = 6,
  EDITORIALS = 7,
  AUTHOR = 8,
  VERSION = 9,
  BIBLE = 10,
  NOTIFICATION = 11,
  REPORT = 12,
  PLACE = 13,
  APARECIDA_PLAY = 14,
  APARECIDA_PLAY_LIVE = 15,
  APARECIDA_PLAY_POP_RADIO = 16,
  APARECIDA_PLAY_YOUNG_DEVOTEES = 17,
  APARECIDA_PLAY_A12 = 18,
  APARECIDA_PLAY_RADIO = 19,
  APARECIDA_PLAY_TV = 20,
  APARECIDA_PLAY_YOUNG_PEOPLE_MARIA = 21,
  APARECIDA_PLAY_REDEMPTORIST = 22,
  APARECIDA_PLAY_NATIONAL_SANCTUARY = 23,
  CHATBOT_DEVOTEE_FAMILY = 24,
  GAME = 25,
  TASKSGAME = 26,
  CHANNEL = 27,
  BIBLEGAME = 28
}
