import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableAuthor from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  name: string;
}

interface IAuthor {
  id: number;
  name: string;
}

function createData(
  id: string,
  code: number,
  name: string,
): Data {
  return { id, code, name };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
];

const Author = () => {
  const [rows, setRows] = useState<IAuthor[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/authors/').then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/authors/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Autor deletado com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])

  const authorRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.name));
  }, [rows]);

  const onCreate = useCallback(() => {
    history.push('/author/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/author/edit', { id: row.code });
  }, [history]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableAuthor<Data, HeadCell[]>
          title='Autor'
          headCells={headCells}
          rows={authorRows}
          orderByDefaultColumn='code'
          onCreate={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Author;
