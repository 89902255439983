import React, { useCallback, useRef, useEffect, useState } from 'react';

import { BiFont } from 'react-icons/bi';
import { FiYoutube, FiLink } from 'react-icons/fi';
import { GrSoundcloud } from 'react-icons/gr';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Content, EditorTitle } from './styles';
import StandardContainer from '../../../components/StandardContainer';
import Header from '../../../components/Header';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import { Editor } from '@tinymce/tinymce-react';

import { NewsTypeEnum } from '../../../utils/NewsTypeEnum';

interface TasksGameFormData {
  id: string;
  description: string;
  screen: string;
  active: string;
}

interface LocationState {
  id: string;
  screen: string;
}

const optionsScreens = [
  { value: 'AparecidaPlayHome', label: 'Home do Aparecida Play' },
  { value: 'NewsDetails', label: 'Detalhes da Notícia' },
  { value: 'LiveSanctuaryHome', label: 'Encontre Aparecida ao Vivo' },
  // {
  //   value: 'AparecidaPlayPlayer',
  //   label: 'Área AparecidaPlay', -- TIREI, PQ REMETE PRO MESMO LUGAR DO ITEM ACIMA. NO APP, NO CARROSSEL, SÓ TEM AparecidaPlayHome

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Encontre Aparecida Play',
  // }, --  // Não há como ter dois values iguais no select (value acima igual a alguns de baixo)

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Devotos Mirins no Aparecida Play',
  // }, -- Não há como ter dois values iguais no select (value acima igual a este e os de baixo)

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Santuário Nacional no Aparecida Play',
  // },
  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Canal a12 no Aparecida Play',
  // },
  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Rádio Aparecida no Aparecida Play',
  // },

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Canal TV Aparecida no Aparecida Play',
  // },

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Rádio Pop no Aparecida Play',
  // },

  // {
  //   value: 'AparecidaPlayChannel',
  //   label: 'Jovens de Maria no Aparecida Play',
  // },

  { value: 'AparecidaTv', label: 'TV Aparecida' },
  {
    value: 'DailyMeditation',
    label: 'Meditação Diária',
  },
  {
    value: 'OurGodWithUsStackScreen',
    label: 'Deus Conosco',
  },
  { value: 'SaintOfDay', label: 'Santo do Dia' },
  { value: 'DayToDay', label: 'Dia a Dia' },
  { value: 'OnlineBible', label: 'Bíblia online' },
  { value: 'SearchBible', label: 'Busca na Bíblia online' },
  // {
  //   value: 'ChoiceBibleBookChapterVerse',
  //   label:
  //     'Página para escolher livro, capítulo e versículo no Novo Testamento',
  // },
  {
    value: 'ChooseNewTestament',
    label: 'Encontre o Novo Testamento',
  },
  {
    value: 'ChooseOldTestament',
    label: 'Encontre o Antigo Testamento',
  },
  { value: 'RadioAparecidaHome', label: 'Home Rádio Aparecida' },
  {
    value: 'RadioAparecida',
    label: 'Vídeos da Rádio Aparecida',
  },
  { value: 'RadioPopHome', label: 'Home da Rádio Pop' },
  {
    value: 'RadioPop',
    label: 'Vídeos da Rádio Pop',
  },
  { value: 'DevoteesFamilyHome', label: 'Família dos Devotos' },
  { value: 'DevoteeArea', label: 'Área do devoto' },
  { value: 'NewRegister', label: 'Novo Cadastro de devoto' },
  { value: 'NewRegisterChat', label: 'Chat novo cadastro de devoto' },
  { value: 'Magazine', label: 'Revista de Aparecida' },
  { value: 'Donation', label: 'Doação por meio da família dos Devotos' },
  { value: 'NewsHome', label: ' Página de Notícias do a12' },
  // { value: 'RegisterScreen', label: 'Página cadastro Game' }, - TIREI, PQ ENQUANTO A PESSOA ESTÁ
  // JOGANDO, NÃO APARECE O CADASTRO DO GAME, LOGO, NÃO HÁ COMO HAVER A PERGUNTA POR N TER COMO ACERTAR
  // { value: 'RankingScreen', label: 'Página Ranking Game' }, - TIREI, PQ ENQUANTO A PESSOA ESTÁ
  // JOGANDO, NÃO APARECE O RANKING DO GAME, LOGO, NÃO HÁ COMO HAVER A PERGUNTA POR N TER COMO ACERTAR
  // {
  //   value: 'ForgetPassword',
  //   label: 'Área "esqueci minha senha" do devoto que possui login',
  // }, -- AO CLICAR, REMETE PRA UMA PÁGINA FORA DO APP (a12.com/entrar?redirect....), acredito q por conta disso, não seja rastreado no app, por isso tirei
  { value: 'About', label: 'Página sobre menu lateral, com dados de contato' },
];


const EditTaskGame = () => {
  const [task, setTask] = useState<TasksGameFormData>();
  const [loading, setLoading] = useState(false);
  const [checkedactive, setCheckedActive] = useState(false);
  const [screenOption, setScreenOption] = useState<string>('');

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    console.log(state.id, 'state');
    api.get(`/tasksgame/${state.id}`).then(response => {
      setTask(response.data);
      console.log(response.data, 'response')
      // const tasks = response.data.map((task: TasksGameFormData) => ({
      //   label: task.screen
      // }))
      setScreenOption(response.data.screen)
      if (response.data.active === 'Y') {
        setCheckedActive(true);
      }
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: TasksGameFormData) => {
      try {
        formRef.current?.setErrors({});

        if (checkedactive) {
          data.active = 'Y';
        } else {
          data.active = 'N';
        }

        setLoading(true);

        if (
          data.description !== '' &&
          data.screen !== '' &&
          data.active !== ''
        ) {
          api
            .put(`/tasksgame/${state.id}`, {
              ...data,
            })
            .then(res => {
              history.push('/tasksgame');
              addToast({
                type: 'success',
                title: 'Task atualizada!',
              });
            })
            .catch(err => console.log(err));
        
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar task',
            description: 'Preencha todos os dados.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar task',
          description: 'Ocorreu um erro ao atualizar notícia, tente novamente.',
        });
      }
      setLoading(false);
    },
    [addToast, history, state.id, checkedactive],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar task</h1>
        <Form
          ref={formRef}
          initialData={{
            description: task?.description,
            screen: task?.screen,
            active: task?.active,
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Input
              name="description"
              icon={BiFont}
              label="Descrição"
              placeholder="Descrição da task"
            />
            <div style={{ marginTop: '15px' }}>
              <Checkbox
                name="checkedActive"
                checkboxName="Task Ativa"
                checked={checkedactive}
                onChecked={() => setCheckedActive(!checkedactive)}
              />
            </div>
            <Select
              name="screen"
              options={optionsScreens}
              label="Selecione a tela"
              style={{ marginBottom: '10px' }}
              defaultOption={screenOption}
            />
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </div>
        </Form>
      </Content>
    </StandardContainer>
  );
};

export default EditTaskGame;
