import React, { useEffect, useState } from 'react';

import {
  Col,
  Content, Row, TitleGraph,
} from './styles';

import StandardContainer from '../../components/StandardContainer';
import Header from '../../components/Header';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import api from '../../services/api';
import DatetimeInput from '../../components/DatetimeInput';
import Button from '../../components/Button';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

interface IStats {
  name: string;
  total: number;
}

const Dashboard: React.FC = () => {

  const [dataCameras, setDataCameras] = useState<IStats[]>([])
  const [dataAreas, setDataAreas] = useState<IStats[]>([])
  const [loading, setLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm')); //7 dias atrás
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm')); //até a data/hora atual
  const [maxValueCameras, setMaxValueCameras] = useState(10000);
  const [maxValueAreas, setMaxValueAreas] = useState(10000);
  const [avgBettweenDays, setAvgBettweenDays] = useState(0);

  useEffect(() => {
    reloadGraphs()
  }, [])

  const reloadGraphs = () => {
    loadCameraStats()
    loadAreaStats()
  }

  const formatDateTime = (dateTime: string) => {
    return dateTime.replace("T", " ")
  }

  const loadCameraStats = () => {
    setLoading(true)
    api.get(`/cameras/stats/period?start=${formatDateTime(selectedStartDate)}&end=${formatDateTime(selectedEndDate)}`).then(response => {
      setLoading(false)
      let data: IStats[] = response.data
      setDataCameras(data)
      setMaxValueCameras(Math.max(...data.map((camera) => Number(camera.total))))
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    });
  }

  const shortText = (text: string, limitChars: number) => { //extrai limitChars do texto caso ultrapasse o tamanho de limitChars

    if (text.length > limitChars) {
      //concatena o começo e o fim do texto
      text = text.substring(0, Math.floor(limitChars / 2)) + ".." + text.substring(- Math.floor(limitChars / 2))
    }
    return text
  }

  const loadAreaStats = () => {
    setLoading(true)
    api.get(`/areas/stats/period?start=${formatDateTime(selectedStartDate)}&end=${formatDateTime(selectedEndDate)}`).then(response => {
      setLoading(false)
      let areas: IStats[] = response.data
      areas = areas.map((area) => {
        const name = EnumAreas[area.name];
        if (name) {
          area.name = shortText(name, 10)
        }
        return area
      })
      setDataAreas(areas)
      setMaxValueAreas(Math.max(...areas.map((area) => Number(area.total))))
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    });

    api.get(`/users/push-token/days/avg?start=${formatDateTime(selectedStartDate)}&end=${formatDateTime(selectedEndDate)}`)
      .then(response => {
        setLoading(false)
        const { data } = response

        setAvgBettweenDays(data.avg)

      }).catch((error) => {
        setLoading(false)
        console.log(error)
      });
  }

  const EnumAreas: any = {
    "APARECIDA_RADIO": "Rádio Aparecida",
    "APARECIDA_PLAY": "Aparecida Play",
    "DEVOTEE_FAMILY": "Família dos Devotos",
    "OUR_GOD_WITH_US": "Deus Conosco",
    "LIVE_SANCTUARY": "Santuário Ao Vivo",
    "TV": "TV Aparecida",
    "ONLINE_BIBLE": "Bíblia",
    "NEWS": "A12 Notícias",
    "POP_RADIO": "Rádio Pop"
  }

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Row>
          <DatetimeInput
            id="datetime-start"
            label="Data e hora iniciais"
            name="dateStart"
            value={selectedStartDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedStartDate(e.target.value)}
          />
          <DatetimeInput
            id="datetime-end"
            label="Data e hora finais"
            name="dateEnd"
            value={selectedEndDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedEndDate(e.target.value)}
          />
          <Button type="button" onClick={reloadGraphs} loading={loading} style={{ maxWidth: 150 }}>Filtrar</Button>
        </Row>
        <Row>
          <Col style={{ marginLeft: "24%", width: "50%" }}>
            <div style={{ backgroundColor: "white", color: "black", borderRadius: "10px" }}>
              <TitleGraph style={{ color: "black" }} >Média de pessoas:</TitleGraph>
              <TitleGraph style={{ marginBottom: "10px", color: "black" }}>{avgBettweenDays}</TitleGraph>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <TitleGraph style={{ color: "#fffF" }}>Número de acessos por Câmera</TitleGraph>
            {loading && <CircularProgress size={32} />}
            {!loading &&
              <>
                {dataCameras.length > 0 &&
                  <ResponsiveContainer width="100%" height="100%" minHeight={400}>
                    <BarChart
                      width={400}
                      height={400}
                      data={dataCameras}
                      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="1 1" />
                      <XAxis dataKey="name" height={50} />
                      <YAxis tickCount={100} domain={[0, maxValueCameras]} />
                      <Tooltip
                        labelStyle={{ color: "#000" }}
                        labelFormatter={function (value) {
                          return `Câmera: ${value}`;
                        }}
                        formatter={(value: number) => new Intl.NumberFormat('pt').format(value)} />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                }
                {dataCameras.length === 0 &&
                  <>Não há dados no período</>
                }
              </>
            }
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <TitleGraph style={{ color: "#fffF" }} >Número de Acessos por Área do App</TitleGraph>
            {loading && <CircularProgress size={32} />
            }
            {!loading &&
              <>
                {dataAreas.length > 0 &&
                  <ResponsiveContainer width="100%" height="100%" minHeight={400}>
                    <BarChart
                      width={400}
                      height={400}
                      data={dataAreas}
                      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="1 1" />
                      <XAxis dataKey="name" height={50} />
                      <YAxis tickCount={100} domain={[0, maxValueAreas]} />
                      <Tooltip
                        labelStyle={{ color: "#000" }}
                        labelFormatter={function (value) {
                          return `Área: ${value}`;
                        }}
                        formatter={(value: number) => new Intl.NumberFormat('pt').format(value)} />
                      <Legend />
                      <Bar dataKey="total" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                }
                {dataAreas.length === 0 &&
                  <>Não há dados no período</>
                }
              </>

            }
          </Col>
        </Row>
      </Content>
    </StandardContainer>
  );
};

export default Dashboard;
