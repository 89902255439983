export enum ScreensNotificationTypeEnum {
    HOME = 'home',
    BIBLIA_BUSCA = 'biblia_busca',
    BIBLIA_LEITURA = 'biblia_leitura',
    RADIO_APARECIDA = 'radio_aparecida',
    RADIO_POP = 'radio_pop',
    TV_APARECIDA = 'tv_aparecida',
    DEUS_CONOSCO_DIA_DIA = 'deus_conosco_dia_dia',
    DEUS_CONOSCO_MEDITACAO_DIARIA = 'deus_conosco_meditacao_diaria',
    DEUS_CONOSCO_SANTO_DIA = 'deus_conosco_santo_dia',
    APARECIDA_PLAY_CANAIS = 'aparecida_play_canais',
    APARECIDA_PLAY_PLAYLISTS = 'aparecida_play_playlists',
    APARECIDA_PLAY_VIDEO = 'aparecida_play_video',
    FAMILIA_HOME = 'familia_home',
    FAMILIA_CADASTRO = 'familia_cadastro',
    FAMILIA_REVISTA = 'familia_revista',
    APARECIDA_AO_VIVO_CAMERA = 'aparecida_ao_vivo_camera',
    NOTICIA = 'noticia',
    HOME_NOTICIA = 'home_noticia',
    VAZIO = ''
  }
  