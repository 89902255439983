import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';
import Version from '../pages/Version';

import Banner from '../pages/Banner';
import CreateBanner from '../pages/Banner/Create';
import BannerUpload from '../pages/BannerUpload';

import Camera from '../pages/Camera';
import CreateCamera from '../pages/Camera/Create';
import EditCamera from '../pages/Camera/Edit';
import ScheduleCamera from '../pages/Camera/Schedule';
import CameraUpload from '../pages/CameraUpload';

import Editorial from '../pages/Editorial';
import CreateEditorial from '../pages/Editorial/Create';
import EditEditorial from '../pages/Editorial/Edit';

import Multimedia from '../pages/Multimedia';
import CreateMultimedia from '../pages/Multimedia/Create';
import EditMultimedia from '../pages/Multimedia/Edit';
import MultimediaUpload from '../pages/MultimediaUpload';

import Author from '../pages/Author';
import CreateAuthor from '../pages/Author/Create';
import EditAuthor from '../pages/Author/Edit';

import News from '../pages/News';
import CreateNews from '../pages/News/Create';
import EditNews from '../pages/News/Edit';
import NewsUpload from '../pages/NewsUpload';

import BibleSearches from '../pages/BibleSearches';

import Notifications from '../pages/Notifications';
import CreateNotifications from '../pages/Notifications/Create';

import Profile from '../pages/Profile';
import Watermark from '../pages/Watermark';

import ScheduleBanner from '../pages/Banner/Schedule';
import GeolocationMap from '../pages/GeolocationMap';
import Permissions from '../pages/Permissions';
import ReportAccess from '../pages/Reports/ReportAccess';
import ReportContentType from '../pages/Reports/ReportContentType';
import ReportTimeScreen from '../pages/Reports/ReportTimeScreen';
import { PermissionsEnum } from '../utils/PermissionsEnum';

import DailyMessageList from '../pages/DailyMessage';
import DailyMessageCreate from '../pages/DailyMessage/Create';
import UpdateDailyMessage from '../pages/DailyMessage/Edit';
import Users from '../pages/Users';
import CreateUser from '../pages/Users/Create';
import CreateTaskGame from '../pages/TasksGame/Create';

import CacheKeyList from '../pages/CacheKeys';
import ChannelList from '../pages/Channel';
import ImageUploadChannel from '../pages/Channel/ImageUpload';
import Link from '../pages/Link';
import CreateLink from '../pages/Link/Create';
import EditLink from '../pages/Link/Edit';
import Place from '../pages/Place';
import CreatePlace from '../pages/Place/Create';
import EditPlace from '../pages/Place/Edit';
import PlaceUpload from '../pages/PlaceUpload';
import PlaylistList from '../pages/Playlist';
import PodcastList from '../pages/Podcast';
import ReportAccessDay from '../pages/Reports/ReportAccessDay';
import ReportChannelAparecidaPlay from '../pages/Reports/ReportChannelAccess';
import ReportHealthCheck from '../pages/Reports/ReportHealthCheck';
import ReportPlaylistAparecidaPlay from '../pages/Reports/ReportPlaylistAccess';
import ReportVideoAparecidaPlay from '../pages/Reports/ReportVideosAccess';
import VideoSearchList from '../pages/Video';
import VideoCreate from '../pages/Video/Create';
import VideoEdit from '../pages/Video/Edit';

import EditBanner from '../pages/Banner/Edit';
import { CronJobList } from '../pages/CronJobs';
import { CronList } from '../pages/Crons';
import ReportCamerasAccess from '../pages/Reports/ReportCamerasAccess';
import ReportNewsAccess from '../pages/Reports/ReportNewsAccess';
import EditUser from '../pages/Users/Edit';
import VideoList from '../pages/VideoSearches';
import ReportBannersAccess from '../pages/Reports/ReportBannersAccess';
import ReportAreasAccess from '../pages/Reports/ReportAreaAccess';
import Chat from '../pages/Chat';
import CreateChat from '../pages/Chat/Create';
import EditChat from '../pages/Chat/Edit';
import AddBpmn from '../pages/AddBpmn';
import ConfirmRegisterDevotee from '../pages/ConfirmRegisterDevotee';
import Game from '../pages/Game';
import TasksGame from '../pages/TasksGame';
import EditTaskGame from '../pages/TasksGame/Edit';
import EditChannel from '../pages/Channel/Edit';
import GameBible from '../pages/GameBible';
import CreateQuestionGameBible from '../pages/GameBible/Create';
import EditQuestionGameBible from '../pages/GameBible/Edit';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={SignIn} />
    {/* <Route path="/signup" component={SignUp} /> */}
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/confirm-register-devotee" component={ConfirmRegisterDevotee} />

    <Route path="/add-bpmn" component={AddBpmn} />

    <Route exact path="/dashboard" component={Dashboard} isPrivate />

    <Route exact path="/version" component={Version} isPrivate permissionCode={PermissionsEnum.VERSION} />

    <Route exact path="/banner/new" component={CreateBanner} isPrivate permissionCode={PermissionsEnum.BANNER} />
    <Route exact path="/banner/edit" component={EditBanner} isPrivate permissionCode={PermissionsEnum.BANNER} />
    <Route exact path="/banner/upload" component={BannerUpload} isPrivate permissionCode={PermissionsEnum.BANNER} />
    <Route exact path="/banner/schedule" component={ScheduleBanner} isPrivate permissionCode={PermissionsEnum.BANNER} />
    <Route exact path="/banner" component={Banner} isPrivate permissionCode={PermissionsEnum.BANNER} />

    <Route exact path="/watermark" component={Watermark} isPrivate permissionCode={PermissionsEnum.WATERMARK} />

    <Route exact path="/news/new" component={CreateNews} isPrivate permissionCode={PermissionsEnum.NEWS} />
    <Route exact path="/news/edit" component={EditNews} isPrivate permissionCode={PermissionsEnum.NEWS} />
    <Route exact path="/news/upload" component={NewsUpload} isPrivate permissionCode={PermissionsEnum.NEWS} />
    <Route exact path="/news" component={News} isPrivate permissionCode={PermissionsEnum.NEWS} />

    <Route exact path="/camera/new" component={CreateCamera} isPrivate permissionCode={PermissionsEnum.CAMERA} />
    <Route exact path="/camera/edit" component={EditCamera} isPrivate permissionCode={PermissionsEnum.CAMERA} />
    <Route exact path="/camera/upload" component={CameraUpload} isPrivate permissionCode={PermissionsEnum.CAMERA} />
    <Route exact path="/camera/schedule" component={ScheduleCamera} isPrivate permissionCode={PermissionsEnum.CAMERA} />
    <Route exact path="/camera" component={Camera} isPrivate permissionCode={PermissionsEnum.CAMERA} />

    <Route exact path="/editorial/new" component={CreateEditorial} isPrivate permissionCode={PermissionsEnum.EDITORIALS} />
    <Route exact path="/editorial/edit" component={EditEditorial} isPrivate permissionCode={PermissionsEnum.EDITORIALS} />
    <Route exact path="/editorial" component={Editorial} isPrivate permissionCode={PermissionsEnum.EDITORIALS} />

    <Route exact path="/author/new" component={CreateAuthor} isPrivate permissionCode={PermissionsEnum.AUTHOR} />
    <Route exact path="/author/edit" component={EditAuthor} isPrivate permissionCode={PermissionsEnum.AUTHOR} />
    <Route exact path="/author" component={Author} isPrivate permissionCode={PermissionsEnum.AUTHOR} />

    <Route exact path="/multimedia/new" component={CreateMultimedia} isPrivate permissionCode={PermissionsEnum.STREAM_MULTIMEDIA} />
    <Route exact path="/multimedia/edit" component={EditMultimedia} isPrivate permissionCode={PermissionsEnum.STREAM_MULTIMEDIA} />
    <Route exact path="/multimedia/upload" component={MultimediaUpload} isPrivate permissionCode={PermissionsEnum.STREAM_MULTIMEDIA} />
    <Route exact path="/multimedia" component={Multimedia} isPrivate permissionCode={PermissionsEnum.STREAM_MULTIMEDIA} />

    <Route exact path="/bible" component={BibleSearches} isPrivate permissionCode={PermissionsEnum.BIBLE} />

    <Route exact path="/notifications" component={Notifications} isPrivate permissionCode={PermissionsEnum.NOTIFICATION} />
    <Route exact path="/notifications/new" component={CreateNotifications} isPrivate permissionCode={PermissionsEnum.NOTIFICATION} />

    <Route exact path="/places" component={Place} isPrivate permissionCode={PermissionsEnum.PLACE} />
    <Route exact path="/places/new" component={CreatePlace} isPrivate permissionCode={PermissionsEnum.PLACE} />
    <Route exact path="/places/edit" component={EditPlace} isPrivate permissionCode={PermissionsEnum.PLACE} />
    <Route exact path="/places/upload" component={PlaceUpload} isPrivate permissionCode={PermissionsEnum.PLACE} />

    <Route exact path="/permissions" component={Permissions} isPrivate permissionCode={PermissionsEnum.ADMIN} />

    <Route exact path="/reports/geolocation-map" component={GeolocationMap} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/report-access" component={ReportAccess} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/report-access-day" component={ReportAccessDay} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/report-time-screen" component={ReportTimeScreen} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/report-content-type" component={ReportContentType} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/health-check" component={ReportHealthCheck} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/videos" component={ReportVideoAparecidaPlay} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/playlist" component={ReportPlaylistAparecidaPlay} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/channel" component={ReportChannelAparecidaPlay} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/news" component={ReportNewsAccess} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/cameras" component={ReportCamerasAccess} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/banners" component={ReportBannersAccess} isPrivate permissionCode={PermissionsEnum.REPORT} />
    <Route exact path="/reports/areas" component={ReportAreasAccess} isPrivate permissionCode={PermissionsEnum.REPORT} />

    <Route exact path="/users" component={Users} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/users/new" component={CreateUser} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/users/edit/password" component={EditUser} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/daily/messages" component={DailyMessageList} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/daily/messages/new" component={DailyMessageCreate} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/daily/messages/edit" component={UpdateDailyMessage} isPrivate permissionCode={PermissionsEnum.ADMIN} />

    <Route exact path="/chats" component={Chat} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/chats/new" component={CreateChat} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/chats/edit" component={EditChat} isPrivate permissionCode={PermissionsEnum.ADMIN} />

    <Route exact path="/links" component={Link} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/links/new" component={CreateLink} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/links/edit" component={EditLink} isPrivate permissionCode={PermissionsEnum.ADMIN} />

    <Route exact path="/video" component={VideoList} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />
    <Route exact path="/video/search" component={VideoSearchList} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />
    <Route exact path="/video/new" component={VideoCreate} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />
    <Route exact path="/video/edit" component={VideoEdit} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />

    <Route exact path="/playlist" component={PlaylistList} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />

    <Route exact path="/channel" component={ChannelList} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />
    <Route exact path="/channel/upload" component={ImageUploadChannel} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />

    <Route exact path="/podcast" component={PodcastList} isPrivate permissionCode={PermissionsEnum.APARECIDA_PLAY} />

    <Route exact path="/profile" component={Profile} isPrivate />

    <Route exact path="/cache/list" component={CacheKeyList} isPrivate permissionCode={PermissionsEnum.ADMIN} />

    <Route exact path="/jobs" component={CronJobList} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/jobs/cron" component={CronList} isPrivate permissionCode={PermissionsEnum.ADMIN} />
    <Route exact path="/game" component={Game} isPrivate permissionCode={PermissionsEnum.GAME} />
    <Route exact path="/tasksgame" component={TasksGame} isPrivate permissionCode={PermissionsEnum.TASKSGAME} />
    <Route exact path="/tasksgame/task/create" component={CreateTaskGame} isPrivate permissionCode={PermissionsEnum.TASKSGAME} />
    <Route exact path="/tasksgame/:id" component={EditTaskGame} isPrivate permissionCode={PermissionsEnum.TASKSGAME} />
    <Route exact path="/channel/:id" component={EditChannel} isPrivate permissionCode={PermissionsEnum.CHANNEL} />
    <Route exact path="/bible-game" component={GameBible} isPrivate permissionCode={PermissionsEnum.BIBLEGAME} />
    <Route exact path="/bible-game/question/create" component={CreateQuestionGameBible} isPrivate permissionCode={PermissionsEnum.BIBLEGAME} />
    <Route exact path="/bible-game/:id" component={EditQuestionGameBible} isPrivate permissionCode={PermissionsEnum.BIBLEGAME} />


  </Switch>
);

export default Routes;
