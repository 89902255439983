import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import moment from 'moment';
import Checkbox from '../../../components/Checkbox';
import { useToast } from '../../../hooks/toast';
import {
  Content
} from './styles';
import Input from '../../../components/Input';
import { BiCalendarEdit, BiFont } from 'react-icons/bi';

interface NewsFormData {
  description: string,
  isDefault: boolean,
  date: Date
}

interface LocationState {
  row: Data
}


interface Data {
  id: string;
  code: number;
  description: string;
  isDefault: boolean;
  date: Date;
}



const UpdateDailyMessage = () => {
  const { state } = useLocation<LocationState>();
  const [loading, setLoading] = useState(false);
  const [newsText, setNewsText] = useState('');
  const [isDefault, setCheckedHome] = useState(state.row.isDefault);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: NewsFormData) => {
      try {
        formRef.current?.setErrors({});
        data.isDefault = isDefault

        const schema = Yup.object().shape({
          description: Yup.string().required('mensagem é obrigatoria'),
          isDefault: Yup.boolean(),
          date: Yup.date(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await api.put(`/daily_message/${state.row.id}`, data);

        addToast({
          type: 'success',
          title: 'Mensagem do dia atualizada!',
        });

        history.push('/daily/messages');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar mensagem do dia',
          description: 'Ocorreu um erro ao cadastrar mensagem, tente novamente.',
        });
      }
      setLoading(false);
    },
    [
      newsText,
      isDefault
    ],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Criar notícia</h1>
        <Form
          ref={formRef}
          initialData={{
            description: state.row.description,
            date: moment(state.row.date).format('YYYY-MM-DD')
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <Input name="description" icon={BiFont} label="Descrição da mensagem" placeholder="mensagem" />
            <Input name="date" type="date" icon={BiCalendarEdit} label="Data" placeholder="" />

            <Checkbox
              name="checkbox_home"
              checkboxName="É mensagem padrão"
              checked={isDefault}
              onChecked={() => setCheckedHome(!isDefault)}
            />


            <Button type="submit" loading={loading}>Salvar</Button>
          </div>

        </Form>
      </Content>
    </StandardContainer>
  );
}

export default UpdateDailyMessage;
