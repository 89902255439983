import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import BasicTable from '../../components/Table';

interface Data {
  id: string;
  code: number;
  alias: string;
  url: string;
}

interface ILink {
  id: number;
  alias: string;
  url: string;
}

function createData(
  id: string,
  code: number,
  alias: string,
  url: string
): Data {
  return { id, code, alias, url };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'alias', numeric: true, disablePadding: true, label: 'Alias' },
  { id: 'url', numeric: true, disablePadding: true, label: 'Url' }
];

const Link = () => {
  const [rows, setRows] = useState<ILink[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  const getRows = useCallback(async () => {
    const response = await api.get(`/links/`)
    setRows(response.data)
    setLoading(false)
  }, [rows, loading])

  useEffect(() => {
    getRows()
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/links/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Link deletado com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])

  const cameraRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.alias, row.url));
  }, [rows]);


  const onCreate = useCallback(() => {
    history.push('/links/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/links/edit', { id: row.code });
  }, [history]);


  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.alias}</TableCell>
      <TableCell align="left"><a href={row.url}>{row.url}</a> </TableCell>

    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <BasicTable<Data, HeadCell[]>
          title='Links'
          headCells={headCells}
          rows={cameraRows}
          onCreate={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Link;
