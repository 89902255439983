import React from 'react';
import {
  TextField,
  withStyles,
  OutlinedTextFieldProps,
} from '@material-ui/core';

interface DatetimeInputProps {
  id: string;
  label: string;
  name: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomDatetimeInput = withStyles({
  root: {
    backgroundColor: '#232129',
    color: '#666360',
    borderRadius: '10px',
    padding: '8px',
    '& label': {
      color: '#666360',
      fontSize: '18px',
    },
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      color: '#666360',
      fontWeight: 'bold',
      '& fieldset': {
        border: 0,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      color: '#fff',
      fontWeight: 'bold',
      '& fieldset': {
        border: 0,
      },
    },
  },
})((props: OutlinedTextFieldProps) => <TextField type="date" {...props} />);

const DateInput: React.FC<DatetimeInputProps> = ({ id, label, name, value, onChange }) => {
  return (
    <CustomDatetimeInput
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default DateInput;
