import React, { useCallback, useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableUsers from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { DeleteButton } from '../Banner/styles';
import { IoIosTrash, IoMdKey, IoMdKeypad } from 'react-icons/io';
import { SimpleTable } from '../../components/SimpleTable';
import Button from '../../components/Button';
import {useDownloadExcel} from 'react-export-table-to-excel'


interface Data {
  id: string;
  code: number;
  name: string;
  email: string;
  phone: string;
  created_at: string;
}

interface IUsers {
  id: number;
  name: string;
  email: string;
  phone: string;
  created_at:string;
}

function createData(
  id: string,
  code: number,
  name: string,
  email: string,
  phone: string,
  created_at: string,
): Data {
  return { id, code, name, email, phone, created_at };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const Game = () => {
  const [rows, setRows] = useState<IUsers[]>([]);
  const [loading, setLoading] = useState(true);
  const headers = [
    { fieldName: 'code', label: 'ID' },
    { fieldName: 'name', label: 'Nome' },
    { fieldName: 'email', label: 'Email' },
    { fieldName: 'phone', label: 'Telefone' },
    {fieldName: 'created_at', label: 'Aceite LGPD'}
  ];

  useEffect(() => {
    api.get('/usersgame/').then(response => {
      setRows(response.data);
      setLoading(false);
      
    });
  }, []);

  const usersRows = useMemo(() => {
    return rows.map(row =>
      createData(row.id.toString(), row.id, row.name, row.email, row.phone, row.created_at),
    );
  }, [rows]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <SimpleTable data={usersRows} headers={headers} 
        // ref={}
        />
        {/* <TableUsers<Data, HeadCell[]>
          title="Usuários"
          headCells={headCells}
          rows={usersRows}
          orderByDefaultColumn="code"
          //   onCreate={onCreate}
          //   onDelete={onDelete}
          tableBodyCells={tableBodyCells}
          loading={loading}
        /> */}
      </Content>
    
    </StandardContainer>
  );
};

export default Game;

