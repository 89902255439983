import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import { BiFont } from 'react-icons/bi';
import Input from '../../../components/Input';
import { useToast } from '../../../hooks/toast';
import {
  Content
} from './styles';

interface LocationState {
  row: IVideo
}

interface IVideo {
  id: string,
  description: string,
  externalId: string,
  tumbnail: string,
  title: string,
  channel: string,
  uploadDate: Date,
  importDate: Date
}



const VideoEdit = () => {
  const { state } = useLocation<LocationState>();
  const [loading, setLoading] = useState(false);
  const [newsText, setNewsText] = useState('');

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});


        const schema = Yup.object().shape({
          description: Yup.string().optional(),
          title: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await api.patch(`/videos/${state.row.id}`, data);

        addToast({
          type: 'success',
          title: 'Video atualizado!',
        });

        history.push('/video');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro para alterar video',
          description: 'Tente mais tarde',
        });
      }
      setLoading(false);
    },
    [
      newsText
    ],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Alterar video</h1>
        <Form
          ref={formRef}
          initialData={
            {
              description: state.row.description,
              title: state.row.title
            }
          }
          onSubmit={handleSubmit}
        >
          <div>
            <Input name="title" icon={BiFont} label="Titulo do video" placeholder="" />
            <Input name="description" icon={BiFont} label="Descrição do video" placeholder="" />

            <Button type="submit" loading={loading}>Salvar</Button>
          </div>

        </Form>
      </Content>
    </StandardContainer>
  );
}

export default VideoEdit;
