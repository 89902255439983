import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import Header from '../../../components/Header';
import StandardContainer from '../../../components/StandardContainer';
import { Content, EditorTitle } from './styles';
import { MdComputer, MdConfirmationNumber, MdSearch } from 'react-icons/md';
import Select from '../../../components/Select';
import { Editor } from '@tinymce/tinymce-react';
import { chatsOptions, screenOptions, typeOptions } from '../chat.consts';

interface ChatMessageFormData {
  message: string;
  type: string;
  area: string;
  orderNumber: number;
}

interface OptionDTO {
  value: string;
  label: string;
}

interface ChatMessageData {
  id: number
  message: string;
  type: string;
  area: string;
  screen?: string;
  orderNumber: number;
}

interface LocationState {
  id: string;
};



const EditChat = () => {
  const [chat, setChat] = useState<ChatMessageData>();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const editorRef = useRef<any>(null);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState<string | undefined>("");
  const [countChars, setCountChars] = useState(0);
  const MAX_CHARS = 900

  const getCountChars = (): number => {
    const rawContent = editorRef.current.getContent({ format: 'raw' }).replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, "").trim()
    return rawContent.length
  }

  useEffect(() => {
    setType(chat?.type)
  }, [chat])

  const saveText = () => {    
    if (editorRef.current) {
      setMessage(editorRef.current.getContent());
    }
  };

  const handleSubmit = async (data: ChatMessageFormData) => {

      data.message = message
      if (!isNaN(data.orderNumber)){
        data.orderNumber = Number(data.orderNumber)
      }

      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          area: Yup.string().required(),
          type: Yup.string().required(),
          orderNumber: Yup.number().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/chats/${state.id}`, data);

        history.push('/chats', { state: { area: data.area }});

        addToast({
          type: 'success',
          title: 'Mensagem cadastrada com sucesso',
        });
      } catch (err) {
        console.log(err)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar mensagem do chat',
          description: 'Ocorreu um erro ao cadastrar a mensagem do chat, tente novamente.',
        });
      }
      setLoading(false);
    }

  const { state } = useLocation<LocationState>();

  useEffect(() => {
    api.get(`/chats/${state.id}`).then(response => {
      console.log(response.data)
      setChat(response.data)
      setMessage(response.data?.message)
    });
  }, [state.id]);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <h1>Editar mensagem do Chat</h1>
        <Form
          ref={formRef}
          initialData={{
            message: chat?.message,
            type: chat?.type,
            area: chat?.area,
            screen: chat?.screen,
            orderNumber: chat?.orderNumber
          }}
          onSubmit={handleSubmit}
        >
          <div>    
            <Select
                name="area"
                options={chatsOptions}
                label="Escolha o Chat"
                style={{ marginBottom: '10px' }}
                onChange={e => console.log(e.target)}
                defaultOption={chat?.area}
              />      
              <Select
                name="type"
                options={typeOptions}
                label="Tipo da mensagem"
                style={{ marginBottom: '10px' }}
                onChange={e => setType(e.target.value)}
                defaultOption={chat?.type}                
              />

              { type == "A12_BUTTON_SCREEN" &&
                <>
                  <Input name="message" icon={MdConfirmationNumber} label="Texto do botão" />
                  <Select
                    name="screen"
                    options={screenOptions}
                    label="Tela de destino no App"
                    style={{ marginBottom: '10px' }}
                    onChange={e => console.log(e.target.value)}
                    defaultOption={chat?.screen}                
                  />
                </>
              }

              { type != "A12_BUTTON_SCREEN" &&
                <>
                  <EditorTitle>Mensagem:</EditorTitle>
                  <Editor
                  onInit={(evt, editor) => editorRef.current = editor}
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  onBlur={saveText}
                  init={{
                    width: "100%",
                    height: 300,
                    plugins: [
                      'autolink link charmap preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'paste wordcount'
                    ],
                    toolbar_mode: 'sliding',
                    automatic_uploads: true,
                    image_title: true,
                    file_picker_types: 'image',
                    font_family_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n',
                    font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                    menubar: 'file edit view insert format tools table tc help',
                    toolbar: 'fontfamily fontsize | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',                
                    setup: function (ed) {
                      var allowedKeys = [8, 37, 38, 39, 40, 46, 16, 17, 18]; // backspace, delete and cursor keys, shift, ctrl, alt
                      ed.on('keydown', function (e) {
                        if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
                        if (getCountChars() + 1 > MAX_CHARS) {
                          e.preventDefault();
                          e.stopPropagation();
                          return false;
                        }
                        return true;
                      });
                      ed.on('keyup', function (e) {
                        setCountChars(getCountChars())
                      });
                    },
                    init_instance_callback: function () { // initialize counter div
                      setCountChars(getCountChars())
                    },

                  }}
                  initialValue={message}
                />
                <span>{countChars} de {MAX_CHARS}</span>
              </>
            }
            <Input name="orderNumber" icon={MdConfirmationNumber} label="Ordem" />
            <Button type="submit" loading={loading}>Salvar</Button>
          </div>
        </Form>
      </Content>
    </StandardContainer>
  )
}

export default EditChat;
