import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';

import TableToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface IToolbar {
  title: string;
  numSelected: number;
  topSelectedFeatures?: ReactNode;
  topFeatures?: ReactNode;
};

const Toolbar: React.FC<IToolbar> = ({
  title,
  numSelected,
  topSelectedFeatures,
  topFeatures,
}) => {
  const classes = useToolbarStyles();

  return (
    <TableToolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          Item {numSelected} selecionado
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
      {numSelected > 0 ? (
        topSelectedFeatures && topSelectedFeatures
      ) : (
        topFeatures && topFeatures
      )}
    </TableToolbar>
  );
}

export default Toolbar;
