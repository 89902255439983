import React from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

type Order = 'asc' | 'desc';

interface IHeader {
  headCells: any;
  order: Order;
  orderBy: string;
  visuallyHidden: string;
  onCreateSortHandler: any;
};

const Header: React.FC<IHeader> = ({
  headCells,
  order,
  orderBy,
  visuallyHidden,
  onCreateSortHandler,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onCreateSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default Header;
