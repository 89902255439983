import React, { useState } from 'react';

import usePermission from '../../hooks/usePermission';
import { PermissionsEnum } from '../../utils/PermissionsEnum';

import {
  Container,
  Content,
  StyledLink,
  MyReport,
  HeadLink,
  ContainerSubmenu,
} from './styles';

const Submenu: React.FC = () => {

  const [isOpen, setOpen] = useState<boolean>( (window.location.href.indexOf("reports") >= 0) ? true : false )

  const toggleSubmenu = () => {
    setOpen(!isOpen)
  }

  const hasPermissionReport = usePermission(PermissionsEnum.REPORT) ;
  const isAdmin = usePermission(PermissionsEnum.ADMIN) ;
  const enable = isAdmin || hasPermissionReport

  return (
    <Container>
      <Content>

        <HeadLink onClick={toggleSubmenu} hasPermission={enable}>
          <MyReport size={18} />
          Relatórios
        </HeadLink>

        <ContainerSubmenu show={isOpen}>
          <StyledLink to="/reports/geolocation-map" hasPermission={enable}>
            <MyReport size={18} />
            Mapa de usuários
          </StyledLink>
          <StyledLink to="/reports/report-access" hasPermission={enable}>
            <MyReport size={18} />  
            Acessos ao app/hora
          </StyledLink>
          <StyledLink to="/reports/report-access-day" hasPermission={enable}>
            <MyReport size={18} />  
            Acessos ao app/dia
          </StyledLink>
          <StyledLink to="/reports/report-time-screen" hasPermission={enable}>
            <MyReport size={18} />
            Tempo por tela
          </StyledLink>
          <StyledLink to="/reports/report-content-type" hasPermission={enable}>
            <MyReport size={18} />
            Pessoas por conteúdo
          </StyledLink>
          <StyledLink to="/reports/health-check" hasPermission={enable}>
            <MyReport size={18} />
            Health check
          </StyledLink>
          <StyledLink to="/reports/videos" hasPermission={enable}>
            <MyReport size={18} />
            Aparecida Play - videos
          </StyledLink>
          <StyledLink to="/reports/playlist" hasPermission={enable}>
            <MyReport size={18} />
            Aparecida Play - playlist
          </StyledLink>
          <StyledLink to="/reports/channel" hasPermission={enable}>
            <MyReport size={18} />
            Aparecida Play - canais
          </StyledLink>
          <StyledLink to="/reports/areas" hasPermission={enable}>
            <MyReport size={18} />
            Acesso por area
          </StyledLink>
          <StyledLink to="/reports/banners" hasPermission={enable}>
            <MyReport size={18} />
            Clicks por banner
          </StyledLink>
          <StyledLink to="/reports/cameras" hasPermission={enable}>
            <MyReport size={18} />
            Acesso por câmera
          </StyledLink>
        </ContainerSubmenu>

      </Content>
    </Container>
  );
};

export default Submenu;
