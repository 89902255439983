import styled from 'styled-components/macro';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;

  form {
    margin: 40px 0;
    width: 100%;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
