import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoIosCloudDownload, IoIosInformationCircle } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableChannel from '../../components/Table';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { ImportButton } from '../Banner/styles';
import { Content } from './styles';

interface Data {
  id: string;
  code: number;
  name: string;
  description: string;
  isEnable: boolean;
}

interface IVideo {
  id: number;
  name: string;
  description: string;
  isEnable: boolean;
}

function createData(
  id: string,
  code: number,
  name: string,
  description: string,
  isEnable: boolean,
): Data {
  return { id, code, name, description, isEnable };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'isEnable', numeric: false, disablePadding: false, label: 'Ativado' },
  { id: 'isEnable', numeric: false, disablePadding: false, label: 'Ações' }
];

export const CronJobList = () => {
  const [rows, setRows] = useState<Data[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();


  useEffect(() => {
  }, []);

  useEffect(() => {
    getData();
  }, [deleted]);



  const getData = useCallback(async () => {
    const { data } = await api.get(`/jobs`)

    setRows(data);
    setLoading(false);
  }, [rows])

  const onEdit = useCallback((row) => {

  }, [history]);

  const cronRows = useMemo(() => {

    return rows.map(item => createData(item.id, Number(item.id), item.name, item.description, item.isEnable));

  }, [rows]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.description}</TableCell>
      <TableCell align="left"><Switch
        checked={row.isEnable}
        onChange={async () => {
          const id = row.id;
          api.patch(`/jobs/${id}/switch`)
            .then((result) => {
              const row = rows.find(item => item.id == id)
              if (row) {
                row.isEnable = !row.isEnable;

                setRows([...rows])
              }
            })
            .catch((err) => {
              addToast({
                type: 'error',
                title: `Erro ao ${row.isEnable ? 'desativar' : 'ativar'} cron job`,
                description: 'Tente novamente mais tarde :(',
              })
            })
        }}
      /></TableCell>
      <TableCell align="left">
        <ImportButton onClick={() => { history.push({ pathname: '/jobs/cron', state: { job: { name: row.description, id: row.id } } }) }}>Crons</ImportButton>
      </TableCell>

    </>
  ), [rows]);


  return (
    <>
      <StandardContainer>
        <Header />
        <Content>
          <TableChannel<Data, HeadCell[]>
            title='Jobs schedulers'
            headCells={headCells}
            rows={cronRows}
            tableBodyCells={tableBodyCells}
            loading={loading}
          />
        </Content>
      </StandardContainer>
    </>
  )
}

