import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import Tooltip from '../Tooltip';

export const ColorPicker = styled(ChromePicker)`
  width: 100%!important;
  height: 300px!important;
  border-radius: 10px;
`;

export const Error = styled(Tooltip)`
  margin-left: 0.25rem;
  padding-bottom: 1rem;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030
        transparent;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  p {
      margin-top: 24px;
      margin-bottom: 14px;
      color: #f4ede8;
      font-size: 16px;
    }
`;
