import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableCamera from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  name: string;
  android_url: string;
  ios_url: string;
  youtube_url: string;
  active: boolean;
  is_default: boolean;
  is_schedule: boolean;
  icon_url: string;
  autoplay: string;
}

interface ICamera {
  id: number;
  name: string;
  android_url: string;
  ios_url: string;
  youtube_url: string;
  active: boolean;
  is_default: boolean;
  is_schedule: boolean;
  icon_url: string;
  autoplay: string;
}

function createData(
  id: string,
  code: number,
  name: string,
  android_url: string,
  ios_url: string,
  youtube_url: string,
  active: boolean,
  is_default: boolean,
  is_schedule: boolean,
  icon_url: string,
  autoplay: string
): Data {
  return { id, code, name, android_url, ios_url, youtube_url, active, is_default, is_schedule, icon_url, autoplay };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'android_url', numeric: false, disablePadding: false, label: 'URL m3u8' },
  { id: 'ios_url', numeric: false, disablePadding: false, label: 'URL JMV' },
  { id: 'youtube_url', numeric: false, disablePadding: false, label: 'URL Youtube' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'is_default', numeric: false, disablePadding: false, label: 'Padrão' },
  { id: 'is_schedule', numeric: false, disablePadding: false, label: 'Agendado' },
  { id: 'icon_url', numeric: false, disablePadding: false, label: 'Imagem' },
  { id: 'autoplay', numeric: false, disablePadding: false, label: 'Autoplay' },
];

const Camera = () => {
  const [rows, setRows] = useState<ICamera[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true)

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/cameras/').then(response => {
      setRows(response.data);
      setLoading(false)
    });
  }, [deleted]);

  const onDelete = useCallback((id) => {
    api.delete(`/cameras/${id}`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Câmera deletada com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast])

  const cameraRows = useMemo(() => {
    return rows.map(row => createData(row.id.toString(), row.id, row.name, row.android_url, row.ios_url, row.youtube_url, row.active, row.is_default, row.is_schedule, row.icon_url, row.autoplay));
  }, [rows]);

  const onUpload = useCallback((row) => {
    history.push('/camera/upload', { id: row.code });
  }, [history]);

  const onCreate = useCallback(() => {
    history.push('/camera/new');
  }, [history]);

  const onEdit = useCallback((row) => {
    history.push('/camera/edit', { id: row.code });
  }, [history]);

  const onSchedule = useCallback((row) => {
    history.push('/camera/schedule', { id: row.code });
  }, [history]);

  const onCancelSchedule = useCallback((row) => {
    api.delete(`/cameras/${row.code}/schedule/cancel`).then(response => {
      setDeleted(!deleted);
      addToast({
        type: 'success',
        title: 'Agendamento cancelado com sucesso!',
      });
    });
  }, [setDeleted, deleted, addToast]);

  const tableBodyCells = useCallback((isItemSelected, labelId, row) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.id}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left"><a href={row.android_url !== '' && row.android_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.android_url?.substring(0, 12)}...</a></TableCell>
      <TableCell align="left"><a href={row.ios_url !== '' && row.ios_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.ios_url?.substring(0, 12)}...</a></TableCell>
      <TableCell align="left"><a href={row.youtube_url !== '' && row.youtube_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>{row.youtube_url?.substring(0, 12)}...</a></TableCell>
      <TableCell align="left">{row.active ? 'Ativo' : 'Inativo'}</TableCell>
      <TableCell align="left">{row.is_default ? 'Sim' : 'Não'}</TableCell>
      <TableCell align="left">{row.is_schedule ? 'Sim' : 'Não'}</TableCell>
      <TableCell align="left"><a href={row.icon_url} target="_blank" rel="noopener noreferrer" style={{ color: '#008FC3' }}>Abrir imagem</a></TableCell>
      <TableCell align="left">{row.autoplay}</TableCell>
    </>
  ), []);

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableCamera<Data, HeadCell[]>
          title='Câmera'
          headCells={headCells}
          rows={cameraRows}
          orderByDefaultColumn='code'
          onCreate={onCreate}
          onDelete={onDelete}
          onUpload={onUpload}
          onEdit={onEdit}
          onSchedule={onSchedule}
          onCancelSchedule={onCancelSchedule}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  )
}

export default Camera;
