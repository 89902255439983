import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosCloudDownload, IoIosInformationCircle } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableChannel from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { ImportButton } from '../Banner/styles';
import {
  ContainerImportTasks,
  Content,
  LoadingOverlayContainer,
  ImportButtonCancel,
} from './styles';
import Pikaday from 'pikaday';
import moment from 'moment';
import { da } from 'date-fns/locale';
import format from 'date-fns/format';
import { Box, Chip, Modal, Divider } from '@material-ui/core';
import Loading from '../../components/Loading';
import CheckboxComponent from '../../components/Checkbox';

interface Data {
  id: string;
  code: number;
  name: string;
  externalId: string;
  enableImportButton: boolean;
  actions: string;
  image_url: string;
  priority: number;
  active: string;
}

interface IVideo {
  id: number;
  name: string;
  externalId: string;
  enableImportButton: boolean;
  image_url: string;
  actions: string;
  priority: number;
  active: string;
}

interface IChannel {
  id: number;
  name: string;
  externalId: string;
  enableImportButton: boolean;
  image_url: string;
  priority: number;
  active: string;
}

function createData(
  id: string,
  code: number,
  name: string,
  externalId: string,
  enableImportButton: boolean,
  actions: string,
  image_url: string,
  active: string,
  priority?: number,
): Data {
  if (!priority) {
    priority = 0 as number;
  }
  return {
    id,
    code,
    name,
    externalId,
    actions,
    image_url,
    enableImportButton,
    priority,
    active,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'externalId', numeric: false, disablePadding: false, label: 'YT ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'image_url', numeric: false, disablePadding: false, label: 'Capa' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Ações' },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'Prioridade',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'Ativo',
  },
];

const ChannelList = () => {
  const [newsRows, setNewsRows] = useState<Data[]>([]);
  const [deleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [dataImportTasks, setDataImportTasks] = useState<any>();
  const [loadingImport, setLoadingImport] = useState(false);
  const [dataImportTasksChangeStatus, setDataImportTasksChangeStatus] =
    useState<any[]>([]);
  const [isCheckedActive, setIsCheckedActive] = useState(false);

  let statusColor;

  let datepicker: Pikaday;

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {}, []);

  useEffect(() => {
    getData();
  }, [deleted]);

  const getData = useCallback(async () => {
    //const { data } = await api.get(`/videos/channel/all-by-permission`)
    const { data } = await api.get(`/videos/channel/all`);
    console.log(data, 'data');
    for (const channel of data) {
      const responseTask = await api.get(`/videos/channel/${channel.id}/tasks`);
      const isEnable =
        !responseTask.data || responseTask.data.actual.status != 'IN_PROGRESS';
      // console.log(isEnable);
      channel.enableImportButton = isEnable;
    }

    setNewsRows(
      data.map((channel: IVideo) =>
        createData(
          channel.id.toString(),
          channel.id,
          channel.name,
          channel.externalId,
          channel.enableImportButton,
          channel.actions,
          channel.image_url,
          channel.active,
          channel.priority,
        ),
      ),
    );
    setLoading(false);
  }, [newsRows]);

  // const newsRows = useMemo(() => {
  //   return rows.map(row => createData(row.id.toString(), row.id, row.description, row.externalId,
  //     row.title, row.channel, row.channelName, row.uploadDate, row.importDate, row.priority));
  // }, [rows]);

  const onUpload  = useCallback(
    row => {
      history.push('/channel/upload', { channelId: row.id });
    },
    [history],
  );

  const onEdit = useCallback(
    row => {
      history.push('/channel/:id', { id: row.code});
    },
    [history],
  );

  const handleChangePosition = useCallback(
    async row => {
      Swal.fire({
        title: `Deseja alterar a prioridade da playlist ${row.title}?`,
        text: 'A prioridade é utilizada para definir a ordem de exibição das playlists',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        showLoaderOnConfirm: true,
        inputAttributes: {
          min: '1',
        },
        preConfirm: priority => {
          return api
            .patch(`/videos/playlist/${row.id}/position`, { priority })
            .then(response => {
              addToast({
                type: 'success',
                title: 'Prioridade alterada com sucesso!',
              });
              getData();
            })
            .catch(error => {
              addToast({
                type: 'error',
                title: 'Erro ao alterar prioridade!',
                description:
                  'Ocorreu um erro ao alterar a prioridade da playlist, tente novamente mais tarde.',
              });
            });
        },
      });
    },
    [newsRows],
  );

  const onImport = useCallback(
    async (row: any, dateFrom?: string) => {
      const { externalId } = row;
      try {
        await api.post(`/videos/import`, {
          externalId,
          dateFrom,
        });

        addToast({
          title: 'Importação iniciada',
          type: 'success',
          description: `Processo de importação do canal ${row.name} foi iniciado com sucesso! \n Dentro de alguns minutos será finalizado`,
        });

        const rowItem = newsRows.filter(item => item.code == row.id)[0];
        // console.log(row);
        // console.log('here: ', rowItem);
        rowItem.enableImportButton = false;
        setNewsRows([...newsRows]);

        poolingTask(row.id);
      } catch (error) {
        console.error(error);
        addToast({
          title: 'Erro na importação',
          type: 'error',
          description: `Não foi possível iniciar a importação do canal ${row.name}`,
        });
      }
    },
    [newsRows],
  );

  const poolingTask = async (channelId: number) => {
    const responseTask = await api.get(`/videos/channel/${channelId}/tasks`);

    const { actual, before } = responseTask.data;

    if (actual == undefined || actual.status == 'IN_PROGRESS') {
      setTimeout(async () => {
        poolingTask(channelId);
      }, 10 * 1000); //10 seconds
    } else {
      const isSuccess = actual.status == 'DONE';

      Swal.fire({
        title: isSuccess
          ? 'Importação finalizada'
          : 'Importação finalizada com erros',
        html: `<p>${buildMessage(actual)}</p>
              ${
                actual.status != 'IN_PROGRESS' && before
                  ? `
              </br>
              <b>Importação Anterior: </b>
              <p>${buildMessage(before)}</p>`
                  : ''
              }
        `,
        icon: isSuccess ? 'success' : 'error',
      });
    }
  };

  const buildMessage = (taskData: any) => {
    if (!taskData) {
      return '';
    }

    return `
    <p>Total de videos importados: ${taskData.totalImportedVideos} </p>
    <p>Total de playlists importados: ${taskData.totalImportedPlaylists} </p>`;
  };

  const openModalToFireImport = (row: any) => {
    Swal.fire({
      title: 'Importa a partir de qual data?',
      input: 'text',
      inputPlaceholder: 'Selecione a data',
      inputAttributes: {
        readonly: 'readonly',
      },
      showCloseButton: true,
      allowOutsideClick: true,

      preConfirm: () => {
        return datepicker.getDate();
      },
      didOpen: () => {
        datepicker = new Pikaday({
          field: Swal.getInput(),
          maxDate: moment().startOf('day').subtract(1, 'days').toDate(),
          format: 'DD/MM/YYYY',
        });
      },
      didClose: () => {
        datepicker.destroy();
      },
    }).then(result => {
      setLoadingImport(true);
      if (result.isConfirmed) {
        const date = result.value;

        const dateUtc = date ? moment(date).endOf('day').utc() : undefined;
        onImport(row, dateUtc?.startOf('day').toISOString())
          .then(() => {
            setLoadingImport(false);
          })
          .catch(error => {
            setLoadingImport(false);
            console.log(error, 'error');
          });
      }
    });
  };

  const openTaskModal = useCallback(
    async row => {
      const responseTask = await api.get(`/videos/channel/${row.id}/tasks`);

      const { data } = responseTask;

      if (!data) {
        Swal.fire({
          title: 'Sem Dados de importação',
          text: `Não foi encontrado nenhum dado de importação para o canal ${row.name}`,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Importar',
          showCancelButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            openModalToFireImport(row);
          }
        });

        return;
      }

      const { actual, before } = data;

      const isRunning = actual.status == 'IN_PROGRESS';

      // console.log('isRunning: ', isRunning);

      Swal.fire({
        title: `${row.name}`,
        html: `<p>${buildMessage(actual)}</p>
            ${
              before
                ? `
            </br>
            <b>Importação Anterior: </b>
            <p>${buildMessage(before)}</p>`
                : ''
            }
      `,
        showCancelButton: true,
        confirmButtonColor: isRunning ? '#AAAAAA' : '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Importar',
        cancelButtonText: 'Fechar',
      }).then(result => {
        if (result.isConfirmed && !isRunning) {
          openModalToFireImport(row);
        }

        if (result.isConfirmed && isRunning) {
          Swal.fire({
            icon: 'error',
            title: 'Importação em andamento',
            html: `<p>A importação do canal <b>${row.name}</b> está em andamento, aguarde alguns minutos para que seja finalizada</p>`,
          });
        }
      });
    },
    [newsRows],
  );

  const openImportsTaskModal = useCallback(
    async row => {
      const responseImportsTask = await api.get(
        `/videos/import-tasks/${row.id}`,
      );

      const { data } = responseImportsTask;

      if (!data) {
        Swal.fire({
          title: 'Sem Dados de importação',
          text: `Não foi encontrado nenhum dado de importação para o canal ${row.name}`,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Importar',
          showCancelButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            openModalToFireImport(row);
          }
        });

        return;
      }

      setShowModal(true);
      setDataImportTasks(data);
    },
    [newsRows],
  );

  const buttonChangeStatus = (
    index: number,
    taskId: string,
    taskStatus: string,
  ) => {
    console.log(taskId, 'taskid');
    console.log(taskStatus, 'taskStatus');
    api
      .put(`/videos/channel/task/${taskId}`, { status: taskStatus })
      .then(() => {
        //código que vc fez no front
        console.log(taskId, 'id');
        const updatedDataImportTasksChangeStatus = [...dataImportTasks];
        updatedDataImportTasksChangeStatus[index].status = 'ERROR'; // muda o status para 'ERROR'
        setDataImportTasks(updatedDataImportTasksChangeStatus); // atualiza o estado
      })
      .catch(error => {
        addToast({
          title: 'Erro ao atualizar status da tarefa',
          type: 'error',
          description: error.message,
        });
      });
  };

  const tableBodyCells = useCallback(
    (isItemSelected, labelId, row) => (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.externalId}</TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">
          <a
            href={row.image_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#008FC3' }}
          >
            Abrir imagem
          </a>
        </TableCell>
        <TableCell align="left">
          <ImportButton onClick={() => openTaskModal(row)}>
            <IoIosInformationCircle />
          </ImportButton>
        </TableCell>
        {/* <TableCell align="left">{row.priority}</TableCell> */}
        <TableCell align="left">
          <div style={{ display: 'flex' }}>
            <ImportButton
              style={{ width: '40px' }}
              onClick={() => handleChangePosition(row)}
            >
              {row.priority}
            </ImportButton>
          </div>
        </TableCell>
        <TableCell align="left">{row.active}</TableCell>
        {/* <TableCell align="left">{row.active} */}
        {/* <div style={{ marginTop: '15px' }}>
          
          <TableCell padding="checkbox">
            <CheckboxComponent
              name="checkedActive"
              checkboxName="Ativo"
              checked={isCheckedActive}
              onChecked={() => setIsCheckedActive(!isCheckedActive)}
            />
          </TableCell>
        </div> */}
        <TableCell align="left">
          <ImportButton onClick={() => openImportsTaskModal(row)}>
            Todas Importações
          </ImportButton>
        </TableCell>
        <TableCell align="left">
          <ImportButton
            onClick={() => {
              history.push({
                pathname: '/playlist',
                state: { channel: row.externalId },
              });
            }}
          >
            Playlists
          </ImportButton>
        </TableCell>
      </>
    ),
    [newsRows],
  );

  useEffect(() => {
    console.log(dataImportTasks);
  }, [dataImportTasks]);

  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/npm/pikaday/css/pikaday.css"
      ></link>
      <StandardContainer>
        <Header />
        <Content>
          <LoadingOverlayContainer>
            {loadingImport && <Loading />}
            <TableChannel<Data, HeadCell[]>
              title="Canais do Youtube"
              headCells={headCells}
              rows={newsRows.sort((a, b) => (a.priority > b.priority ? 1 : -1))}
              onUpload={onUpload}
              onEdit={onEdit}
              tableBodyCells={tableBodyCells}
              loading={loading}
            />
          </LoadingOverlayContainer>
        </Content>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '50%',
              height: '80%',
              overflow: 'auto',
              bgcolor: '#312E38',
              padding: '50px 100px',
            }}
          >
            {dataImportTasks &&
              dataImportTasks.map((task: any, index: number) => {
                const date_init = `${new Date(
                  task.date_init,
                ).toLocaleDateString()} - ${new Date(
                  task.date_init,
                ).toLocaleTimeString()}`;
                const date_end = `${new Date(
                  task.date_end,
                ).toLocaleDateString()} - ${new Date(
                  task.date_end,
                ).toLocaleTimeString()}`;

                // console.log(task.status)

                if (task.status == 'DONE') statusColor = '#4ead13';
                else if (task.status == 'ERROR') statusColor = '#a31e1c';
                else statusColor = '#a39b07';

                return (
                  <>
                    <ContainerImportTasks>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h2>{task.channel_name}</h2>
                        <Chip
                          variant="outlined"
                          size="medium"
                          label={task.status}
                          style={{
                            color: statusColor,
                            borderColor: statusColor,
                            marginLeft: 10,
                          }}
                        />
                        {task.status !== 'DONE' && task.status !== 'ERROR' ? (
                          <ImportButtonCancel
                            onClick={() =>
                              buttonChangeStatus(index, task.id, task.status)
                            }
                          >
                            Cancelar
                          </ImportButtonCancel>
                        ) : (
                          ''
                        )}
                      </div>
                      <i>Ínicio: {date_init}</i> |{' '}
                      <i>Fim: {date_end ? date_end : 'Indefinida'}</i>
                      <br />
                      <b>
                        Playlists importadas: {task.total_playlists_imported}
                      </b>
                    </ContainerImportTasks>
                    <Divider />
                  </>
                );
              })}
          </Box>
        </Modal>
      </StandardContainer>
    </>
  );
};

export default ChannelList;
