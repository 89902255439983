import React, { useEffect } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Sidebar from '../Sidebar';

import {
  Container,
  Content,
  Toggle,
} from './styles';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const StandardContainer: React.FC = ({ ...components }) => {
  const [toggle, setToggle] = React.useState(true);
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    windowDimensions.width <= 1024
    ? setToggle(false)
    : setToggle(true);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <Container toggle={toggle}>
      <Sidebar toggle={toggle} />
      <Toggle onClick={() => setToggle(!toggle)} toggle={toggle}>
        { toggle
          ?
          ( <FiChevronLeft size={26} /> )
          :
          ( <FiChevronRight size={26} /> )
        }
      </Toggle>
      <div id="content">
        <Content toggle={toggle}>{components.children}</Content>
      </div>
    </Container>
  )
}

export default StandardContainer;
