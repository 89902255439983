import React, { useCallback, useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import {
  ChartContainer,
  Content,
  NoDataMessage,
  ReorderButton,
} from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import BasicTable from '../../components/Table';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '../../components/Button';
import Select from '../../components/Select';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import DatetimeInput from '../../components/DatetimeInput';
import moment from 'moment';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { Modal, Paper } from '@material-ui/core';

interface Data {
  id: string;
  code: number;
  area: string;
  type: string;
  message: string;
  orderNumber: number;
  amountUsers: number;
  percentageUsers: number;
}

interface IChat {
  id: number;
  area: string;
  type: string;
  message: string;
  orderNumber: number;
  amountUsers: number;
  percentageUsers: number;
}

function createData(
  id: string,
  code: number,
  area: string,
  message: string,
  type: string,
  orderNumber: number,
  amountUsers: number,
  percentageUsers: number,
): Data {
  return {
    id,
    code,
    area,
    message,
    type,
    orderNumber,
    amountUsers,
    percentageUsers,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'area', numeric: true, disablePadding: true, label: 'Área' },
  { id: 'message', numeric: true, disablePadding: true, label: 'Mensagem' },
  { id: 'type', numeric: true, disablePadding: true, label: 'Tipo' },
  { id: 'orderNumber', numeric: true, disablePadding: true, label: 'Ordem' },
  {
    id: 'amountUsers',
    numeric: true,
    disablePadding: true,
    label: '# Usuários',
  },
  { id: 'percentageUsers', numeric: true, disablePadding: true, label: '%' },
];

const ChatAreas = new Map<string, string>([
  ['DEVOUT_MISSIONARY', 'Devoto Missionário'],
  ['YOUNG_OF_MARY', 'Jovem de Maria'],
  ['CHILD_DEVOTEE', 'Devoto Mirim'],
]);

const ChatTypes = new Map<string, string>([
  ['A12_INPUT_ADDRESS_ADDITIONAL', 'Complemento do Endereço'],
  ['A12_INPUT_ADDRESS_NEIGHBORHOOD', 'Bairro'],
  ['A12_INPUT_ADDRESS_NUMBER', 'Número da casa'],
  ['A12_INPUT_BIRTH_DATE', 'Data de Nascimento'],
  ['A12_INPUT_POSTAL_CODE', 'CEP'],
  ['A12_INPUT_CPF', 'CPF'],
  ['A12_INPUT_PHONE', 'Telefone'],
  ['A12_INPUT_NAME', 'Nome Completo'],
  ['A12_INPUT_EMAIL', 'E-mail'],
  ['A12_MESSAGE', 'Mensagem de texto para o usuário'],
  ['A12_SELECT_YES_NO', 'Aceite LGPD'],
  ['A12_SELECT_GENDER', 'Gênero'],
  ['A12_SELECT_RADIO_BUTTON', 'Origem do cadastro'],
  ['A12_SELECT_CONFIRM_ADDRESS', 'Confirmação do endereço'],
  ['A12_BUTTON_SCREEN', 'Botão para direcionar para uma tela'],
  ['A12_INPUT_BIRTH_DATE_CHILD', 'Data de Nascimento da criança'],
  ['A12_INPUT_CPF_CHILD', 'CPF da criança'],
  ['A12_INPUT_PHONE_CHILD', 'Telefone da criança'],
  ['A12_INPUT_NAME_CHILD', 'Nome Completo da Criança'],
  ['A12_INPUT_EMAIL_CHILD', 'E-mail da criança'],
  ['A12_SELECT_YES_NO_CHILD_EMAIL', 'Confirmação de e-mail da criança'],
  ['A12_SELECT_YES_NO_CHILD_PHONE', 'Confirmação de telefone da criança'],
  ['A12_SELECT_YES_NO_CHILD_CPF', 'Confirmação de CPF da criança'],
]);

interface IParams {
  state: {
    area: string;
  };
}

const Chat = () => {
  const [rows, setRows] = useState<IChat[]>([]);
  const [amountDevotees, setAmountDevotees] = useState<string>('');
  const [devoteesValidatedEmail, setDevoteesValidatedEmail] =
    useState<string>('');
  const [devoteesValidatedSms, setDevoteesValidatedSms] = useState<string>('');
  const [devoteesNotValidated, setDevoteesNotValidated] = useState<string>('');
  const [deleted, setDeleted] = useState(false);
  const [showChartModal, setShowChartModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { addToast } = useToast();

  const history = useHistory();
  const { state }: { state: IParams } = useLocation();

  // console.log(state);

  const formRef = useRef<FormHandles>(null);
  const [selectedChat, setSelectedChat] = useState<string>(
    state?.state?.area ? state?.state?.area : 'all',
  );
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm'),
  ); //7 dias atrás
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm'),
  ); //até a data/hora atual

  const [dataChartAccessByDay, setDataChartAccessByDay] = useState([]);

  const [chatAreas, setChatAreas] = useState<
    { label: string; value: string }[]
  >([
    { value: 'DEVOUT_MISSIONARY', label: 'Devoto Missionário' },
    { value: 'YOUNG_OF_MARY', label: 'Jovem de Maria' },
    { value: 'CHILD_DEVOTEE', label: 'Devoto Mirim' },
    { value: 'all', label: 'Todos' },
  ]);

  const handleSelectedChat = useCallback((data: NamedNodeMap) => {
    const [, id] = data[2].nodeValue!.split('chat');
    // console.log(id);
    setSelectedChat(id);
  }, []);

  const handleSubmitFilter = () => {
    console.log('filtrou');

    if (!selectedChat) {
      addToast({
        type: 'error',
        title: 'Valores inválidos',
        description: 'Selecione um chat para filtrar',
      });

      return false;
    }

    getInfosDevotees(selectedChat, selectedStartDate, selectedEndDate);
    getDataChart(selectedChat, selectedStartDate, selectedEndDate);
    getRows(selectedChat, selectedStartDate, selectedEndDate);
  };

  const getDataChart = useCallback(
    async (area = 'all', selectedStartDate, selectedEndDate) => {
      let startDate: string = moment(selectedStartDate).add(3, 'hours').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let endDate: string = moment(selectedEndDate).add(3, 'hours').format(
        'YYYY-MM-DD HH:mm:ss',
      );

      const response = await api.get(`/chats/analysis/access-by-day`, {
        params: {
          initDate: startDate,
          endDate: endDate,
          chatType: area,
        },
      });

      setDataChartAccessByDay(response.data);
    },
    [],
  );

  useEffect(() => {
    getDataChart(selectedChat, selectedStartDate, selectedEndDate);
    getInfosDevotees(selectedChat, selectedStartDate, selectedEndDate);
  }, []);

  const getRows = useCallback(
    async (area = 'all', selectedStartDate, selectedEndDate) => {
      setLoading(true);
      let startDate: string = moment(selectedStartDate).add(3, 'hours').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let endDate: string = moment(selectedEndDate).add(3, 'hours').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      const response = await api.get(
        `/chats/filter-by-area/` +
          area +
          '?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
      );
      setRows(response.data);
      setLoading(false);
    },
    [rows, loading],
  );

  const downloadCVSData = async () => {
    api.get('/chats/export/csv-file').then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    getRows(selectedChat, selectedStartDate, selectedEndDate);
  }, [deleted]);

  const onDelete = useCallback(
    id => {
      api.delete(`/chats/${id}`).then(response => {
        setDeleted(!deleted);
        addToast({
          type: 'success',
          title: 'Mensagem deletada com sucesso!',
        });
      });
    },
    [setDeleted, deleted, addToast],
  );

  const chatRows = useMemo(() => {
    return rows.map(row =>
      createData(
        row.id.toString(),
        row.id,
        row.area,
        row.message,
        row.type,
        row.orderNumber,
        row.amountUsers,
        row.percentageUsers,
      ),
    );
  }, [rows]);

  const onCreate = useCallback(() => {
    history.push('/chats/new');
  }, [history]);

  const onEdit = useCallback(
    row => {
      history.push('/chats/edit', { id: row.code });
    },
    [history],
  );

  const handleChangePosition = async (
    id: string,
    isUp: boolean,
    area: string,
  ) => {
    api
      .put(`/chats/${id}/position`, { isUp: isUp })
      .then(newRows => {
        getRows(area, selectedStartDate, selectedEndDate);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Não foi possível alterar a posição da mensagem!',
          description: error.response.data.message,
        });
      });
  };

  const getInfosDevotees = async (
    area: string,
    selectedStartDate: string,
    selectedEndDate: string,
  ) => {
    setLoading(true);
    try {
      let startDate: string = moment(selectedStartDate).add(3, 'hours').format(
        'YYYY-MM-DD HH:mm:ss',
      );
      let endDate: string = moment(selectedEndDate).add(3, 'hours').format(
        'YYYY-MM-DD HH:mm:ss',
      );

      const response = await api.get(
        `/devotees/amount-infos-devotees/` +
          area +
          '?startDate=' +
          startDate +
          '&endDate=' +
          endDate,
      );
      setAmountDevotees(response.data[0].cadastros);
      setDevoteesValidatedEmail(response.data[0].validadosEmail);
      setDevoteesValidatedSms(response.data[0].validadosSms);
      setDevoteesNotValidated(response.data[0].naoValidados);
      setLoading(false);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const tableBodyCells = useCallback(
    (isItemSelected, labelId, row) => (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{ChatAreas.get(row.area)}</TableCell>
        <TableCell
          align="left"
          dangerouslySetInnerHTML={{ __html: row.message }}
        ></TableCell>
        <TableCell align="left">{ChatTypes.get(row.type)}</TableCell>
        <TableCell align="left">
          <div style={{ display: 'flex' }}>
            <span>{row.orderNumber}</span>
            <ReorderButton
              onClick={() => handleChangePosition(row.id, false, row.area)}
            >
              <IoIosArrowUp />
            </ReorderButton>
            <ReorderButton
              onClick={() => handleChangePosition(row.id, true, row.area)}
            >
              <IoIosArrowDown />
            </ReorderButton>
          </div>
        </TableCell>
        <TableCell align="left">{row.amountUsers}</TableCell>
        <TableCell
          align="left"
          style={{
            backgroundColor:
              row.percentageUsers > 0.75
                ? '#a4f7be'
                : row.percentageUsers > 0.5
                ? '#f7f1a4'
                : '#f7aaa4',
          }}
        >
          {(row.percentageUsers * 100).toFixed(1)}%
        </TableCell>
      </>
    ),
    [],
  );

  const ChatAccessByDayChart = () => (
    <Paper
      style={{
        backgroundColor: 'white',
        borderRadius: '4px',
        width: '100%',
        marginBottom: '24px',
      }}
    >
      {dataChartAccessByDay.length !== 0 ? (
        <ResponsiveContainer width="100%" height={700}>
          <LineChart
            data={dataChartAccessByDay}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/* <CartesianGrid strokeDasharray="5 5" /> */}
            <XAxis dataKey="created_at" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={'devout_missionary_count'}
              stroke="green"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey={'young_of_mary_count'}
              stroke="red"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey={'child_devotee_count'}
              stroke="blue"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoDataMessage>Sem dados suficientes para exibir</NoDataMessage>
      )}
    </Paper>
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          onSubmit={() => handleSubmitFilter()}
          style={{
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            alignItems: 'flex-end',
            marginBottom: 20,
          }}
        >
          <div>
            <div>
              <Select
                name="chat"
                label="Selecione o Chat"
                defaultOption={selectedChat}
                options={chatAreas}
                onChange={e => handleSelectedChat(e.target.attributes)}
                style={{ width: '400px', marginRight: '18px' }}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <DatetimeInput
                id="datetime-start"
                label="Data e hora iniciais"
                name="dateStart"
                value={selectedStartDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedStartDate(e.target.value)
                }
              />
              <DatetimeInput
                id="datetime-end"
                label="Data e hora finais"
                name="dateEnd"
                value={selectedEndDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedEndDate(e.target.value)
                }
              />
            </div>
            <div>
              <Button
                type="submit"
                loading={loading}
                style={{ width: 200, marginLeft: 10 }}
              >
                Filtrar
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '25px',
                }}
              >
                {amountDevotees !== '' && amountDevotees !== undefined && (
                  <h2 style={{ margin: '0 10px' }}>
                    Qtde de cadastros: {amountDevotees}
                  </h2>
                )}
                  {devoteesValidatedEmail !== '' && devoteesValidatedEmail !== undefined && (
                  <h2 style={{ margin: '0 10px' }}>
                    Validados por email: {devoteesValidatedEmail}
                  </h2>
                )}
                  {devoteesValidatedSms !== '' && devoteesValidatedSms !== undefined && (
                  <h2 style={{ margin: '0 10px' }}>
                    Validados por sms: {devoteesValidatedSms}
                  </h2>
                )}
                  {devoteesNotValidated !== '' && devoteesNotValidated !== undefined && (
                  <h2 style={{ margin: '0 10px' }}>
                    Não validados: {devoteesNotValidated}
                  </h2>
                )}
              </div>
            </div>
          </div>
        </Form>

        {dataChartAccessByDay && (
          <Modal
            open={showChartModal}
            onClose={() => setShowChartModal(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <ChartContainer>
              <ChatAccessByDayChart />
            </ChartContainer>
          </Modal>
        )}
        <BasicTable<Data, HeadCell[]>
          title="Chats"
          headCells={headCells}
          rows={chatRows}
          onCreate={onCreate}
          onDelete={onDelete}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
          rowsPerPage={50}
          hasChartAttached
          setShowChart={setShowChartModal}
          hasDownloadData
          downloadDataEvent={downloadCVSData}
        />
      </Content>
    </StandardContainer>
  );
};

export default Chat;
