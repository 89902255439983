import React, { useEffect, useRef, useState } from 'react';

import {
  ContainerMap,
  Content, Row
} from './styles';

import StandardContainer from '../../components/StandardContainer';
import Header from '../../components/Header';
import api from '../../services/api';
import DatetimeInput from '../../components/DatetimeInput';
import Button from '../../components/Button';
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import { Vector as VectorSource, Cluster as ClusterSource, OSM } from 'ol/source'
import { Vector as VectorLayer, Tile as TileLayer } from 'ol/layer'
import { Circle, Style, Fill, Stroke, Text } from 'ol/style'
import { fromLonLat, transform } from 'ol/proj'
import Map from 'ol/Map';
import View from 'ol/View';

import moment from 'moment';

interface IUser{
  lon: number;
  lat: number;
}


const GeolocationMap: React.FC = () => {

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IUser[]>([])
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm')); //7 dias atrás
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format('YYYY-MM-DDTHH:mm')); //até a data/hora atual
  const mapRef = useRef<HTMLDivElement>();
  
  useEffect(() => {
    loadUsers()
  }, [])

  const formatDateTime = (dateTime: string) => {
    return dateTime.replace("T"," ")
  }

  const loadUsers = () => {
    api.get(`/users/location/?start=${formatDateTime(selectedStartDate)}&end=${formatDateTime(selectedEndDate)}`).then(response => {
      setLoading(false)
      let users = response.data
      users = users.map((user: IUser) => {
        user.lat = Number(user.lat)
        user.lon = Number(user.lon)
        return user
      })
      showMap(users)
      setUsers(users)
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    });    
  }

  const clearMap = () => {
    const div:any = mapRef?.current
    while(div.firstChild){
      div.removeChild(div.firstChild);
    }
  }

  const showMap = (users: IUser[]) => {

    clearMap()

    const baseMapLayer = new TileLayer({
      source: new OSM()
    });

    const center = transform([-53, -18],'EPSG:4326', 'EPSG:3857');

    const map = new Map({
      target: 'map-canvas',
      layers: [baseMapLayer],
      view: new View({
        center: center,
        projection: 'EPSG:3857',
        zoom: 4
      })
    }) 
    
    var markers = [];
      for(let user of users){
        markers.push(new Feature({
            geometry: new Point(
                fromLonLat([ user.lon, user.lat ])
            ),
        }))
    }

    var source = new VectorSource({
        features: markers,
    });

    var clusterSource = new ClusterSource({
        distance: 40,
        source: source,
    });

    var styleCache:any = {};
    var clusters = new VectorLayer({
        source: clusterSource,
        style: function (feature) {
            var size = feature.get('features').length;
            var style = styleCache[size];
            if (!style) {
                style = new Style({
                    image: new Circle({
                        radius: 10,
                        stroke: new Stroke({
                            color: '#fff',
                        }),
                        fill: new Fill({
                            color: '#3399CC',
                        }),
                    }),
                    text: new Text({
                        text: size.toString(),
                        fill: new Fill({
                            color: '#fff',
                        }),
                    }),
                });
                styleCache[size] = style;
            }
            return style;
        },
        properties: {
          "name": "clusters"
        }
    });

    map.addLayer(clusters); 

  }

  return (
    <StandardContainer>      
      <Header />
      <Content>
        <Row>
          <DatetimeInput
            id="datetime-start"
            label="Data e hora iniciais"
            name="dateStart"
            value={selectedStartDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedStartDate(e.target.value)}
          />
          <DatetimeInput
            id="datetime-end"
            label="Data e hora finais"
            name="dateEnd"
            value={selectedEndDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedEndDate(e.target.value)}
          />
          <Button type="button" onClick={loadUsers} loading={loading} style={{maxWidth: 150}}>Filtrar</Button>
        </Row>
        
        <Row>
          <ContainerMap ref={mapRef} id="map-canvas" />
        </Row>

      </Content>
    </StandardContainer>
  );
};

export default GeolocationMap;
