import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import StandardContainer from '../../components/StandardContainer';
import TableNews from '../../components/Table';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Content } from './styles';

import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface Data {
  id: string;
  code: number;
  title: string;
  news_type: string;
  author: string;
  editorial: string;
  created_at: Date;
  updated_at: Date;
  image_url: string;
  views: number;
  likes: number;
  highlight_fixed: string;
}

interface INews {
  id: number;
  title: string;
  news_type: string;
  author: { name: string };
  editorial: { name: string };
  created_at: Date;
  updated_at: Date;
  image_url: string;
  views: number;
  likes: number;
  highlight_fixed: string;
}

function createData(
  id: string,
  code: number,
  title: string,
  news_type: string,
  author: string,
  editorial: string,
  created_at: Date,
  updated_at: Date,
  image_url: string,
  views: number,
  likes: number,
  highlight_fixed: string,
): Data {
  return {
    id,
    code,
    title,
    news_type,
    author,
    editorial,
    created_at,
    updated_at,
    image_url,
    views,
    likes,
    highlight_fixed,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'code', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'title', numeric: false, disablePadding: false, label: 'Título' },
  { id: 'news_type', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'author', numeric: false, disablePadding: false, label: 'Autor' },
  { id: 'editorial', numeric: false, disablePadding: false, label: 'Editoria' },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Criado em',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Atualizado em',
  },
  { id: 'image_url', numeric: false, disablePadding: false, label: 'Imagem' },
  { id: 'views', numeric: false, disablePadding: false, label: 'Views' },
  { id: 'likes', numeric: false, disablePadding: false, label: 'Curtidas ' },
  {
    id: 'highlight_fixed',
    numeric: false,
    disablePadding: false,
    label: 'Destaque fixo',
  },
];

const News = () => {
  const [rows, setRows] = useState<INews[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(true);

  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    api.get('/news/', { params: { limit: 10000 } }).then(response => {
      setRows(response.data);
      setLoading(false);
    });
  }, [deleted]);

  const onDelete = useCallback(
    id => {
      api.delete(`/news/${id}`).then(response => {
        setDeleted(!deleted);
        addToast({
          type: 'success',
          title: 'Notícia deletada com sucesso!',
        });
      });
    },
    [setDeleted, deleted, addToast],
  );

  const newsRows = useMemo(() => {
    return rows.map(row =>
      createData(
        row.id.toString(),
        row.id,
        row.title,
        row.news_type,
        row.author.name,
        row.editorial.name,
        row.created_at,
        row.updated_at,
        row.image_url,
        row.views,
        row.likes,
        row.highlight_fixed,
      ),
    );
  }, [rows]);

  const onUpload = useCallback(
    row => {
      history.push('/news/upload', { id: row.code });
    },
    [history],
  );

  const onCreate = useCallback(() => {
    history.push('/news/new');
  }, [history]);

  const onEdit = useCallback(
    row => {
      history.push('/news/edit', { id: row.code });
    },
    [history],
  );

  const tableBodyCells = useCallback(
    (isItemSelected, labelId, row) => (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.title}</TableCell>
        <TableCell align="left">{row.news_type}</TableCell>
        <TableCell align="left">{row.author}</TableCell>
        <TableCell align="left">{row.editorial}</TableCell>
        <TableCell align="left">
          {format(parseISO(row.created_at), 'dd/MM/YYY HH:mm:ss', {
            locale: ptBR,
          })}
        </TableCell>
        <TableCell align="left">
          {format(parseISO(row.updated_at), 'dd/MM/YYY HH:mm:ss', {
            locale: ptBR,
          })}
        </TableCell>
        <TableCell align="left">
          <a
            href={row.image_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#008FC3' }}
          >
            Abrir imagem
          </a>
        </TableCell>
        <TableCell align="left">{row.views}</TableCell>
        <TableCell align="left">{row.likes}</TableCell>
        <TableCell align="left">
          {row.highlight_fixed === 'Y' ? 'Sim' : 'Não'}
        </TableCell>
      </>
    ),
    [],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <TableNews<Data, HeadCell[]>
          title="Notícias"
          headCells={headCells}
          rows={newsRows}
          orderByDefaultColumn="updated_at"
          defineOrder="desc"
          onCreate={onCreate}
          onDelete={onDelete}
          onUpload={onUpload}
          onEdit={onEdit}
          tableBodyCells={tableBodyCells}
          loading={loading}
        />
      </Content>
    </StandardContainer>
  );
};

export default News;
