import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../../components/Button';
import Header from '../../../components/Header';
import Input from '../../../components/Input';
import StandardContainer from '../../../components/StandardContainer';

import { FiLock, FiMail, FiUser } from 'react-icons/fi';
import { Content } from './styles';

interface UserFormData {
  password: string;
  password_confirmation: string;
}

interface LocationState {
  id: number;
  name: string;
  email: string;
};

const EditUser = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const { id, name, email } = state;

  

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required('Campo obrigatório'),
          password_confirmation: Yup.string()
            .when('password', {
              is: val => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf(
              [Yup.ref('password'), undefined],
              'Confirmação de senha incorreta',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.patch('/profile/password', {
          userId : id,
          password: data.password,
          password_confirmation: data.password_confirmation,
        });

        history.push('/users');

        addToast({
          type: 'success',
          title: 'Senha altera para o usúario!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro na alteração de senha',
            description: err.message,
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na alteração da senha',
            description:
              'Ocorreu um erro ao alterar a senha, tente novamente.',
          });
        }
      }
      setLoading(false);
    },
    [addToast, history],
  );

  return (
    <StandardContainer>
      <Header />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h1>Alterar senha do usuário - {name}</h1>

          <Input
            name="password"
            icon={FiLock}
            type="password"
            label="Nova senha"
            placeholder="Nova senha"
          />

          <Input
            name="password_confirmation"
            icon={FiLock}
            type="password"
            label="Confirmar senha"
            placeholder="Confirmar senha"
          />

          <Button type="submit" loading={loading}>Salvar</Button>
        </Form>
      </Content>
    </StandardContainer>
  );
}

export default EditUser;
